/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../Core/Components/Modal/CustomModal";
import CustomRoundedImage from "../../Core/Components/CustomRoundedImage";
import { TrashSecondaryIcon } from "../../Common/Svgs/TrashIcon";
import CloseIcon from "../../Common/Svgs/CloseIcon";
import Button from "../../Core/Components/CustomButton";
import { updateSession } from "../../Redux/Slices/sessions.slice";
import { POWER_AFRICA_ASSET_STATUS } from "./constants";
import {
  deleteAsset,
  getPowerAfricaKPI,
  getAssets,
} from "../../Redux/APIs/powerAfricaAPI";
import AssetsIcon from "../../Common/Svgs/AssetsIcon";

const LOCAL_DEFAULT_LIMIT = 10;

const DeletePowerAfricaAssetModal = ({
  visible,
  onCancel,
  currentPage,
  setCurrentPage,
}) => {
  const dispatch = useDispatch();

  const { currentAsset } = useSelector((state) => state.powerAfrica);

  const onConfirm = async () => {
    await dispatch(deleteAsset({ powerAfricaAssetId: currentAsset.id }));

    const response = await dispatch(
      getAssets({
        offset: ((currentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
      }),
    );

    // If no records exist for the current page, move to the previous page
    if (!response.payload.result.length && currentPage > 1) {
      const previousPage = currentPage - 1;
      setCurrentPage(previousPage);

      await dispatch(
        getAssets({
          offset: (previousPage - 1) * LOCAL_DEFAULT_LIMIT,
        }),
      );
    }

    dispatch(getPowerAfricaKPI());

    dispatch(
      updateSession({
        key: "showToastMessage",
        value: {
          visible: true,
          data: {
            title: "Asset Deleted",
            description: `Asset ${currentAsset?.registrationNo} was deleted for successfully`,
          },
        },
      }),
    );

    onCancel();
  };

  const getBody = () => {
    if (+currentAsset.status === POWER_AFRICA_ASSET_STATUS.IN_SESSION) {
      return (
        <div className="px-2 mt-5">
          <p className="text-base font-medium text-alert-red font-poppins">
            Cannot delete asset
          </p>
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            The asset is currently associated with an ongoing session.
          </p>
        </div>
      );
    }

    return (
      <div className="px-2 mt-5">
        <p className="text-base font-medium text-alert-red font-poppins">
          Delete this asset
        </p>
        <p className="mt-2 text-xs font-normal text-black font-aileron">
          Are you sure you want to delete this asset from the system?
        </p>
        <p className="mt-2 text-xs font-normal text-black font-aileron">
          This action cannot be undone.
        </p>
      </div>
    );
  };

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="536px" centered>
      <div className="w-full p-4 rounded-md">
        <div className="flex items-center justify-between">
          <div className="relative">
            <div className="absolute top-0 right-0">
              <TrashSecondaryIcon className="text-alert-red" />
            </div>
            <CustomRoundedImage
              size="w-12 h-12"
              image={<AssetsIcon size={24} className="text-white" />}
              background="bg-primary"
            />
          </div>
          <button type="button" onClick={onCancel}>
            <CloseIcon className="w-6 h-6 stroke-dark-gray" />
          </button>
        </div>

        {getBody()}

        <div className="flex justify-end gap-x-3 mt-5.5">
          <Button colors="cancelButton" size="primary" onClick={onCancel}>
            Cancel
          </Button>

          {/* do not show the confirm button if the tiller is currently in session */}
          {+currentAsset.status !== POWER_AFRICA_ASSET_STATUS.IN_SESSION ? (
            <Button colors="alert" size="primary" onClick={onConfirm}>
              Confirm
            </Button>
          ) : null}
        </div>
      </div>
    </CustomModal>
  );
};

export default DeletePowerAfricaAssetModal;
