import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "antd";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { useSelector } from "react-redux";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import { ExpandButton } from "../../../Core/Components/MapMarkerButtons";
import { INITIAL_ZOOM_LEVEL, MAX_BOUNDS } from "../../Home/Data/HomeData";
import getStatus from "../getStatus";
import KPIandBatteries from "./KPIandBatteries";
import AssetSessionTable from "./AssetSessionTable";
import MapBottomCard from "./MapBottomCard";
import AssetFullScreenMap from "./AssetFullScreenMap";
import getMarkerIcon from "./getMarkerIcon";

const PowerAfricaAssetDetails = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const { currentAsset } = useSelector((state) => state.powerAfrica);

  const [fullScreenMap, setFullScreenMap] = useState(false);

  const mapRef = useRef(null);

  const getAssetLatLngs = () => {
    return [-0.6803741, 34.7569482];
  };

  return (
    <CustomLayout
      pageName="Assets"
      nestedName={`${id}`}
      customPageNameNavigation={() => navigate("/assets?tab=agri-tech")}
      hideFooter
    >
      <Row className="w-full h-full">
        <Col span={16}>
          <div className="w-full h-full bg-background-white">
            <div className="flex items-center justify-between py-4 px-6 h-14 border-b border-light-grey">
              <p className="font-poppins font-medium text-base text-black">
                Overview
              </p>

              {getStatus(+currentAsset.status)}
            </div>

            <KPIandBatteries />
            <AssetSessionTable />
          </div>
        </Col>

        <Col span={8}>
          <div className="py-4 px-6 h-14 border-b border-l border-light-grey bg-background-white">
            <p className="font-poppins font-medium text-base text-black">
              Live Location
            </p>
          </div>

          <div className="border-l border-light-grey">
            <div className="h-[calc(100vh-128px)]">
              <MapContainer
                center={getAssetLatLngs()}
                zoom={INITIAL_ZOOM_LEVEL}
                maxBounds={MAX_BOUNDS}
                className="z-10 w-full h-full"
                zoomControl={false}
                ref={mapRef}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                <Marker
                  position={getAssetLatLngs()}
                  icon={getMarkerIcon(+currentAsset.type)}
                />
              </MapContainer>
            </div>

            <div className="absolute z-20 w-full left-6 top-20">
              <div className="flex items-center justify-between">
                <div />

                <div className="flex mr-14 gap-x-4">
                  <ExpandButton onClick={() => setFullScreenMap(true)} />
                </div>
              </div>
            </div>

            <div className="z-20">
              <MapBottomCard
                mapRef={mapRef}
                initialZoomLevel={INITIAL_ZOOM_LEVEL}
                center={getAssetLatLngs()}
              />
            </div>
          </div>
        </Col>

        <AssetFullScreenMap
          visible={fullScreenMap}
          onCancel={() => setFullScreenMap(false)}
        />
      </Row>
    </CustomLayout>
  );
};

export default PowerAfricaAssetDetails;
