import React from "react";

const Detailistings = ({ title, value }) => {
  return (
    <div className="flex-1 font-poppins">
      <p className="font-medium text-sm leading-[21px] text-black capitalize">
        {value || "--"}
      </p>
      <p className="font-normal text-xs leading-[18px] text-dark-gray capitalize">
        {title}
      </p>
    </div>
  );
};

export default Detailistings;
