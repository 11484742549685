import React from "react";

const PendingIcon = ({ size, className = "" }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M13.833 8.833a5.836 5.836 0 0 1-5.834 5.834 5.836 5.836 0 0 1-5.833-5.834A5.835 5.835 0 0 1 7.999 3a5.836 5.836 0 0 1 5.834 5.833M8 5.334v3.333"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M6 1.334h4"
        />
      </svg>
    </span>
  );
};

export default PendingIcon;
