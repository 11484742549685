import { POWER_AFRICA_ASSETS } from "../../PowerAfrica/constants";
import { SESSION_TYPE } from "../constants";
import getSessionTextType from "../getSessionTextType";

export const activityType = [
  {
    id: SESSION_TYPE.TILLING,
    value: getSessionTextType(SESSION_TYPE.TILLING),
  },
  {
    id: SESSION_TYPE.MILLING,
    value: getSessionTextType(SESSION_TYPE.MILLING),
  },
  {
    id: SESSION_TYPE.WATER_PUMPING,
    value: getSessionTextType(SESSION_TYPE.WATER_PUMPING),
  },
  {
    id: SESSION_TYPE.THRESHING,
    value: getSessionTextType(SESSION_TYPE.THRESHING),
  },
  {
    id: SESSION_TYPE.CHAUF_CUTTING,
    value: getSessionTextType(SESSION_TYPE.CHAUF_CUTTING),
  },
];

export const timeSlots = [
  {
    id: 1,
    value: "5:00 AM",
  },
  {
    id: 2,
    value: "6:00 AM",
  },
  {
    id: 3,
    value: "7:00 AM",
  },
  {
    id: 4,
    value: "8:00 AM",
  },
  {
    id: 5,
    value: "9:00 AM",
  },
  {
    id: 6,
    value: "10:00 AM",
  },
  {
    id: 7,
    value: "11:00 AM",
  },
  {
    id: 8,
    value: "12:00 PM",
  },
  {
    id: 9,
    value: "1:00 PM",
  },
  {
    id: 10,
    value: "2:00 PM",
  },
  {
    id: 11,
    value: "3:00 PM",
  },
  {
    id: 12,
    value: "4:00 PM",
  },
  {
    id: 13,
    value: "5:00 PM",
  },
  {
    id: 14,
    value: "6:00 PM",
  },
];

export const assetType = [
  {
    id: POWER_AFRICA_ASSETS.TILLER,
    value: "Tiller",
  },
  {
    id: POWER_AFRICA_ASSETS.MILLER,
    value: "Miller",
  },
  {
    id: POWER_AFRICA_ASSETS.WATER_PUMP,
    value: "Water Pump",
  },
  {
    id: POWER_AFRICA_ASSETS.THRESHER,
    value: "Thresher",
  },
  {
    id: POWER_AFRICA_ASSETS.CHAUF_CUTTER,
    value: "Chauf Cutter",
  },
];

export const powerSources = [
  {
    id: 1,
    value: "Powertrain",
  },
];

export const motorTypes = [
  {
    id: 1,
    value: "Petrol Engine",
  },
  {
    id: 2,
    value: "4kW BLDC Motor",
  },
  {
    id: 3,
    value: "⁠2.2kW PSM Motor",
  },
  {
    id: 4,
    value: "2kW BLDC Motor",
  },
];
