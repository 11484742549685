import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const useDefaultChecklistValues = () => {
  const { type } = useParams();

  const { checklist } = useSelector((state) => state.trikes);
  const { checklists } = useSelector((state) => state.powerAfrica);

  if (type === "trike" && checklist.length) {
    return checklist;
  }

  if (type === "tiller" && checklists?.tiller?.length) {
    return checklists.tiller;
  }

  if (type === "miller" && checklists?.miller?.length) {
    return checklists.miller;
  }

  if (type === "water-pump" && checklists?.waterPump?.length) {
    return checklists.waterPump;
  }

  if (type === "thresher" && checklist?.thresher?.length) {
    return checklists?.thresher;
  }

  if (type === "chauf-cutter" && checklist?.chaufCutter?.length) {
    return checklists?.chaufCutter;
  }

  return [
    {
      partId: "",
      partName: "",
    },
  ];
};

export const useExistingChecklist = () => {
  const { type } = useParams();

  const { checklist } = useSelector((state) => state.trikes);
  const { checklists } = useSelector((state) => state.powerAfrica);

  const checklistMap = {
    trike: checklist.length,
    tiller: checklists?.tiller?.length,
    miller: checklists?.miller?.length,
    "water-pump": checklists?.waterPump?.length,
    thresher: checklist?.thresher?.length,
    "chauf-cutter": checklist?.chaufCutter?.length,
  };

  return Boolean(checklistMap[type]);
};

export default useDefaultChecklistValues;
