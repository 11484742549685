import React from "react";
import { POWER_AFRICA_ASSETS } from "./constants";

const getAssetType = (type) => {
  switch (type) {
    case POWER_AFRICA_ASSETS.TILLER:
      return (
        <div className="flex items-center h-[26px] w-[65px] py-[5px] px-2 gap-x-1 bg-yellow-10 rounded-md">
          <img
            src="/assets/icons/power-africa/tiller.svg"
            className="w-4 h-4"
            alt="tiller"
          />
          <p className="font-poppins font-medium text-xs leading-[18px] text-yellow-dark">
            Tiller
          </p>
        </div>
      );

    case POWER_AFRICA_ASSETS.MILLER:
      return (
        <div className="flex items-center h-[26px] w-[69px] py-[5px] px-2 gap-x-1 bg-green-10 rounded-md">
          <img
            src="/assets/icons/power-africa/miller.svg"
            className="w-4 h-4"
            alt="miller"
          />
          <p className="font-poppins font-medium text-xs leading-[18px] text-green">
            Miller
          </p>
        </div>
      );

    case POWER_AFRICA_ASSETS.WATER_PUMP:
      return (
        <div className="flex items-center h-[26px] w-[112px] py-[5px] px-2 gap-x-1 bg-warning-bg rounded-md">
          <img
            src="/assets/icons/power-africa/water-pump.svg"
            className="w-4 h-4"
            alt="thresher"
          />
          <p className="font-poppins font-medium text-xs leading-[18px] text-warning">
            Water Pump
          </p>
        </div>
      );

    case POWER_AFRICA_ASSETS.THRESHER:
      return (
        <div className="flex items-center h-[26px] w-[89px] py-[5px] px-2 gap-x-1 bg-alert-10 rounded-md">
          <img
            src="/assets/icons/power-africa/thresher.svg"
            className="w-4 h-4"
            alt="thresher"
          />
          <p className="font-poppins font-medium text-xs leading-[18px] text-alert-red">
            Thresher
          </p>
        </div>
      );

    case POWER_AFRICA_ASSETS.CHAUF_CUTTER:
      return (
        <div className="flex items-center h-[26px] w-[114px] py-[5px] px-2 gap-x-1 bg-user-pink-10 rounded-md">
          <img
            src="/assets/icons/power-africa/chauf-cutter.svg"
            className="w-4 h-4"
            alt="thresher"
          />
          <p className="font-poppins font-medium text-xs leading-[18px] text-user-pink whitespace-nowrap">
            Chaff Cutter
          </p>
        </div>
      );

    default:
      return (
        <div className="flex items-center w-[114px] h-[26px] py-[5px] px-2 gap-x-1 bg-user-pink-10 rounded-md">
          <img
            src="/assets/icons/power-africa/chauf-cutter.svg"
            className="w-4 h-4"
            alt="thresher"
          />
          <p className="font-poppins font-medium text-xs leading-[18px] text-user-pink whitespace-nowrap">
            Chaff Cutter
          </p>
        </div>
      );
  }
};

export const getAssetTypeName = (type) => {
  if (type === POWER_AFRICA_ASSETS.TILLER) return "Tiller";
  if (type === POWER_AFRICA_ASSETS.MILLER) return "Miller";
  if (type === POWER_AFRICA_ASSETS.THRESHER) return "Thresher";
  if (type === POWER_AFRICA_ASSETS.WATER_PUMP) return "Water Pump";
  if (type === POWER_AFRICA_ASSETS.CHAUF_CUTTER) return "Chaff Cutter";

  return "Tiller";
};

export default getAssetType;
