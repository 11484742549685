import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "antd";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import CustomInput from "../../../Core/Components/CustomInput";
import { BATTERY_STATUS } from "../../Battery/Data";
import useSearch from "../../../Core/hooks/useSearch";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import { updateRequest } from "../../../Redux/Slices/requests.slice";
import getBatteryStatus from "../../../Utils/getBatteryStatus";
import BatteryPercentageStatus from "../../Battery/phase3/BatteryPercentageStatus";

const SelectBatteries = () => {
  const dispatch = useDispatch();

  const { transportRequestAssigning } = useSelector((state) => state.requests);

  const { allBatteries } = useSelector((state) => state.batteries);

  const [focus, setFocus] = useState(false);
  const [batteriesInSystem, setBatteriesInSystem] = useState([]);

  const { filteredData, search, setSearch } = useSearch(batteriesInSystem, [
    "value",
  ]);

  const handleSelection = (battery) => {
    let local = transportRequestAssigning.batteries;

    const found = local.find((item) => item.id === battery.id);

    if (found) {
      local = local.filter((item) => item.id !== battery.id);
    } else {
      local = [...local, battery];
    }

    dispatch(
      updateRequest({
        key: "transportRequestAssigning",
        value: { ...transportRequestAssigning, batteries: local },
      }),
    );
  };

  // find the batteries currently in hub station
  const getBatteries = useCallback(() => {
    const hubStationBatteries = allBatteries
      .filter((item) => +item.status === BATTERY_STATUS.HUB_STATION)
      .map((battery) => ({
        ...battery,
        id: battery.id,
        value: battery.registrationNo,
      }));

    setBatteriesInSystem(hubStationBatteries);
  }, [allBatteries]);

  useEffect(() => {
    getBatteries();
  }, [getBatteries]);

  return (
    <div>
      <div className="bg-background-white h-full px-6">
        <div className="py-6 flex items-center gap-x-3 border-t border-light-grey">
          <button
            type="button"
            onClick={() =>
              dispatch(
                updateRequest({
                  key: "transportDetailStep",
                  value: 2,
                }),
              )
            }
          >
            <ChevronIcon className="w-5 h-5 stroke-dark-gray -rotate-180" />
          </button>

          <p className="font-poppins font-medium text-sm text-black">
            Assign batteries to accept this request
          </p>
        </div>

        <CustomInput
          intent="searchFilterItem"
          prefix={
            <img src="/assets/icons/search.svg" alt="" className="mr-2" />
          }
          value={search}
          focus={focus}
          border={focus ? "success" : "none"}
          placeholder={focus ? null : "Search Battery No."}
          onChange={(e) => setSearch(e.target.value)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          suffix={
            search.length > 0 ? (
              <CloseIcon
                className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
                onClick={() => {
                  setFocus(false);
                  setSearch("");
                }}
              />
            ) : null
          }
        />

        <div className="">
          {filteredData.map((battery) => {
            const selected = transportRequestAssigning?.batteries?.find(
              (item) => item.id === battery.id,
            );

            return (
              <div
                className={`flex justify-between h-10 px-6 border-b cursor-pointer hover:bg-primary-20 border-light-grey 
                  ${selected ? "bg-primary-20" : ""}`}
                key={battery.id}
                onClick={() => handleSelection(battery)}
              >
                <div className="flex items-center gap-x-3">
                  <Checkbox checked={selected} />
                  <p className="text-xs font-semibold text-black capitalize font-aileron">
                    {battery.value}
                  </p>
                </div>

                <div className="flex items-center gap-x-12">
                  <div className="w-[112px] flex justify-end">
                    {getBatteryStatus(battery.status)}
                  </div>

                  <div className="w-[52px]">
                    <BatteryPercentageStatus
                      detail={battery}
                      showChargingStatus={false}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SelectBatteries;
