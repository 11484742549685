import React from "react";
import { Drawer } from "antd";
import { useSelector } from "react-redux";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import PaymentStatus from "../PaymentList/PaymentStatus";
import CustomTable from "../../../Core/Components/CustomTable";
import { paymentTransactionColumns, paymentTransactionData } from "./data";
import { FinanceHoverIcon } from "../../../Common/Svgs/FinanceIcon";

const TransactionHistoryDrawer = ({ visible, onCancel }) => {
  const { selectedPayment } = useSelector((state) => state.revenue);

  return (
    <Drawer
      width={448}
      placement="right"
      closable={false}
      maskStyle={{ background: "transparent" }}
      open={visible}
      onClose={onCancel}
      className="rounded-tl-xl"
    >
      <div className="bg-background-white w-full h-full rounded-tl-xl">
        <div className="p-6">
          <div className="flex items-center justify-between">
            <p className="font-poppins font-medium text-base text-black">
              Transaction History
            </p>

            <CloseIcon
              className="w-5 h-5 cursor-pointer stroke-dark-grey-text"
              onClick={onCancel}
            />
          </div>

          <div className="mt-6 flex items-center justify-between">
            <p className="font-aileron font-normal text-xs leading-[14.5px] text-dark-gray">
              Status
            </p>

            <PaymentStatus payment={selectedPayment} width="max-w-max" />
          </div>
        </div>

        <div className="transaction-history">
          <CustomTable
            columns={paymentTransactionColumns}
            data={paymentTransactionData}
            scrollX={null}
            emptyState={<FinanceHoverIcon className="text-dark-grey-text" />}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default TransactionHistoryDrawer;
