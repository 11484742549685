/* eslint-disable eqeqeq */
import { Divider } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import { MobiliseModalCTA } from "./Modal/MobiliseModalV3";
import { TRIKE_STATUS } from "../../Modules/Trikes/Data";
import { PERMISSIONS } from "../../Modules/Login/constants";
import usePermission from "../hooks/usePermission";
import CustomTooltip from "./CustomTooltip";

const LastOnline = ({ trike }) => {
  const [lastOnline, setLastOnline] = useState({
    today: false,
    time: "",
  });

  const getLastOnline = useCallback(() => {
    if (trike.lastOnline) {
      const isToday = dayjs(trike.lastOnline).isSame(dayjs(), "day");
      setLastOnline({
        today: isToday,
        time: dayjs(trike.lastOnline).format("DD-MM-YYYY, hh:mm a"),
      });
    }
  }, [trike]);

  useEffect(() => {
    getLastOnline();
  }, [getLastOnline]);

  const displayTime = lastOnline.today
    ? lastOnline.time.slice(12) // only show time
    : lastOnline.time.substring(0, 10); // show date

  return (
    <CustomTooltip tooltipTitle={`Last online: ${lastOnline.time}`}>
      <p className="font-aileron text-xs font-normal text-dark-gray">
        {displayTime || "--"}
      </p>
    </CustomTooltip>
  );
};

const CustomHomeListing = ({ trike, onClick, isLast }) => {
  const hasPermission = usePermission([PERMISSIONS.TRIKE_MOBILISE]);

  return (
    <div onClick={onClick} className="cursor-pointer">
      <div className="flex items-start justify-between px-6 py-4">
        <div className="cursor-pointer font-poppins">
          <div className="text-base font-medium text-black">
            {trike.registrationNo}
          </div>
          <div className="flex items-center gap-x-2 mt-1.5">
            <p className="text-xs leading-4.5 font-normal font-aileron text-dark-gray">
              {trike.status == TRIKE_STATUS.ON_TRIP ||
              trike.status == TRIKE_STATUS.OUT_OF_GEOFENCE
                ? "Online"
                : "Offline"}
            </p>

            <Divider
              type="vertical"
              className="bg-light-grey"
              style={{ margin: 0 }}
            />

            {trike.lastOnline ? (
              <LastOnline trike={trike} />
            ) : (
              <p className="font-aileron text-xs font-normal text-dark-gray">
                --
              </p>
            )}
          </div>
        </div>

        {hasPermission && (
          <div>
            <MobiliseModalCTA trike={trike} />
          </div>
        )}
      </div>

      {!isLast && <Divider className="m-0" />}
    </div>
  );
};
export default CustomHomeListing;
