/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import PaymentDetailsHeader from "./PaymentDetailsHeader";
import { paymentDetailColumns } from "./data";
import { FinanceHoverIcon } from "../../../Common/Svgs/FinanceIcon";
import CustomTable from "../../../Core/Components/CustomTable";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import {
  getPaymentDetails,
  getPaymentDetailsKPI,
} from "../../../Redux/APIs/revenueAPI";
import useDebounce from "../../../Core/hooks/useDebounce";
import { PaymentDetailsKPIData } from "../PaymentList/data";
import CustomKPI from "../../../Core/Components/CustomKpi";
import TransactionHistoryDrawer from "./TransactionHistoryDrawer";
import { updateRevenue } from "../../../Redux/Slices/revenue.slice";
import EditCommodityModal from "./EditCommodityModal";

const LOCAL_DEFAULT_LIMIT = 10;

const PaymentDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    selectedPayment,
    paymentDetails,
    transactionHistoryVisible,
    editCommodityModal,
    paymentDetailsKPI,
  } = useSelector((state) => state.revenue);

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    paymentStatus: [],
    dateRangeStart: "",
    dateRangeEnd: "",
  });

  const debounceSearch = useDebounce(search, 500);

  const handlePageNameNavigation = () => {
    navigate("/revenue?tab=payments");
  };

  useEffect(() => {
    const filterObj = {
      receiverId: selectedPayment?.entityId,
      type: selectedPayment?.entityType,
      offset: ((currentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
      limit: (currentPage || 1) * LOCAL_DEFAULT_LIMIT,
    };

    if (debounceSearch) filterObj.searchQuery = debounceSearch;

    if (filters.paymentStatus.length)
      filterObj.paymentStatus = filters.paymentStatus;

    if (filters.dateRangeStart !== "" && filters.dateRangeEnd !== "") {
      filterObj.startDate = filters.dateRangeStart;
      filterObj.endDate = filters.dateRangeEnd;
    }

    dispatch(getPaymentDetails(filterObj));
  }, [dispatch, selectedPayment, currentPage, debounceSearch, filters]);

  // fetch payment details kpi
  useEffect(() => {
    dispatch(
      getPaymentDetailsKPI({
        entityId: selectedPayment?.entityId,
        entityType: selectedPayment?.entityType,
      }),
    );
  }, [dispatch, selectedPayment]);

  return (
    <CustomLayout
      pageName="Payments"
      nestedName={`${selectedPayment?.firstName} ${selectedPayment?.lastName}`}
      customPageNameNavigation={handlePageNameNavigation}
    >
      <div className="w-full bg-background">
        {/* <div className="p-6">
          <h1 className="font-poppins text-base font-medium capitalize">
            Payment History
          </h1>

          <p className="mt-3 font-poppins text-base font-medium">
            {paymentDetails.totalDue > 0
              ? `${parseFloat(paymentDetails.totalDue).toFixed(2)} KSH`
              : "--"}
          </p>

          <p className="mt-1 font-aileron text-xs font-normal text-dark-gray capitalize">
            Total amount due
          </p>
        </div> */}

        {/* only rendering for UI, integration remaining */}
        <div className="w-full flex items-center gap-x-6 p-6">
          {PaymentDetailsKPIData(paymentDetailsKPI).map((item) => (
            <CustomKPI
              key={item.title}
              title={item.title}
              count={item.count}
              countColor={item.countColor}
              background={item.background}
              image={item.icon}
              unit="KSH"
            />
          ))}
        </div>

        <PaymentDetailsHeader
          search={search}
          setSearch={setSearch}
          filters={filters}
          setFilters={setFilters}
          setCurrentPage={setCurrentPage}
        />

        <div
          className={`h-[calc(100vh-72px-128px-70px-40px)] ${
            paymentDetails?.result?.length && "overflow-y-scroll"
          }`}
        >
          <CustomTable
            // scrollX={0}
            // scrollY="388px"
            disableScroll
            columns={paymentDetailColumns}
            data={paymentDetails.result}
            emptyState={
              <FinanceHoverIcon size={16} className="text-dark-grey-text" />
            }
            emptyStateHeight="420px"
            rowClassName="group"
            pagination={{
              pageSize: LOCAL_DEFAULT_LIMIT,
              total: paymentDetails.totalRecords,
              showSizeChanger: false,
              onChange: (page) => {
                setCurrentPage(page);
              },
              current: currentPage,
              itemRender: (current, type, originalElement) => {
                const lastPage = paymentDetails.totalRecords / current;

                if (type === "prev" && current === 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{
                        pointerEvents: "none",
                      }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "prev" && current > 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next" && lastPage <= 10) {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{ pointerEvents: "none" }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next") {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                return originalElement;
              },
            }}
          />
        </div>
      </div>

      <TransactionHistoryDrawer
        visible={transactionHistoryVisible}
        onCancel={() =>
          dispatch(
            updateRevenue({
              key: "transactionHistoryVisible",
              value: false,
            }),
          )
        }
      />

      <EditCommodityModal
        visible={editCommodityModal?.visible}
        onCancel={() =>
          dispatch(
            updateRevenue({
              key: "editCommodityModal",
              value: {
                visible: false,
                current: {},
              },
            }),
          )
        }
      />
    </CustomLayout>
  );
};

export default PaymentDetails;
