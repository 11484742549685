import { SERVICE_ASSET_TYPE } from "../Data";

const getServiceAssetType = (type) => {
  switch (type) {
    case SERVICE_ASSET_TYPE.TRIKE:
      return "Trike";
    case SERVICE_ASSET_TYPE.BATTERY:
      return "Battery";
    case SERVICE_ASSET_TYPE.TILLER:
      return "Tiller";
    case SERVICE_ASSET_TYPE.MILLER:
      return "Miller";
    case SERVICE_ASSET_TYPE.THRESHER:
      return "Thresher";
    case SERVICE_ASSET_TYPE.WATER_PUMP:
      return "Water Pump";
    case SERVICE_ASSET_TYPE.CHAUF_CUTTER:
      return "Chauf Cutter";
    default:
      return "";
  }
};

export default getServiceAssetType;
