/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../../Core/Components/CustomTable";
import SessionIcon from "../../../Common/Svgs/SessionIcon";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import { assetDetailSessionColumns } from "../data";
import { getSessionsByAssetId } from "../../../Redux/APIs/powerAfricaAPI";

const LOCAL_DEFAULT_LIMIT = 10;

const AssetSessionTable = () => {
  const dispatch = useDispatch();

  const { currentAsset, assetSessionList } = useSelector(
    (state) => state.powerAfrica,
  );

  const [currentPage, setCurrentPage] = useState(1);

  const tableRef = useRef(null);

  const getEmptyStateHeight = () => {
    return `${window.innerHeight - 584}px`;
  };

  // finding sessions based on the asset id
  useEffect(() => {
    if (currentAsset?.id) {
      const filterObj = {
        offset: ((currentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
        limit: LOCAL_DEFAULT_LIMIT,
        assetId: currentAsset.id,
      };

      dispatch(getSessionsByAssetId(filterObj));
    }
  }, [dispatch, currentAsset, currentPage]);

  return (
    <div className="w-full">
      <div className="w-full py-4 px-6 flex items-center border-b border-light-grey">
        <div className="flex items-center gap-x-3">
          <p className="font-poppins font-medium text-base text-black">
            Sessions
          </p>

          <div className="flex items-center justify-center rounded-full w-6 h-6 bg-light-grey">
            <p className="font-aileron text-10 font-semibold">
              {assetSessionList?.totalRecords || 0}
            </p>
          </div>
        </div>
      </div>

      {/* <div
        className={`h-[calc(100vh-497px)] ${
          assetDetailSessionsData?.length && "overflow-y-scroll"
        }`}
        ref={tableRef}
      > */}
      <div
        className={`h-[calc(100vh-556px)] ${
          assetSessionList?.result?.length && "overflow-y-scroll"
        }`}
        ref={tableRef}
      >
        <CustomTable
          // scrollX={0}
          // scrollY={tableScrollYHeight}
          disableScroll
          columns={assetDetailSessionColumns}
          data={assetSessionList?.result}
          emptyStateHeight={getEmptyStateHeight()}
          emptyState={<SessionIcon size={16} className="text-dark-gray" />}
          customTableClassName="w-full"
          pagination={{
            pageSize: LOCAL_DEFAULT_LIMIT,
            total: assetSessionList?.totalRecords,
            showSizeChanger: false,
            onChange: (page) => {
              setCurrentPage(page);
            },
            current: currentPage,
            itemRender: (current, type, originalElement) => {
              const lastPage = assetSessionList.totalRecords / current;

              if (type === "prev" && current === 0) {
                return (
                  <button
                    type="button"
                    className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    style={{
                      pointerEvents: "none",
                    }}
                  >
                    <ChevronIcon className="stroke-dark-gray" />
                  </button>
                );
              }

              if (type === "prev" && current > 0) {
                return (
                  <button
                    type="button"
                    className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                  >
                    <ChevronIcon className="stroke-dark-gray" />
                  </button>
                );
              }

              if (type === "next" && lastPage <= 10) {
                return (
                  <button
                    type="button"
                    className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    style={{ pointerEvents: "none" }}
                  >
                    <ChevronIcon className="stroke-dark-gray" />
                  </button>
                );
              }

              if (type === "next") {
                return (
                  <button
                    type="button"
                    className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                  >
                    <ChevronIcon className="stroke-dark-gray" />
                  </button>
                );
              }

              return originalElement;
            },
          }}
        />
      </div>
    </div>
  );
};

export default AssetSessionTable;
