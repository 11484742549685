import { createSlice } from "@reduxjs/toolkit";
import { getRequestList, rejectRequest } from "../APIs/requestsAPI";

const initialState = {
  loading: false,
  error: {},
  status: "idle",
  requestList: {
    result: [],
    totalRecords: 0,
  },
  currentRequest: {},
  requestDetailsVisible: false,
  showToastMessage: {
    visible: false,
    data: {},
  },
  transportDetailStep: 1,
  transportRequestAssigning: {
    trikeId: 0,
    trikeRegistrationNo: "",
    batteries: [],
  },
  rejectModalVisible: false,
};

export const requestsSlice = createSlice({
  name: "requests",
  initialState,
  reducers: {
    updateRequest: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers(builder) {
    // get request list
    builder.addCase(getRequestList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRequestList.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.requestList = action.payload;
    });
    builder.addCase(getRequestList.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // reject request
    builder.addCase(rejectRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(rejectRequest.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(rejectRequest.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });
  },
});

export const { updateRequest } = requestsSlice.actions;

export default requestsSlice.reducer;
