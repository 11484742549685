import { SESSION_TYPE } from "./constants";

const getSessionTextType = (type) => {
  switch (type) {
    case SESSION_TYPE.TILLING:
      return "tilling";

    case SESSION_TYPE.MILLING:
      return "milling";

    case SESSION_TYPE.WATER_PUMPING:
      return "Water pumping";

    case SESSION_TYPE.THRESHING:
      return "threshing";

    case SESSION_TYPE.CHAUF_CUTTING:
      return "chaff cutting";

    default:
      return "chaff cutting";
  }
};

export const getAssetTypeName = (type) => {
  switch (type) {
    case SESSION_TYPE.TILLING:
      return "tiller";

    case SESSION_TYPE.MILLING:
      return "miller";

    case SESSION_TYPE.WATER_PUMPING:
      return "Water pump";

    case SESSION_TYPE.THRESHING:
      return "thresher";

    case SESSION_TYPE.CHAUF_CUTTING:
      return "chaff cutter";

    default:
      return "tiller";
  }
};

export default getSessionTextType;
