/* eslint-disable import/no-cycle */
import React from "react";
import { QR_CODE_TYPE } from "./data";
import CustomTags from "../../Core/Components/CustomTags";
import TrikeIcon from "../../Common/Svgs/TrikeIcon";
import BatteryIcon from "../../Common/Svgs/BatteryIcon";
import UserIcon from "../../Common/Svgs/UserIcon";
import ChargerIcon from "../../Common/Svgs/ChargerIcon";

const getQRCodeStatus = (type) => {
  switch (type) {
    case QR_CODE_TYPE.TRIKE:
      return (
        <CustomTags width="qrCode" colors="qrTrike">
          <div className="flex items-center gap-x-2">
            <TrikeIcon size={16} className="text-primary" />
            <p className="font-poppins text-xs font-medium text-primary pt-0.5">
              Trike
            </p>
          </div>
        </CustomTags>
      );
    case QR_CODE_TYPE.BATTERY:
      return (
        <CustomTags width="qrCode" colors="qrBattery">
          <div className="flex items-center gap-x-2">
            <BatteryIcon className="w-4 h-4 stroke-yellow-dark fill-none" />
            <p className="font-poppins text-xs font-medium text-yellow-dark">
              Battery
            </p>
          </div>
        </CustomTags>
      );
    case QR_CODE_TYPE.FARMER:
      return (
        <CustomTags width="qrCode" colors="qrFarmer">
          <div className="flex items-center gap-x-2">
            <UserIcon className="w-4 h-4 stroke-user-pink fill-none" />
            <p className="font-poppins text-xs font-medium text-user-pink pt-0.5">
              Partner
            </p>
          </div>
        </CustomTags>
      );
    case QR_CODE_TYPE.CHARGER:
      return (
        <CustomTags width="qrCode" colors="qrCharger">
          <div className="flex items-center gap-x-2">
            <ChargerIcon size={16} className="text-[#0892BD]" />
            <p className="font-poppins text-xs font-medium text-[#0892BD] pt-0.5">
              Charger
            </p>
          </div>
        </CustomTags>
      );
    case QR_CODE_TYPE.TILLER:
      return (
        <div className="flex items-center h-[26px] w-[65px] py-[5px] px-2 gap-x-1 bg-yellow-10 rounded-md">
          <img
            src="/assets/icons/power-africa/tiller.svg"
            className="w-4 h-4"
            alt="tiller"
          />
          <p className="font-poppins font-medium text-xs leading-[18px] text-yellow-dark">
            Tiller
          </p>
        </div>
      );

    case QR_CODE_TYPE.MILLER:
      return (
        <div className="flex items-center h-[26px] w-[69px] py-[5px] px-2 gap-x-1 bg-green-10 rounded-md">
          <img
            src="/assets/icons/power-africa/miller.svg"
            className="w-4 h-4"
            alt="miller"
          />
          <p className="font-poppins font-medium text-xs leading-[18px] text-green">
            Miller
          </p>
        </div>
      );

    case QR_CODE_TYPE.WATER_PUMP:
      return (
        <div className="flex items-center h-[26px] w-[112px] py-[5px] px-2 gap-x-1 bg-warning-bg rounded-md">
          <img
            src="/assets/icons/power-africa/water-pump.svg"
            className="w-4 h-4"
            alt="thresher"
          />
          <p className="font-poppins font-medium text-xs leading-[18px] text-warning">
            Water Pump
          </p>
        </div>
      );

    case QR_CODE_TYPE.THRESHER:
      return (
        <div className="flex items-center h-[26px] w-[89px] py-[5px] px-2 gap-x-1 bg-alert-10 rounded-md">
          <img
            src="/assets/icons/power-africa/thresher.svg"
            className="w-4 h-4"
            alt="thresher"
          />
          <p className="font-poppins font-medium text-xs leading-[18px] text-alert-red">
            Thresher
          </p>
        </div>
      );

    case QR_CODE_TYPE.CHAUF_CUTTER:
      return (
        <div className="flex items-center h-[26px] w-[114px] py-[5px] px-2 gap-x-1 bg-user-pink-10 rounded-md">
          <img
            src="/assets/icons/power-africa/chauf-cutter.svg"
            className="w-4 h-4"
            alt="thresher"
          />
          <p className="font-poppins font-medium text-xs leading-[18px] text-user-pink whitespace-nowrap">
            Chaff Cutter
          </p>
        </div>
      );

    default:
      return (
        <CustomTags width="qrCode" colors="qrTrike">
          <div className="flex items-center gap-x-2">
            <TrikeIcon size={16} className="text-primary" />
            <p className="font-poppins text-xs font-medium text-primary pt-0.5">
              Trike
            </p>
          </div>
        </CustomTags>
      );
  }
};

export default getQRCodeStatus;
