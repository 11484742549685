import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Radio } from "antd";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import CustomInput from "../../../Core/Components/CustomInput";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import { TRIKE_STATUS } from "../../Trikes/Data";
import useSearch from "../../../Core/hooks/useSearch";
import CustomTags from "../../../Core/Components/CustomTags";
import { updateRequest } from "../../../Redux/Slices/requests.slice";

const SelectTrike = () => {
  const dispatch = useDispatch();

  const { transportRequestAssigning } = useSelector((state) => state.requests);

  const { allTrikes } = useSelector((state) => state.trikes);

  const [focus, setFocus] = useState(false);
  const [trikesInSystem, setTrikesInSystem] = useState([]);

  const { filteredData, search, setSearch } = useSearch(trikesInSystem, [
    "value",
  ]);

  const handleSelection = (trike) => {
    if (+trike.id === transportRequestAssigning?.trikeId) {
      dispatch(
        updateRequest({
          key: "transportRequestAssigning",
          value: {
            ...transportRequestAssigning,
            trikeId: 0,
            trikeRegistrationNo: "",
          },
        }),
      );
    } else {
      dispatch(
        updateRequest({
          key: "transportRequestAssigning",
          value: {
            ...transportRequestAssigning,
            trikeId: +trike.id,
            trikeRegistrationNo: trike.value,
          },
        }),
      );
    }
  };

  const getTrikesInSystem = useCallback(() => {
    const trikes = allTrikes
      ?.filter((item) => {
        if (+item.status === TRIKE_STATUS.HUB_STATION) {
          return true;
        }
        return false;
      })
      ?.map((t) => ({
        id: t.id,
        value: t.registrationNo,
      }));

    setTrikesInSystem([...trikes]);
  }, [allTrikes]);

  useEffect(() => {
    getTrikesInSystem();
  }, [getTrikesInSystem]);

  return (
    <div className="bg-background-white h-full px-6">
      <div className="py-6 flex items-center gap-x-3 border-t border-light-grey">
        <button
          type="button"
          onClick={() =>
            dispatch(
              updateRequest({
                key: "transportDetailStep",
                value: 1,
              }),
            )
          }
        >
          <ChevronIcon className="w-5 h-5 stroke-dark-gray -rotate-180" />
        </button>

        <p className="font-poppins font-medium text-sm text-black">
          Assign a trike to accept this request
        </p>
      </div>

      <CustomInput
        intent="searchFilterItem"
        prefix={<img src="/assets/icons/search.svg" alt="" className="mr-2" />}
        value={search}
        focus={focus}
        border={focus ? "success" : "none"}
        placeholder={focus ? null : "Search Trike No."}
        onChange={(e) => setSearch(e.target.value)}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        suffix={
          search.length > 0 ? (
            <CloseIcon
              className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
              onClick={() => {
                setFocus(false);
                setSearch("");
              }}
            />
          ) : null
        }
      />

      <div className="">
        {filteredData.map((trike) => (
          <div
            className={`flex items-center justify-between h-10 px-6 border-b cursor-pointer hover:bg-primary-20 border-light-grey 
            ${
              trike.id === transportRequestAssigning?.trikeId
                ? "bg-primary-20"
                : ""
            }`}
            key={trike.id}
            onClick={() => handleSelection(trike)}
          >
            <div className="flex items-center gap-x-3">
              <Radio
                checked={trike.id === transportRequestAssigning?.trikeId}
              />
              <p className="text-xs font-semibold text-black capitalize font-aileron">
                {trike.value}
              </p>
            </div>

            <CustomTags colors="checkIn" background="checkIn">
              <p className="text-xs font-medium font-poppins">Available</p>
            </CustomTags>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectTrike;
