/* eslint-disable operator-assignment */
import React from "react";
import dayjs from "dayjs";
import { getCollectionDetailStatus } from "../getPaymentStatus";
import TotalAmountColumn, { sortByTotalAmount } from "./TotalAmountColumn";
import getFormatedFloat from "../../../Utils/getFormatedFloat";

export const statusOptions = [
  {
    id: 1,
    value: getCollectionDetailStatus(1),
  },
  {
    id: 3,
    value: getCollectionDetailStatus(3),
  },
];

export const collectionDetailColumns = [
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Date
      </div>
    ),
    dataIndex: "submittedAt",
    sorter: (a, b) => a.submittedAt.localeCompare(b.submittedAt),
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {text ? dayjs(text).format("DD-MM-YYYY") : "--"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Trip ID
      </div>
    ),
    dataIndex: "tripId",
    sorter: (a, b) => a.tripId.localeCompare(b.tripId),
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Commodity
      </div>
    ),
    dataIndex: "tripId",
    render: (text, record) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {record.produceDetails
          .filter((i) => i.weightDelivered > 0)
          .map((item, index) => (
            <div key={item.produceId}>
              <p
                style={{
                  marginTop: index !== 0 && "8px",
                  textTransform: "capitalize",
                }}
              >
                {item?.produceName}
              </p>
            </div>
          ))}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Total Weight
      </div>
    ),
    dataIndex: "tripId",
    render: (text, record) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {record.produceDetails
          .filter((i) => i.weightDelivered > 0)
          .map((item, index) => (
            <div key={item.produceId}>
              <p
                style={{
                  marginTop: index !== 0 && "8px",
                  textTransform: "capitalize",
                }}
              >
                {/* {item?.weightDelivered
                  ? `${parseFloat(item.weightDelivered).toFixed(
                      2,
                    )} ${item.unit.slice(4)}`
                  : 0} */}
                {item?.weightDelivered
                  ? `
                  ${getFormatedFloat(item.weightDelivered)} ${item.unit.slice(
                      4,
                    )}`
                  : 0}
              </p>
            </div>
          ))}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Rate
      </div>
    ),
    dataIndex: "tripId",
    render: (text, record) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {record.produceDetails
          .filter((i) => i.weightDelivered > 0)
          .map((item, index) => (
            <div key={item.produceId}>
              <p
                style={{
                  marginTop: index !== 0 && "8px",
                  textTransform: "capitalize",
                }}
              >
                {item?.collectionRate} KSH/{item?.unit}
              </p>
            </div>
          ))}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Amount/Commodity
      </div>
    ),
    dataIndex: "tripId",
    render: (text, record) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {record.produceDetails
          .filter((i) => i.weightDelivered > 0)
          .map((item, index) => (
            <div key={item.produceId}>
              <p style={{ marginTop: index !== 0 && "8px" }}>
                {/* {item?.amount ? `${parseFloat(item.amount).toFixed(2)} KSH` : 0} */}
                {item?.amount ? `${getFormatedFloat(item.amount)} KSH` : 0}
              </p>
            </div>
          ))}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Total Amount
      </div>
    ),
    dataIndex: "tripId",
    sorter: (a, b) => sortByTotalAmount(a, b),
    render: (text, record) => <TotalAmountColumn collection={record} />,
  },
  // {
  //   title: () => (
  //     <div className="font-normal font-aileron text-10 text-dark-gray">
  //       Status
  //     </div>
  //   ),
  //   dataIndex: "paymentStatus",
  //   render: (text, record) => (
  //     <div>
  //       <div>{getCollectionDetailStatus(+text)}</div>
  //       <p className="mt-1 text-xs font-aileron font-semibold text-dark-gray">
  //         {record.paymentTimestamp
  //           ? dayjs(record.paymentTimestamp).format("DD-MM-YYYY")
  //           : ""}
  //       </p>
  //     </div>
  //   ),
  // },
  // {
  //   dataIndex: "tripId",
  //   width: "80px",
  //   render: (text, record) => (
  //     <button
  //       type="button"
  //       onClick={(event) => {
  //         event.stopPropagation();
  //       }}
  //       className="w-full flex items-center justify-center invisible group-hover:visible"
  //       // invisible group-hover:visible
  //     >
  //       <ListDropdown detail={record} />
  //     </button>
  //   ),
  // },
];
