/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../Core/Components/Modal/CustomModal";
import CustomRoundedImage from "../../Core/Components/CustomRoundedImage";
import { TrashSecondaryIcon } from "../../Common/Svgs/TrashIcon";
import CloseIcon from "../../Common/Svgs/CloseIcon";
import Button from "../../Core/Components/CustomButton";
import OperationsIcon from "../../Common/Svgs/OperationsIcon";
import { updateSession } from "../../Redux/Slices/sessions.slice";
import {
  deleteSession,
  getSessionKPI,
  getSessions,
} from "../../Redux/APIs/sessionsAPI";
import { SESSION_STATUS } from "./constants";

const LOCAL_DEFAULT_LIMIT = 10;

const DeleteSessionModal = ({
  visible,
  onCancel,
  currentPage,
  setCurrentPage,
}) => {
  const dispatch = useDispatch();

  const { currentSession } = useSelector((state) => state.sessions);

  const onConfirm = async () => {
    await dispatch(deleteSession({ sessionId: currentSession.id }));

    const response = await dispatch(
      getSessions({
        offset: ((currentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
      }),
    );

    // If no records exist for the current page, move to the previous page
    if (!response.payload.result.length && currentPage > 1) {
      const previousPage = currentPage - 1;
      setCurrentPage(previousPage);

      await dispatch(
        getSessions({
          offset: (previousPage - 1) * LOCAL_DEFAULT_LIMIT,
        }),
      );
    }

    dispatch(getSessionKPI());

    dispatch(
      updateSession({
        key: "showToastMessage",
        value: {
          visible: true,
          data: {
            title: "Session Deleted",
            description: `Session ${currentSession.id} was deleted for successfully`,
          },
        },
      }),
    );

    onCancel();
  };

  const getBody = () => {
    if (+currentSession.status === SESSION_STATUS.UPCOMING) {
      return (
        <div className="px-2 mt-5">
          <p className="text-base font-medium text-alert-red font-poppins">
            Delete this session
          </p>
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            Are you sure you want to delete this session from the system?
          </p>
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            This action cannot be undone.
          </p>
        </div>
      );
    }

    if (+currentSession.status === SESSION_STATUS.ONGOING) {
      return (
        <div className="px-2 mt-5">
          <p className="text-base font-medium text-alert-red font-poppins">
            Cannot delete session
          </p>
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            The session is currently ongoing.
          </p>
        </div>
      );
    }

    return (
      <div className="px-2 mt-5">
        <p className="text-base font-medium text-alert-red font-poppins">
          Cannot delete session
        </p>
        <p className="mt-2 text-xs font-normal text-black font-aileron">
          The session is completed.
        </p>
      </div>
    );
  };

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="536px" centered>
      <div className="w-full p-4 rounded-md">
        <div className="flex items-center justify-between">
          <div className="relative">
            <div className="absolute top-0 right-0">
              <TrashSecondaryIcon className="text-alert-red" />
            </div>
            <CustomRoundedImage
              size="w-12 h-12"
              image={<OperationsIcon size={24} className="text-white" />}
              background="bg-primary"
            />
          </div>
          <button type="button" onClick={onCancel}>
            <CloseIcon className="w-6 h-6 stroke-dark-gray" />
          </button>
        </div>

        {getBody()}

        <div className="flex justify-end gap-x-3 mt-5.5">
          <Button colors="cancelButton" size="primary" onClick={onCancel}>
            Cancel
          </Button>

          {+currentSession.status === SESSION_STATUS.UPCOMING && (
            <Button colors="alert" size="primary" onClick={onConfirm}>
              Confirm
            </Button>
          )}
        </div>
      </div>
    </CustomModal>
  );
};

export default DeleteSessionModal;
