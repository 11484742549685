/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import { getFarmersV3 } from "../../../Redux/APIs/usersAPI";
import { farmersColumns } from "../Data/UsersListData";
import CustomTable from "../../../Core/Components/CustomTable";
import UserIcon from "../../../Common/Svgs/UserIcon";
import { getUserByEmail } from "../../../Redux/APIs/systemUsersAPI";
import useDebounce from "../../../Core/hooks/useDebounce";
import PartnerHeader from "./PartnerHeader";
import DeleteFarmerModal from "./DeleteFarmerModal";
import { updateUsers } from "../../../Redux/Slices/users.slice";
import ExportFarmerModal from "./ExportFarmerModal";
import QRCodeModal from "../../../Core/Components/Modal/QRCodeModal";
import { updateQrCode } from "../../../Redux/Slices/qrcode.slice";
import { getTableContainerHeight } from "../../../Utils/getTableContainerHeight";
import { getAllLocationsV3 } from "../../../Redux/APIs/locationsAPI";
import FarmerApprovalDrawer from "./FarmerApprovalDrawer";

const LOCAL_DEFAULT_LIMIT = 10;

const PartnersList = () => {
  const dispatch = useDispatch();

  const {
    farmersListV3,
    deleteFarmerModal,
    exportFarmerModal,
    approveFarmerDrawer,
  } = useSelector((state) => state.users);

  const { viewModal } = useSelector((state) => state.qrCode);

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const tableContainerRef = useRef(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    hub: [],
    dateRangeStart: "",
    dateRangeEnd: "",
    farmerSelected: [],
  });

  const debounceSearch = useDebounce(search, 500);

  // fetch farmers (pagination based) along with filters
  useEffect(() => {
    const filterObj = {
      offset: ((currentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
      limit: LOCAL_DEFAULT_LIMIT,
    };

    if (debounceSearch) {
      filterObj.offset = 0;
      filterObj.limit = 1000;
      filterObj.searchQuery = debounceSearch;
    }

    if (filters.hub.length) filterObj.hub = filters.hub;

    if (filters.farmerSelected.length) {
      filterObj.farmerSelected = filters.farmerSelected;
    }

    if (filters.dateRangeStart !== "" && filters.dateRangeEnd !== "") {
      filterObj.dateRangeStart = filters.dateRangeStart;
      filterObj.dateRangeEnd = filters.dateRangeEnd;
    }

    dispatch(getFarmersV3(filterObj));
  }, [dispatch, currentPage, filters, debounceSearch]);

  // fetch user details and all the locations (to get permissions on every refresh)
  useEffect(() => {
    dispatch(getUserByEmail({ email: userDetails.email }));
    dispatch(getAllLocationsV3());
  }, [dispatch]);

  return (
    <div>
      <PartnerHeader
        search={search}
        setSearch={setSearch}
        filters={filters}
        setFilters={setFilters}
        setCurrentPage={setCurrentPage}
      />

      <div
        className={`h-[calc(100vh-72px-71px-69px-40px)] ${
          farmersListV3.result.length && "overflow-y-scroll"
        }`}
        ref={tableContainerRef}
      >
        <CustomTable
          // scrollX={0}
          // scrollY="415px"
          disableScroll
          columns={farmersColumns}
          data={farmersListV3.result}
          emptyState={
            <UserIcon className="w-4 h-4 fill-none stroke-dark-grey-text" />
          }
          emptyStateHeight={getTableContainerHeight(tableContainerRef)}
          rowClassName="group"
          pagination={{
            pageSize: LOCAL_DEFAULT_LIMIT,
            total: farmersListV3.totalRecords,
            showSizeChanger: false,
            onChange: (page) => {
              setCurrentPage(page);
            },
            current: currentPage,
            itemRender: (current, type, originalElement) => {
              const lastPage = farmersListV3.totalRecords / current;

              if (type === "prev" && current === 0) {
                return (
                  <button
                    type="button"
                    className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    style={{
                      pointerEvents: "none",
                    }}
                  >
                    <ChevronIcon className="stroke-dark-gray" />
                  </button>
                );
              }

              if (type === "prev" && current > 0) {
                return (
                  <button
                    type="button"
                    className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                  >
                    <ChevronIcon className="stroke-dark-gray" />
                  </button>
                );
              }

              if (type === "next" && lastPage <= 10) {
                return (
                  <button
                    type="button"
                    className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    style={{ pointerEvents: "none" }}
                  >
                    <ChevronIcon className="stroke-dark-gray" />
                  </button>
                );
              }

              if (type === "next") {
                return (
                  <button
                    type="button"
                    className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                  >
                    <ChevronIcon className="stroke-dark-gray" />
                  </button>
                );
              }

              return originalElement;
            },
          }}
        />
      </div>

      <DeleteFarmerModal
        visible={deleteFarmerModal?.visible}
        onCancel={() =>
          dispatch(
            updateUsers({
              key: "deleteFarmerModal",
              value: {
                visible: false,
                id: 0,
              },
            }),
          )
        }
        farmersCurrentPage={currentPage}
        setFarmersCurrentPage={setCurrentPage}
      />

      <ExportFarmerModal
        visible={exportFarmerModal}
        onCancel={() =>
          dispatch(
            updateUsers({
              key: "exportFarmerModal",
              value: false,
            }),
          )
        }
      />

      <QRCodeModal
        visible={viewModal?.visible}
        type={viewModal?.data?.assetType}
        onCancel={() =>
          dispatch(
            updateQrCode({
              key: "viewModal",
              value: { visible: false, data: {} },
            }),
          )
        }
      />

      <FarmerApprovalDrawer
        key={approveFarmerDrawer?.data?.id}
        visible={approveFarmerDrawer?.visible}
        onCancel={() =>
          dispatch(
            updateUsers({
              key: "approveFarmerDrawer",
              value: {
                visible: false,
                data: {},
              },
            }),
          )
        }
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default PartnersList;
