import React from "react";
import EditIcon from "../../../Common/Svgs/EditIcon";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";

const useThreeDotsOptions = () => {
  const options = [
    // {
    //   id: 1,
    //   value: "Update Status",
    //   icon: <EditIcon className="text-dark-gray group-hover:text-black" />,
    //   hasPermission: usePermission([PERMISSIONS.REVENUE_MODIFY_PAYMENT]),
    // },
    {
      id: 1,
      value: "Edit Commodity Weight",
      icon: <EditIcon className="text-dark-gray group-hover:text-black" />,
      hasPermission: usePermission([PERMISSIONS.REVENUE_MODIFY_PAYMENT]),
    },
  ];

  return options;
};

export default useThreeDotsOptions;
