/* eslint-disable eqeqeq */
import { useSelector } from "react-redux";
import { POWER_AFRICA_ASSETS } from "./constants";

const usePowerAfricaChecklist = () => {
  const { currentAsset, checklists } = useSelector(
    (state) => state.powerAfrica,
  );

  let data = [];

  if (currentAsset?.type == POWER_AFRICA_ASSETS.TILLER) {
    data = checklists.tiller;
  }

  if (currentAsset?.type == POWER_AFRICA_ASSETS.MILLER) {
    data = checklists.miller;
  }

  if (currentAsset?.type == POWER_AFRICA_ASSETS.WATER_PUMP) {
    data = checklists.waterPump;
  }

  if (currentAsset?.type == POWER_AFRICA_ASSETS.THRESHER) {
    data = checklists.thresher;
  }

  if (currentAsset?.type == POWER_AFRICA_ASSETS.CHAUF_CUTTER) {
    data = checklists.chaufCutter;
  }

  if (data.length) {
    return data.map((item) => ({
      id: item.partId,
      value: item.partName,
    }));
  }

  return data;
};

export default usePowerAfricaChecklist;
