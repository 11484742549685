import React, { useState } from "react";
import CustomInput from "../../Core/Components/CustomInput";
import CloseIcon from "../../Common/Svgs/CloseIcon";
import FilterItem from "../../Core/Components/FilterItem";
import useTypeFilters from "./useTypeFilters";

const QRCodeListHeader = ({
  search,
  setSearch,
  typeFilter,
  setTypeFilter,
  setCurrentPage,
}) => {
  const [focus, setFocus] = useState(false);

  // type filter data based on the permission
  const typeFilterData = useTypeFilters().filter((item) => item.hasPermission);

  const onSelect = (key, value) => {
    setCurrentPage(1);
    if (typeFilter.includes(value)) {
      const newFilters = typeFilter.filter((item) => item !== value);
      setTypeFilter(newFilters);
    } else {
      setTypeFilter((prev) => [...prev, value]);
    }
  };

  return (
    <div className="bg-background-white">
      <div className="px-6 py-3.5 flex items-center gap-x-3">
        <CustomInput
          intent="search"
          prefix={
            <img src="/assets/icons/search.svg" alt="" className="mr-2" />
          }
          value={search}
          focus={focus}
          border={focus ? "success" : "none"}
          placeholder={focus ? null : "Search ID/Asset ID"}
          onChange={(e) => setSearch(e.target.value)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          suffix={
            search.length > 0 ? (
              <CloseIcon
                className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
                onClick={() => {
                  setFocus(false);
                  setSearch("");
                }}
              />
            ) : null
          }
        />

        <FilterItem
          label="Asset Type"
          identifier="type"
          contentData={typeFilterData}
          onSelect={onSelect}
          selectedValues={typeFilter}
          className="max-h-[200px] overflow-y-scroll"
        />
      </div>
    </div>
  );
};

export default QRCodeListHeader;
