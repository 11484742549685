import React from "react";

const RequestIcon = ({ size = 16, className = "" }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M5.665 12.667h-.333c-2.667 0-4-.667-4-4V5.333q0-4 4-4h5.333q4 0 4 4v3.334q0 4-4 4h-.333c-.207 0-.407.1-.533.266l-1 1.334c-.44.586-1.16.586-1.6 0l-1-1.334a.75.75 0 0 0-.534-.266"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4.668 5.333h6.667M4.668 8.667h4"
        />
      </svg>
    </span>
  );
};

export default RequestIcon;
