import React from "react";
import { REQUEST_STATUS } from "./constants";
import TickIcon from "../../Common/Svgs/TickIcon";
import TrikeIcon from "../../Common/Svgs/TrikeIcon";
import CloseIcon from "../../Common/Svgs/CloseIcon";
import PendingIcon from "../../Common/Svgs/PendingIcon";

const getRequestStatus = (requestStatus) => {
  if (+requestStatus === REQUEST_STATUS.PENDING) {
    return (
      <div className="flex items-center h-[26px] w-[90px] py-1 px-2 gap-x-1 bg-warning-10 rounded-md">
        <PendingIcon size={16} className="text-warning" />
        <p className="font-poppins font-medium text-xs leading-[18px] text-warning">
          Pending
        </p>
      </div>
    );
  }

  if (+requestStatus === REQUEST_STATUS.REJECTED) {
    return (
      <div className="flex items-center h-[26px] w-[93px] py-1 px-2 gap-x-1 bg-alert-10 rounded-md">
        <CloseIcon className="w-4 h-4 stroke-alert-red" />
        <p className="font-poppins font-medium text-xs leading-[18px] text-alert-red">
          Rejected
        </p>
      </div>
    );
  }

  if (requestStatus === REQUEST_STATUS.ACCEPTED) {
    return (
      <div className="flex items-center h-[26px] w-[112px] py-1 px-2 gap-x-1 bg-green-10 rounded-md">
        <TickIcon size={16} className="text-green" />
        <p className="font-poppins font-medium text-xs leading-[18px] text-green">
          Accepted
        </p>
      </div>
    );
  }

  if (requestStatus === REQUEST_STATUS.ASSIGN_TRIKE) {
    return (
      <div className="flex items-center h-[26px] w-[112px] py-1 px-2 gap-x-1 bg-green rounded-md">
        <TickIcon size={16} className="text-background-white" />
        <p className="font-poppins font-medium text-xs leading-[18px] text-background-white">
          Assign Trike
        </p>
      </div>
    );
  }

  if (requestStatus === REQUEST_STATUS.TRIKE_ASSIGNING_COMPLETED) {
    return (
      <div className="flex items-center h-[26px] w-[128px] py-1 px-2 gap-x-1 bg-green-10 rounded-md">
        <TrikeIcon size={16} className="text-green" />
        <p className="font-poppins font-medium text-xs leading-[18px] text-green">
          Trike Assigned
        </p>
      </div>
    );
  }

  return (
    <div className="flex items-center h-[26px] w-[93px] py-1 px-2 gap-x-1 bg-alert-10 rounded-md">
      <CloseIcon className="w-4 h-4 stroke-alert-red" />
      <p className="font-poppins font-medium text-xs leading-[18px] text-alert-red">
        Rejected
      </p>
    </div>
  );
};

export default getRequestStatus;
