import React from "react";
import { Controller } from "react-hook-form";
import { PopoverDropdown } from "../../../Core/Components/CustomDropdown";
import { useAgentsOnAdd, usePowerAfricaAssetOnAdd } from "./useOnAdd";
import { assetType, motorTypes, powerSources } from "./constants";

const Step2 = ({ control, formState, setValue, clearErrors, watch }) => {
  const agents = useAgentsOnAdd(watch);
  const assets = usePowerAfricaAssetOnAdd(watch);

  // errors
  const agentError = formState?.errors?.agentName?.message;
  const motorTypeError = formState?.errors?.motorTypeName?.message;

  return (
    <div className="bg-background-white p-6 h-[calc(100vh-72px-54px-90px)] overflow-y-scroll flex flex-col gap-y-6">
      <div>
        <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
          Assign Agent
        </p>

        <Controller
          name="agentName"
          rules={{
            required: "Required.",
          }}
          control={control}
          render={({ field }) => (
            <PopoverDropdown
              {...field}
              enableSearch
              searchPlaceholder="Search Name"
              placeholder="Select Agent"
              formState={formState}
              options={agents}
              className="max-h-[200px] overflow-y-scroll"
              onSelect={(data) => {
                setValue("agentId", data.id);
                setValue("agentName", data.value);
                clearErrors("agentName");
              }}
            />
          )}
        />

        <p
          className={`font-poppins text-xs font-light capitalize leading-4.5 ${
            agentError ? "mt-2 text-alert-red visible" : "invisible"
          }`}
        >
          {agentError}
        </p>
      </div>

      <div>
        <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
          Assign Asset
        </p>

        <Controller
          name="assetTypeName"
          rules={{
            required: "Required.",
          }}
          control={control}
          render={({ field }) => (
            <PopoverDropdown
              {...field}
              enableSearch
              searchPlaceholder="Search Asset"
              placeholder="Select Asset"
              formState={formState}
              options={assetType}
              className="max-h-[150px] overflow-y-scroll"
              onSelect={(data) => {
                setValue("assetTypeId", data.id);
                setValue("assetTypeName", data.value);
                clearErrors("assetTypeName");
              }}
            />
          )}
        />
      </div>

      <div>
        <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
          Asset ID
        </p>

        <Controller
          name="assetRegistrationNo"
          rules={{
            required: "Required.",
          }}
          control={control}
          render={({ field }) => (
            <PopoverDropdown
              {...field}
              enableSearch
              searchPlaceholder="Search Asset"
              placeholder="Asset ID"
              formState={formState}
              options={assets}
              className="max-h-[200px] overflow-y-scroll"
              onSelect={(data) => {
                setValue("assetId", data.id);
                setValue("assetRegistrationNo", data.value);
                clearErrors("assetRegistrationNo");
              }}
            />
          )}
        />

        <p
          className={`font-poppins text-xs font-light capitalize leading-4.5 ${
            agentError ? "mt-2 text-alert-red visible" : "invisible"
          }`}
        >
          {agentError}
        </p>
      </div>

      <div>
        <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
          Power Source
        </p>

        <Controller
          name="powerSourceName"
          rules={{
            required: "Required.",
          }}
          control={control}
          render={({ field }) => (
            <PopoverDropdown
              {...field}
              enableSearch
              searchPlaceholder="Search Power Source"
              placeholder="Select Power Source"
              formState={formState}
              options={powerSources}
              className="max-h-[200px] overflow-y-scroll"
              onSelect={(data) => {
                setValue("powerSourceId", data.id);
                setValue("powerSourceName", data.value);
                clearErrors("powerSourceName");
              }}
            />
          )}
        />
      </div>

      <div>
        <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
          Type Of Motor
        </p>

        <Controller
          name="motorTypeName"
          rules={{
            required: "Required.",
          }}
          control={control}
          render={({ field }) => (
            <PopoverDropdown
              {...field}
              enableSearch
              searchPlaceholder="Search Motor"
              placeholder="Select Motor"
              formState={formState}
              options={motorTypes}
              className="max-h-[200px] overflow-y-scroll"
              onSelect={(data) => {
                setValue("motorTypeId", data.id);
                setValue("motorTypeName", data.value);
                clearErrors("motorTypeName");
              }}
            />
          )}
        />

        <p
          className={`font-poppins text-xs font-light capitalize leading-4.5 ${
            motorTypeError ? "mt-2 text-alert-red visible" : "invisible"
          }`}
        >
          {motorTypeError}
        </p>
      </div>
    </div>
  );
};

export default Step2;
