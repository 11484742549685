/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomLayout from "../../Core/Layout/CustomLayout";
import RequestListHeader from "./RequestListHeader";
import { requestColumns, requestData } from "./data";
import CustomTable from "../../Core/Components/CustomTable";
import RequestIcon from "../../Common/Svgs/RequestIcon";
import { getTableContainerHeight } from "../../Utils/getTableContainerHeight";
import ChevronIcon from "../../Common/Svgs/ChevronIcon";
import RequestDetailsDrawer from "./RequestDetailsDrawer";
import { updateRequest } from "../../Redux/Slices/requests.slice";
import RejectRequestModal from "./RejectRequestModal";
import useDebounce from "../../Core/hooks/useDebounce";
import { getRequestList } from "../../Redux/APIs/requestsAPI";
import { getUserByEmail } from "../../Redux/APIs/systemUsersAPI";
import ToastMessageModal from "./ToastMessageModal";
import { getAllTrikesV3 } from "../../Redux/APIs/trikesAPI";
import { getAllBatteriesV3 } from "../../Redux/APIs/batteriesAPI";

const LOCAL_DEFAULT_LIMIT = 10;

const RequestList = () => {
  const dispatch = useDispatch();

  const {
    requestDetailsVisible,
    rejectModalVisible,
    // requestList,
    showToastMessage,
  } = useSelector((state) => state.requests);

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    hub: [],
    type: [],
    status: [],
  });

  const tableContainerRef = useRef(null);

  const debounceSearch = useDebounce(search, 500);

  // get charger list with filters
  useEffect(() => {
    const filterObj = {
      limit: LOCAL_DEFAULT_LIMIT,
      offset: ((currentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
    };

    if (debounceSearch) filterObj.searchQuery = debounceSearch;
    if (filters.hub.length) filterObj.hubs = filters.hub;
    if (filters.type.length) filterObj.type = filters.type;
    if (filters.status.length) filterObj.status = filters.status;

    dispatch(getRequestList(filterObj));
  }, [dispatch, currentPage, debounceSearch, filters]);

  // fetch user details (to get permissions on every refresh), trikes and batteries
  useEffect(() => {
    dispatch(getUserByEmail({ email: userDetails.email }));
    dispatch(getAllTrikesV3());
    dispatch(getAllBatteriesV3());
  }, [dispatch]);

  // clear the toast message
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (showToastMessage?.visible) {
        dispatch(
          updateRequest({
            key: "showToastMessage",
            value: {
              visible: false,
              data: {},
            },
          }),
        );
      }
    }, 5000);

    return () => clearTimeout(timeout);
  }, [dispatch, showToastMessage]);

  return (
    <CustomLayout pageName="Requests">
      <RequestListHeader
        filters={filters}
        setFilters={setFilters}
        search={search}
        setSearch={setSearch}
        setCurrentPage={setCurrentPage}
      />

      <div
        className={`h-[calc(100vh-72px-69px-40px)] ${
          requestData.length && "overflow-y-scroll"
        }`}
        ref={tableContainerRef}
      >
        <CustomTable
          // scrollX={0}
          // scrollY="518px"
          disableScroll
          columns={requestColumns}
          // data={requestList.result}
          data={requestData}
          emptyState={<RequestIcon size={16} className="text-dark-grey-text" />}
          emptyStateHeight={getTableContainerHeight(tableContainerRef)}
          rowClassName="group"
          pagination={{
            pageSize: LOCAL_DEFAULT_LIMIT,
            // total: requestList.totalRecords,
            total: requestData.length,
            showSizeChanger: false,
            onChange: (page) => {
              setCurrentPage(page);
            },
            current: currentPage,
            itemRender: (current, type, originalElement) => {
              // const lastPage = requestList.totalRecords / current;
              const lastPage = requestData.length / current;

              if (type === "prev" && current === 0) {
                return (
                  <button
                    type="button"
                    className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    style={{
                      pointerEvents: "none",
                    }}
                  >
                    <ChevronIcon className="stroke-dark-gray" />
                  </button>
                );
              }

              if (type === "prev" && current > 0) {
                return (
                  <button
                    type="button"
                    className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                  >
                    <ChevronIcon className="stroke-dark-gray" />
                  </button>
                );
              }

              if (type === "next" && lastPage <= 10) {
                return (
                  <button
                    type="button"
                    className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    style={{ pointerEvents: "none" }}
                  >
                    <ChevronIcon className="stroke-dark-gray" />
                  </button>
                );
              }

              if (type === "next") {
                return (
                  <button
                    type="button"
                    className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                  >
                    <ChevronIcon className="stroke-dark-gray" />
                  </button>
                );
              }

              return originalElement;
            },
          }}
        />
      </div>

      <RequestDetailsDrawer
        visible={requestDetailsVisible}
        onCancel={() => {
          dispatch(
            updateRequest({
              key: "requestDetailsVisible",
              value: false,
            }),
          );

          dispatch(
            updateRequest({
              key: "transportDetailStep",
              value: 1,
            }),
          );

          dispatch(
            updateRequest({
              key: "currentRequest",
              value: {},
            }),
          );
        }}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />

      <RejectRequestModal
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        visible={rejectModalVisible}
        onCancel={() => {
          dispatch(
            updateRequest({
              key: "rejectModalVisible",
              value: false,
            }),
          );

          dispatch(
            updateRequest({
              key: "requestDetailsVisible",
              value: false,
            }),
          );
        }}
      />

      <ToastMessageModal
        visible={showToastMessage?.visible}
        onCancel={() =>
          dispatch(
            updateRequest({
              key: "showToastMessage",
              value: {
                visible: false,
                data: {},
              },
            }),
          )
        }
        title={showToastMessage?.data?.title}
        description={showToastMessage?.data?.description}
      />
    </CustomLayout>
  );
};

export default RequestList;
