import React from "react";
import getUserType from "../getUserType";
import { getCollectionPaymentStatus } from "../getPaymentStatus";
import TripsDue, { sortByTripsDue } from "../PaymentList/TripsDue";
import TotalAmountDue, {
  sortByTotalAmountDue,
} from "../PaymentList/TotalAmountDue";
import PaymentStatus from "./PaymentStatus";
import {
  FinanceDueIcon,
  FinanceHoverIcon,
} from "../../../Common/Svgs/FinanceIcon";
import getFormatedFloat from "../../../Utils/getFormatedFloat";
import ListDropdown from "./ListDropdown";
import { PAYMENT_STATUS } from "../data";

export const tempCollectionColumns = [
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Name
      </div>
    ),
    dataIndex: "firstName",
    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    render: (text, record) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-black capitalize">
        {text ? `${text} ${record.lastName}` : ""}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Main Hub
      </div>
    ),
    dataIndex: "hubName",
    sorter: (a, b) => a.hubName.localeCompare(b.hubName),
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        User Type
      </div>
    ),
    dataIndex: "userType",
    render: (text) => <div className="max-w-max">{getUserType(text)}</div>,
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        No. of Trips Due
      </div>
    ),
    dataIndex: "tripsDue",
    sorter: (a, b) => sortByTripsDue(a, b),
    render: (text, record) => <TripsDue payment={record} />,
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Total Amount Due
      </div>
    ),
    dataIndex: "amountDue",
    sorter: (a, b) => sortByTotalAmountDue(a, b),
    render: (text, record) => <TotalAmountDue payment={record} />,
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Payment Status
      </div>
    ),
    dataIndex: "paymentStatus",
    render: (text, record) => <PaymentStatus payment={record} />,
  },
];

export const collectionColumns = [
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Name
      </div>
    ),
    dataIndex: "firstName",
    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    render: (text, record) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-black capitalize">
        {text ? `${text} ${record.lastName}` : ""}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Main Hub
      </div>
    ),
    dataIndex: "hubName",
    sorter: (a, b) => a.hubName.localeCompare(b.hubName),
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        User Type
      </div>
    ),
    dataIndex: "entityType",
    render: (text) => <div className="max-w-max">{getUserType(+text)}</div>,
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Total Amount Due
      </div>
    ),
    dataIndex: "totalAmountDue",
    sorter: (a, b) => a.totalAmountDue - b.totalAmountDue,
    render: (text, record) => {
      const value =
        +record.paymentStatus === PAYMENT_STATUS.COMPLETED
          ? 0
          : record.totalAmountDue;

      return (
        <div className="text-xs font-medium font-poppins leading-4.5">
          {value > 0 ? `${value} KSH` : "--"}
        </div>
      );
    },
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Payment Status
      </div>
    ),
    dataIndex: "paymentStatus",
    render: (text) => <div>{getCollectionPaymentStatus(text)}</div>,
  },
  {
    dataIndex: "id",
    render: (text, record) => (
      <div
        className="invisible group-hover:visible"
        onClick={(e) => e.stopPropagation()}
      >
        {record?.totalAmountDue > 0 ? <ListDropdown detail={record} /> : null}
      </div>
    ),
  },
];

export const CollectionKPIData = (kpidata) => {
  return [
    {
      title: "Amount Due From Partners",
      background: "bg-alert-red",
      countColor: "#C84630",
      icon: <FinanceDueIcon size={16} className="text-background-white" />,
      count: kpidata?.amountDue ? getFormatedFloat(kpidata.amountDue) : 0,
    },
    {
      title: "Total Amount Collected",
      background: "bg-yellow",
      countColor: "#000000",
      icon: <FinanceHoverIcon size={16} className="text-green" />,
      count: kpidata?.amountCollected
        ? getFormatedFloat(kpidata.amountCollected)
        : 0,
    },
  ];
};

export const collectionPaymentStatusOptions = [
  {
    id: 1,
    value: getCollectionPaymentStatus(1),
  },
  // {
  //   id: 2,
  //   value: getCollectionPaymentStatus(2),
  // },
  {
    id: 3,
    value: getCollectionPaymentStatus(3),
  },
];
