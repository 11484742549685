export const SESSION_STATUS = {
  ONGOING: 1,
  UPCOMING: 2,
  COMPLETED: 3,
};

export const SESSION_TYPE = {
  TILLING: 1,
  MILLING: 2,
  WATER_PUMPING: 3,
  THRESHING: 4,
  CHAUF_CUTTING: 5,
};
