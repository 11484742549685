export const NOTIFICATION_TYPE = {
  TRIKE_GEOFENCE: 1,
  ASSIGN_BATTERIES: 2,
  DRIVER_LICENSE_EXPIRE: 3,
  ACCEPT_BATTERY_REQUEST: 4,
  HUB_ACCEPTED_REQUEST: 5,
  HUB_REJECTED_REQUEST: 6,
  BATTERY_CHECK_IN_REQUEST: 7,
  BATTERY_CHECK_OUT_REQUEST: 8,
  ASSIGN_BATTERIES_FOR_SESSION: 9,
  NEW_PURCHASE_REQUEST: 10,
  NEW_SESSION_REQUEST: 11,
  NEW_TRANSPORT_REQUEST: 12,
  NEW_FARMER_ADDED: 13,
};

export const notificationList = [
  {
    id: 1,
    type: 1,
    metaData: {
      trikeId: 100,
      registrationNo: "KTWC_100R",
    },
    submittedAt: new Date(),
  },
  {
    id: 2,
    type: 2,
    metaData: {
      tripId: 236,
      startTime: "5:30 Am",
      hubName: "Kilgoris Agr-E-Hub",
      hubId: 14,
    },
    submittedAt: new Date(),
  },

  {
    id: 3,
    type: 3,
    metaData: {
      driverId: 100,
      driverName: "Kevin Shah",
      expiryDate: "Dec 20, 2024",
    },
    submittedAt: new Date(),
  },
  {
    id: 4,
    type: 4,
    metaData: {
      hubId: 14,
      hubName: "Kilgoris Agr-E-Hub",
      batteries: 3,
    },
    submittedAt: new Date(),
  },
  {
    id: 5,
    type: 5,
    metaData: {
      hubName: "Ogembo Hub",
      batteries: 4,
    },
    submittedAt: new Date(),
  },
  {
    id: 6,
    type: 6,
    metaData: {
      hubName: "Ogembo Hub",
      batteries: 4,
    },
    submittedAt: new Date(),
  },
  {
    id: 7,
    type: 7,
    metaData: {
      hubName: "Ogembo Hub",
    },
    submittedAt: new Date(),
  },
  {
    id: 8,
    type: 8,
    metaData: {
      hubName: "Ogembo Hub",
    },
    submittedAt: new Date(),
  },
];
