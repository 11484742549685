/* eslint-disable eqeqeq */
/* eslint-disable no-console */
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { client } from "../../../Utils/axiosClient";
import { POWER_AFRICA_ASSET_STATUS } from "../../PowerAfrica/constants";

export const useFarmersOnAdd = () => {
  const [farmers, setFarmers] = useState([]);

  const getFarmers = useCallback(async () => {
    try {
      const response = await client.get(
        `${process.env.REACT_APP_API_URL}/farmers/filter-data`,
      );

      const temp = response.data.farmers.map((item) => ({
        id: item.id,
        value: item.name,
        hubId: item.hubId,
        hubName: item.hubName,
      }));

      setFarmers([...temp]);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getFarmers();
  }, [getFarmers]);

  return farmers;
};

export const useFarmsForFarmer = (farmerId) => {
  const { id } = useParams();

  const { currentSession } = useSelector((state) => state.sessions);

  const [farmsArr, setFarmsArr] = useState([]);

  const getFarms = useCallback(async () => {
    try {
      const response = await client.get(
        `${process.env.REACT_APP_API_URL}/farmers/get-farms`,
        {
          params: {
            farmerId: +farmerId,
          },
        },
      );

      // while editing a session, the selected farm co-ordinates will always appear first
      if (id && currentSession?.farmerId == farmerId) {
        const selected = response.data.farms.find(
          (item) => item.id == currentSession?.farmerLocationId,
        );

        const temp = response.data.farms
          .filter((f) => f.id != currentSession?.farmerLocationId)
          .map((item, index) => ({
            id: item?.id,
            value: `Farm ${index + 2} (${item?.lat}, ${item?.long})`,
          }));

        setFarmsArr([
          {
            id: selected?.id,
            value: `Farm 1 (${selected?.lat}, ${selected?.long})`,
          },
          ...temp,
        ]);
      } else {
        const temp = response.data.farms.map((item, index) => ({
          id: item?.id,
          value: `Farm ${index + 1} (${item?.lat}, ${item?.long})`,
        }));

        setFarmsArr([...temp]);
      }
    } catch (error) {
      console.log(error);
    }
  }, [farmerId, currentSession, id]);

  useEffect(() => {
    if (farmerId) getFarms();
  }, [getFarms, farmerId]);

  return farmsArr;
};

// include all the drivers
// filtering based on selected hub id
export const useAgentsOnAdd = (watch) => {
  const { allDrivers } = useSelector((state) => state.users);

  if (!allDrivers.length) return [];

  let agents = allDrivers;
  const localHubIdState = watch("hubId");

  if (localHubIdState) {
    agents = agents.filter((agent) => agent.hubId == localHubIdState);
  }

  return agents.map((item) => ({
    id: item.id,
    value: `${item.firstName} ${item.lastName}`,
  }));
};

// filtering based on selected activity and hub id
export const usePowerAfricaAssetOnAdd = (watch) => {
  const { allAssets } = useSelector((state) => state.powerAfrica);

  if (!allAssets.length) return [];

  const localActivityId = watch("activityId");

  let assets = allAssets.filter(
    (asset) =>
      +asset.status === POWER_AFRICA_ASSET_STATUS.HUB_STATION &&
      asset.type == localActivityId,
  );

  // console.log(assets, "check assets");

  const localHubIdState = watch("hubId");

  if (localHubIdState) {
    assets = assets.filter((asset) => +asset.hubId == localHubIdState);
  }

  return assets.map((item) => ({
    id: item.id,
    value: item.registrationNo,
  }));
};
