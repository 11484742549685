import React from "react";
import { PAYMENT_STATUS } from "../data";
import getPaymentStatus from "../getPaymentStatus";

const PaymentStatus = ({ payment, width = "" }) => {
  if (
    payment?.details?.every(
      (item) => item.paymentStatus === PAYMENT_STATUS.COMPLETED,
    )
  ) {
    return (
      <div className={`${width || "w-[120px]"}`}>
        {getPaymentStatus(PAYMENT_STATUS.COMPLETED)}
      </div>
    );
  }

  if (
    payment?.details?.every(
      (item) => item.paymentStatus === PAYMENT_STATUS.PENDING,
    )
  ) {
    return (
      <div className={`${width || "w-[120px]"}`}>
        {getPaymentStatus(PAYMENT_STATUS.PENDING)}
      </div>
    );
  }

  return (
    <div className={`${width || "w-[130px]"}`}>
      {getPaymentStatus(PAYMENT_STATUS.PARTIAL)}
    </div>
  );
};

export default PaymentStatus;
