import { SERVICE_ASSET_TYPE } from "./index";

const getServiceAssetId = (type) => {
  if (type === "trike") return SERVICE_ASSET_TYPE.TRIKE;
  if (type === "battery") return SERVICE_ASSET_TYPE.BATTERY;
  if (type === "tiller") return SERVICE_ASSET_TYPE.TILLER;
  if (type === "miller") return SERVICE_ASSET_TYPE.MILLER;
  if (type === "thresher") return SERVICE_ASSET_TYPE.THRESHER;
  if (type === "water pump") return SERVICE_ASSET_TYPE.WATER_PUMP;
  if (type === "chauf cutter") return SERVICE_ASSET_TYPE.CHAUF_CUTTER;

  return SERVICE_ASSET_TYPE.TRIKE;
};

export default getServiceAssetId;
