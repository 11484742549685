import React, { useEffect, useRef } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { useSelector } from "react-redux";
import { INITIAL_ZOOM_LEVEL, MAX_BOUNDS } from "../../Home/Data/HomeData";
import CustomModal from "../../../Core/Components/Modal/CustomModal";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import ZoomButton, {
  RelocateButton,
} from "../../../Core/Components/MapMarkerButtons";
import getMarkerIcon from "./getMarkerIcon";

const AssetFullScreenMap = ({ visible, onCancel }) => {
  const { currentAsset } = useSelector((state) => state.powerAfrica);

  const mapRef = useRef(null);

  const getAssetLatLngs = () => {
    return [-0.6803741, 34.7569482];
  };

  // in order to load the map correctly
  useEffect(() => {
    const timeout = setTimeout(() => {
      mapRef?.current?.invalidateSize(true);
    }, 200);

    return () => clearTimeout(timeout);
  });

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="1196px" centered>
      <div
        className="relative overflow-hidden rounded-md bg-black"
        style={{
          width: "1196px",
          height: "700px",
        }}
      >
        <div className="absolute z-30 top-6 right-6">
          <div className="flex items-center justify-center w-8 h-8 rounded-md cursor-pointer bg-primary">
            <CloseIcon className="w-4 h-4 stroke-white" onClick={onCancel} />
          </div>
        </div>

        <MapContainer
          center={getAssetLatLngs()}
          zoom={INITIAL_ZOOM_LEVEL}
          maxBounds={MAX_BOUNDS}
          className="z-10 w-full h-full"
          zoomControl={false}
          ref={mapRef}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

          <Marker
            position={getAssetLatLngs()}
            icon={getMarkerIcon(+currentAsset.type)}
          />
        </MapContainer>

        <div className="absolute bottom-6 right-6 z-30">
          <div className="flex flex-col gap-y-3">
            <RelocateButton
              mapRef={mapRef}
              initialZoomlevel={INITIAL_ZOOM_LEVEL}
              center={getAssetLatLngs()}
            />
            <ZoomButton mapRef={mapRef} />
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default AssetFullScreenMap;
