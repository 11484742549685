/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../Core/Components/Modal/CustomModal";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import Button from "../../../Core/Components/CustomButton";
import TripsIcon from "../../../Common/Svgs/TripsIcon";
import CustomInput from "../../../Core/Components/CustomInput";
import { client } from "../../../Utils/axiosClient";
import { updateTrips } from "../../../Redux/Slices/trips.slice";
import {
  getFarmerSummary,
  getOverallSummary,
} from "../../../Redux/APIs/tripsAPI";

const CommodityRow = ({ item, current, handleValueChange, handleFocus }) => {
  return (
    <div className="flex items-center justify-between py-3 px-6 border-b border-light-grey">
      <p className="flex-1 font-aileron font-semibold text-xs text-black capitalize">
        {item?.produce}
      </p>

      <p className="flex-1 font-aileron font-semibold text-xs text-dark-gray capitalize">
        {item?.weightCollected} {item.unit.split(" ")?.[1]}
      </p>

      <div className="flex-1 flex items-center">
        <CustomInput
          intent="editCommodity"
          text="generalSettings"
          value={current.new || ""}
          focus={current.focus}
          border={current.focus ? "success" : "primary"}
          placeholder={current.focus ? null : "Enter weight"}
          onChange={(e) => handleValueChange(e, current.id)}
          onFocus={() => handleFocus(current.id, true)}
          onBlur={() => handleFocus(current.id, false)}
        />
        <p className="ml-2 flex-1 font-aileron font-semibold text-xs text-dark-gray capitalize">
          {item.unit.split(" ")?.[1]}
        </p>
      </div>
    </div>
  );
};

const EditCommodityWeightModal = ({ visible, onCancel }) => {
  const dispatch = useDispatch();

  const { editCommodityModal, currentTrip, currentStop, currentFarmer } =
    useSelector((state) => state.trips);

  const produceDetails = editCommodityModal.data || [];

  const [newWeights, setNewWeights] = useState([]);

  const handleValueChange = useCallback((e, id) => {
    setNewWeights((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, new: e.target.value } : item,
      ),
    );
  }, []);

  const handleFocus = useCallback((id, focus) => {
    setNewWeights((prev) =>
      prev.map((item) => (item.id === id ? { ...item, focus } : item)),
    );
  }, []);

  const handleSaveChanges = async () => {
    const current = editCommodityModal?.data[0];

    const payload = {
      tripId: current?.tripId,
      farmerId: current?.farmerId,
      driverId: current?.driverId,
      values: [],
    };

    newWeights.forEach((item) => {
      payload.values.push({
        produceId: item.produceId,
        oldWeight: item.old,
        newWeight: +item.new,
        stopId: item.stopId,
      });
    });

    try {
      dispatch(updateTrips({ key: "loading", value: true }));

      await client.post(
        `${process.env.REACT_APP_API_URL}/trip/edit-commodity-weight`,
        payload,
      );

      onCancel();

      dispatch(
        getFarmerSummary({
          tripId: currentStop.tripId,
          stopId: currentStop.stopId,
          farmerId: currentFarmer.id,
        }),
      );

      dispatch(getOverallSummary({ tripId: currentTrip.id }));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(updateTrips({ key: "loading", value: false }));
    }
  };

  useEffect(() => {
    if (produceDetails.length) {
      setNewWeights(
        produceDetails.map((item, index) => ({
          id: index + 1,
          stopId: item.stopId,
          produceId: item.produceId,
          produceName: item.produce,
          old: item.weightCollected,
          new: "",
          focus: false,
        })),
      );
    }
  }, [produceDetails]);

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="536px">
      <div
        style={{
          boxShadow:
            "12px 12px 16px -4px rgba(16, 24, 40, 0.08), -4px -4px 6px -2px rgba(16, 24, 40, 0.03)",
        }}
      >
        <div className="flex items-center justify-between px-4 pt-4">
          <div className="flex items-center gap-x-3">
            <div className="w-12 h-12 flex items-center justify-center rounded-full bg-primary">
              <TripsIcon size={24} className="text-background-white" />
            </div>
            <p className="font-poppins font-medium text-base text-black">
              Trip ID: {editCommodityModal?.data[0]?.tripId}
            </p>
          </div>

          <button type="button" className="mr-2" onClick={onCancel}>
            <CloseIcon className="stroke-dark-gray w-6 h-6" />
          </button>
        </div>

        <div className="mt-6 px-6">
          <h1 className="font-poppins text-xs font-medium text-black">
            Edit Commodity weight
          </h1>

          <p className="mt-1 font-aileron text-xs font-normal text-dark-gray">
            Please enter the new weight of the commodities in this trip.
          </p>
        </div>

        <div className="mt-4 bg-background py-3 px-6 flex items-center">
          <p className="flex-1 font-aileron font-normal text-10 text-dark-gray">
            Type of Produce
          </p>
          <p className="flex-1 font-aileron font-normal text-10 text-dark-gray">
            Original Weight
          </p>
          <p className="flex-1 font-aileron font-normal text-10 text-dark-gray">
            New Weight
          </p>
        </div>

        <div>
          {produceDetails?.map((item, index) => {
            const current = newWeights?.find(
              (i) =>
                i.produceId === item?.produceId &&
                i.old === item?.weightCollected,
            );

            return current ? (
              <CommodityRow
                key={index}
                item={item}
                current={current}
                handleValueChange={handleValueChange}
                handleFocus={handleFocus}
              />
            ) : null;
          })}
        </div>

        <div className="flex justify-end gap-x-3 p-6">
          <Button colors="grey" size="primary" onClick={onCancel}>
            Cancel
          </Button>
          <Button colors="primary" size="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default EditCommodityWeightModal;
