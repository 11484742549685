/* eslint-disable no-console */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "antd";
import { updateRevenue } from "../../../Redux/Slices/revenue.slice";
import useThreeDotsOptions from "./useThreeDotsOptions";
import { PAYMENT_RECEIVER_TYPE } from "../data";

const ListDropdown = ({ detail }) => {
  const dispatch = useDispatch();

  const { paymentDetailsPopoverId, selectedPayment } = useSelector(
    (state) => state.revenue,
  );

  const [open, setOpen] = useState(false);

  const options = useThreeDotsOptions().filter((item) => item.hasPermission);

  const onOptionClick = async (optionId) => {
    // setOpen(false);

    switch (optionId) {
      case 1:
        // dispatch(
        //   updateRevenue({
        //     key: "selectedPaymentDetailStatusId",
        //     value: detail.id,
        //   }),
        // );

        setOpen(false);

        dispatch(
          updateRevenue({
            key: "editCommodityModal",
            value: {
              visible: true,
              current: detail,
            },
          }),
        );

        break;

      default:
        break;
    }
  };

  const handleOpenChange = (state) => {
    setOpen(state);

    dispatch(
      updateRevenue({
        key: "paymentDetailsPopoverId",
        value: detail.tripId,
      }),
    );
  };

  if (
    selectedPayment?.entityType === PAYMENT_RECEIVER_TYPE.DRIVER ||
    !options.length
  ) {
    return <div />;
  }

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      open={open && paymentDetailsPopoverId === detail.tripId}
      arrow={false}
      onOpenChange={(state) => handleOpenChange(state)}
      content={
        <div
          className="rounded-md bg-background-white py-1 relative"
          style={{
            width: "196px",
            boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          {options.map((option) => (
            <button
              type="button"
              className="w-full flex items-center gap-x-3 px-3 py-2 group hover:bg-background"
              key={option.id}
              onClick={() => onOptionClick(option.id)}
            >
              {option.icon}
              <p className="font-aileron text-xs font-semibold capitalize text-dark-gray group-hover:text-black">
                {option.value}
              </p>
            </button>
          ))}

          {/* {detail.id === selectedPaymentDetailStatusId && (
            <div className="mt-2 absolute">
              <UpdateStatus
                title="Payment Status"
                current={detail.paymentStatus}
                onClick={handlePaymentStatus}
              />
            </div>
          )} */}
        </div>
      }
    >
      <div className="cursor-pointer">
        <img src="/assets/icons/trips/more.svg" alt="dropdown" />
      </div>
    </Popover>
  );
};

export default ListDropdown;
