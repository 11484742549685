import { createAsyncThunk } from "@reduxjs/toolkit";
import { client as axios } from "../../Utils/axiosClient";

export const getRequestList = createAsyncThunk(
  "requests/getRequestList",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/ussd/get-request-list`,
        {
          params,
        },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const rejectRequest = createAsyncThunk(
  "requests/rejectRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/ussd/reject-request`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
