import { createSlice } from "@reduxjs/toolkit";
import {
  createSession,
  deleteSession,
  editSession,
  getSessionById,
  getSessionKPI,
  getSessions,
} from "../APIs/sessionsAPI";

const initialState = {
  loading: false,
  error: {},
  status: "idle",
  kpi: {
    total: 0,
    ongoing: 0,
    upcoming: 0,
    completed: 0,
  },
  sessionList: {
    result: [],
    totalRecords: 0,
  },
  currentSession: {},
  threeDotsPopoverId: 0,
  showToastMessage: {
    visible: false,
    data: {},
  },
  deleteSessionModal: false,
  checklistModal: false,
  createSessionStep: 1,
  sessionThroughUSSD: false,
};

export const sessionsSlice = createSlice({
  name: "sessions",
  initialState,
  reducers: {
    updateSession: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers(builder) {
    // create session
    builder.addCase(createSession.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createSession.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(createSession.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get sessions
    builder.addCase(getSessions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSessions.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.sessionList = action.payload;
    });
    builder.addCase(getSessions.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get session KPI
    builder.addCase(getSessionKPI.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSessionKPI.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.kpi = action.payload.kpi;
    });
    builder.addCase(getSessionKPI.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // create session
    builder.addCase(editSession.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editSession.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(editSession.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // delete session
    builder.addCase(deleteSession.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteSession.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(deleteSession.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    builder.addCase(getSessionById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSessionById.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.currentSession = action.payload.session;
    });
    builder.addCase(getSessionById.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });
  },
});

export const { updateSession } = sessionsSlice.actions;

export default sessionsSlice.reducer;
