import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomLayout from "../../Core/Layout/CustomLayout";
import TrikesList from "../Trikes/phase3/TrikesList";
import Header from "./Header";
import usePermission from "../../Core/hooks/usePermission";
import { PERMISSIONS } from "../Login/constants";
import UnauthorizedAccess from "../../Core/Components/UnauthorizedAccess";
import PowerAfricaAssetList from "../PowerAfrica/PowerAfricaAssetList";
import ViewAllKPIs from "../PowerAfrica/ViewAllKPIs";

const AssetList = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { viewAllKPIs } = useSelector((state) => state.powerAfrica);

  const selectedTab = searchParams.get("tab");

  const hasTrikeModulePermission = usePermission([
    PERMISSIONS.TRIKE_VIEW,
    PERMISSIONS.TRIKE_VIEW_AND_EDIT,
  ]);

  const hasPowerAfricaModulePermission = usePermission([
    PERMISSIONS.POWER_AFRICA_ASSET_VIEW,
    PERMISSIONS.POWER_AFRICA_ASSET_VIEW_AND_EDIT,
  ]);

  useEffect(() => {
    if (!searchParams.has("tab")) {
      searchParams.set("tab", "trikes");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  // if module permission does not exist
  if (selectedTab === "trikes" && !hasTrikeModulePermission)
    return <UnauthorizedAccess />;

  if (selectedTab === "agri-tech" && !hasPowerAfricaModulePermission)
    return <UnauthorizedAccess />;

  const getBody = () => {
    if (selectedTab === "trikes") {
      return <TrikesList />;
    }

    if (selectedTab === "agri-tech" && viewAllKPIs) {
      return <ViewAllKPIs />;
    }

    return <PowerAfricaAssetList />;
  };

  return (
    <CustomLayout pageName="Assets">
      <Header />
      {/* {selectedTab === "trikes" ? <TrikesList /> : <PowerAfricaAssetList />} */}
      {getBody()}
    </CustomLayout>
  );
};

export default AssetList;
