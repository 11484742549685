/* eslint-disable no-console */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SpoiltMilkIcon from "../../../Common/Svgs/SpoiltMilkIcon";
import { updateTrips } from "../../../Redux/Slices/trips.slice";
import EditIcon from "../../../Common/Svgs/EditIcon";
import { getFarmerSummary } from "../../../Redux/APIs/tripsAPI";

const activity = {
  1: "Collection",
  2: "Delivery",
};

const ListingsIcon = () => {
  return (
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        fill="none"
        viewBox="0 0 16 17"
      >
        <path stroke="#DFDFDF" d="M1 0v7a4 4 0 004 4h11" />
      </svg>
    </span>
  );
};

const SpoiltMilk = ({ reason, weight }) => {
  return (
    <div className="bg-background rounded-md py-2 px-4 mt-2">
      <div className="flex items-center gap-x-2 w-full pb-2 border-b border-[#E7E7E7]">
        <SpoiltMilkIcon />
        <p className="font-aileron text-xs font-semibold text-alert-red">
          Spoilt Milk
        </p>
      </div>

      <div className="mt-2 flex items-center justify-between">
        <p className="font-aileron text-xs font-normal text-grey-200">
          Total Weight
        </p>
        <p className="font-aileron text-xs font-semibold">{weight} Kg</p>
      </div>

      <div className="flex items-center gap-x-1.5">
        <ListingsIcon />
        <p className="font-aileron text-xs font-normal text-grey-200">
          {reason}
        </p>
      </div>
    </div>
  );
};

const ActivityCard = ({ data, index }) => {
  const dispatch = useDispatch();

  return (
    <div
      className="p-4 rounded-2xl border border-light-grey"
      style={{ width: "619px" }}
    >
      <div className="flex items-center pb-2 border-b border-[#E7E7E7]">
        <div className="flex items-center gap-x-2">
          <div className="w-6 h-6 bg-[#E7E7E7] rounded-full flex items-center justify-center">
            <p className="font-poppins text-xs font-semibold">{index + 1}</p>
          </div>

          <p className="font-poppins text-sm font-medium">
            {activity[data?.activityType]}
          </p>
        </div>
      </div>

      <div className="flex items-center w-full mt-2">
        <div className="flex-1">
          <p className="font-poppins text-xs font-medium">Type of produce</p>
          <div className="flex items-center mt-2 gap-x-1.5">
            <ListingsIcon />
            <p className="font-aileron text-xs font-normal text-grey-200">
              {data?.produce}
            </p>
          </div>
        </div>

        <div className="flex-1">
          <p className="font-poppins text-xs font-medium">Total weight</p>

          <div className="mt-2 flex items-center justify-between">
            <div className="flex items-center gap-x-1.5">
              <ListingsIcon />

              <p className="font-aileron text-xs font-normal text-grey-200">
                {data.activityType === 1
                  ? data?.weightCollected
                  : data?.weightDelivered}{" "}
                Kg
              </p>

              {+data.isEdited === 1 && (
                <p className="-ml-1 font-aileron text-xs font-semibold text-yellow-dark">
                  (Edited)
                </p>
              )}
            </div>

            {data.activityType === 1 && (
              <button
                type="button"
                className="flex items-center gap-x-3"
                onClick={() =>
                  dispatch(
                    updateTrips({
                      key: "editCommodityModal",
                      value: {
                        visible: true,
                        data: [data],
                      },
                    }),
                  )
                }
              >
                <EditIcon />

                <p className="font-aileron text-xs font-semibold text-grey-200">
                  Edit Weight
                </p>
              </button>
            )}
          </div>
        </div>
      </div>

      {data?.spoiltMilk ? (
        <SpoiltMilk
          reason={data?.spoiltMilkReason || data?.spoiltMilk?.reason}
          weight={data?.spoiltMilk || data?.spoiltMilk?.weight}
        />
      ) : null}
    </div>
  );
};

const FarmerSummary = () => {
  const dispatch = useDispatch();

  const { currentStop, currentFarmer, farmerSummary } = useSelector(
    (state) => state.trips,
  );

  // const [farmerSummary, setFarmerSummary] = useState([]);

  // const getFarmerSummary = useCallback(async () => {
  //   try {
  //     dispatch(
  //       updateTrips({
  //         key: "loading",
  //         value: true,
  //       }),
  //     );

  //     const response = await client.get(
  //       `${process.env.REACT_APP_API_URL}/trip/farmer-summary`,
  //       {
  //         params: {
  //           tripId: currentStop.tripId,
  //           stopId: currentStop.stopId,
  //           farmerId: currentFarmer.id,
  //         },
  //       },
  //     );

  //     setFarmerSummary([...response.data.farmerSummary]);
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     dispatch(
  //       updateTrips({
  //         key: "loading",
  //         value: false,
  //       }),
  //     );
  //   }
  // }, [dispatch, currentFarmer, currentStop]);

  useEffect(() => {
    dispatch(
      getFarmerSummary({
        tripId: currentStop.tripId,
        stopId: currentStop.stopId,
        farmerId: currentFarmer.id,
      }),
    );
  }, [dispatch, currentStop, currentFarmer]);

  return (
    <div className="p-6 bg-background-white h-[calc(100vh-128px)] overflow-y-scroll">
      <p className="font-poppins text-xs font-medium mb-4">
        {farmerSummary.length} Activities
      </p>
      <div className="flex flex-col gap-y-4">
        {farmerSummary?.map((item, index) => (
          <ActivityCard index={index} data={item} />
        ))}
      </div>
    </div>
  );
};

export default FarmerSummary;
