import React from "react";
import { useNavigate } from "react-router-dom";
import { NOTIFICATION_TYPE } from "./constants";

const ItemBody10 = ({ notification }) => {
  const navigate = useNavigate();

  const getText = () => {
    if (notification?.type === NOTIFICATION_TYPE.NEW_PURCHASE_REQUEST) {
      return (
        <p className="font-poppins text-xs font-light">
          New <span className="text-green font-medium">Purchase request</span>{" "}
          from Viola Thomson
        </p>
      );
    }

    if (notification?.type === NOTIFICATION_TYPE.NEW_SESSION_REQUEST) {
      return (
        <p className="font-poppins text-xs font-light">
          New <span className="text-green font-medium">Session request</span>{" "}
          from Viola Thomson
        </p>
      );
    }

    if (notification?.type === NOTIFICATION_TYPE.NEW_TRANSPORT_REQUEST) {
      return (
        <p className="font-poppins text-xs font-light">
          New <span className="text-green font-medium">Transport request</span>{" "}
          from Viola Thomson
        </p>
      );
    }

    return "";
  };

  return (
    <div>
      {getText()}

      <button
        type="button"
        className="mt-1 w-full bg-primary hover:bg-primary-50 py-2 px-4 rounded-md"
        onClick={() => navigate("/requests")}
      >
        <p className="font-poppins text-xs font-medium text-background-white">
          View Details
        </p>
      </button>
    </div>
  );
};

export default ItemBody10;
