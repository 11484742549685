import { createSlice } from "@reduxjs/toolkit";
import {
  addKnownIssues,
  addAsset,
  createPowerAfricaAssetChecklist,
  deleteAsset,
  editAsset,
  getAssetById,
  getPowerAfricaKPI,
  resolveKnownIssue,
  updateKnownIssues,
  getAssets,
  getAllAssets,
  getPowerAfricaAssetChecklist,
  updatePowerAfricaAssetChecklist,
  getSessionsByAssetId,
} from "../APIs/powerAfricaAPI";

const initialState = {
  loading: false,
  error: {},
  status: "idle",
  tillerChecklist: [],
  checklists: {
    tiller: [],
    miller: [],
    waterPump: [],
    thresher: [],
    chaufCutter: [],
  },
  kpiData: {
    total: 0,
    inSession: 0,
    service: 0,
  },
  assetList: {
    result: [],
    totalRecords: 0,
  },
  allAssets: [],
  currentAsset: {},
  threeDotsPopoverId: 0,
  showToastMessage: {
    visible: false,
    data: {},
  },
  deletePowerAfricaAssetModal: false,
  viewAllKPIs: false,
  assetSessionList: {
    result: [],
    totalRecords: 0,
  },
};

export const powerAfricaSlice = createSlice({
  name: "powerAfrica",
  initialState,
  reducers: {
    updateAsset: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers(builder) {
    // add asset
    builder.addCase(addAsset.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addAsset.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(addAsset.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get assets
    builder.addCase(getAssets.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAssets.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.assetList = action.payload;
    });
    builder.addCase(getAssets.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get asset by id
    builder.addCase(getAssetById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAssetById.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.currentAsset = action.payload.asset;
    });
    builder.addCase(getAssetById.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get power africa KPIs
    builder.addCase(getPowerAfricaKPI.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPowerAfricaKPI.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.kpiData = action.payload.kpi;
    });
    builder.addCase(getPowerAfricaKPI.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // edit asset
    builder.addCase(editAsset.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editAsset.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(editAsset.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // delete asset
    builder.addCase(deleteAsset.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteAsset.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(deleteAsset.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get all assets
    builder.addCase(getAllAssets.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllAssets.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.allAssets = action.payload.result;
    });
    builder.addCase(getAllAssets.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get power africa checklist
    builder.addCase(getPowerAfricaAssetChecklist.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPowerAfricaAssetChecklist.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.checklists = action.payload.checklists;
    });
    builder.addCase(getPowerAfricaAssetChecklist.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // create power africa checklist
    builder.addCase(createPowerAfricaAssetChecklist.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createPowerAfricaAssetChecklist.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(
      createPowerAfricaAssetChecklist.rejected,
      (state, action) => {
        state.loading = false;
        state.status = "rejected";
        state.error = action.error;
      },
    );

    // update power africa checklist
    builder.addCase(updatePowerAfricaAssetChecklist.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePowerAfricaAssetChecklist.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(
      updatePowerAfricaAssetChecklist.rejected,
      (state, action) => {
        state.loading = false;
        state.status = "rejected";
        state.error = action.error;
      },
    );

    // add power africa known issues
    builder.addCase(addKnownIssues.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addKnownIssues.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(addKnownIssues.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // update power africa known issues
    builder.addCase(updateKnownIssues.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateKnownIssues.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(updateKnownIssues.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // resolve power africa known issues
    builder.addCase(resolveKnownIssue.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(resolveKnownIssue.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(resolveKnownIssue.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get sessions by asset id
    builder.addCase(getSessionsByAssetId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSessionsByAssetId.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.assetSessionList = action.payload;
    });
    builder.addCase(getSessionsByAssetId.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });
  },
});

export const { updateAsset } = powerAfricaSlice.actions;

export default powerAfricaSlice.reducer;
