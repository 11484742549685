/* eslint-disable new-cap */
import React from "react";
import * as ReactDOMServer from "react-dom/server";
import L from "leaflet";
import { POWER_AFRICA_ASSETS } from "../constants";
import TillerIcon from "../../../Common/Svgs/TillerIcon";
import {
  ChaufCutterIcon,
  MillerIcon,
  ThresherIcon,
  WaterPumpIcon,
} from "../../../Common/Svgs/PowerAfricaIcon";

const getMarkerIcon = (type) => {
  let icon = null;

  if (+type === POWER_AFRICA_ASSETS.TILLER) {
    icon = <TillerIcon size={24} className="text-white" />;
  }

  if (+type === POWER_AFRICA_ASSETS.MILLER) {
    icon = <MillerIcon size={24} className="text-white" />;
  }

  if (+type === POWER_AFRICA_ASSETS.WATER_PUMP) {
    icon = <WaterPumpIcon size={24} className="text-white" />;
  }

  if (+type === POWER_AFRICA_ASSETS.THRESHER) {
    icon = <ThresherIcon size={24} className="text-white" />;
  }

  if (+type === POWER_AFRICA_ASSETS.CHAUF_CUTTER) {
    icon = <ChaufCutterIcon size={24} className="text-white" />;
  }

  return new L.divIcon({
    html: ReactDOMServer.renderToString(
      <div className="w-12 h-12 flex items-center justify-center bg-[#60AF8C] rounded-full">
        {icon}
      </div>,
    ),
  });
};

export default getMarkerIcon;
