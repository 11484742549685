const checkSameCoordinates = (boundary) => {
  const variance = 0.001;

  const firstPoint = boundary[0];
  const latestPoint = boundary[boundary.length - 1];

  // return false if added co-ordinates are less than or equal to 3
  if (boundary.length <= 3) return false;

  const [firstPointLat, firstPointLng] = firstPoint;
  const [latestPointLat, latestPointLng] = latestPoint;

  const differenceLat = Math.abs(latestPointLat - firstPointLat);
  const differenceLng = Math.abs(latestPointLng - firstPointLng);

  // if difference is less than variance than it is a same co-ordinate
  if (differenceLat <= variance && differenceLng <= variance) {
    return true;
  }

  // not a same co-ordinate
  return false;
};

export default checkSameCoordinates;
