import React from "react";

const LocationPoint = ({ type = 1, area, point }) => {
  return (
    <div className="mt-3 py-3 px-6 border border-light-grey rounded-md">
      <div
        className="max-w-max py-1 px-2 rounded-md"
        style={{
          backgroundColor: type === 1 ? "#eab726" : "#085054",
        }}
      >
        <p className="font-aileron font-semibold text-10 leading-3 text-white">
          {type === 1 ? "Pick-up Area" : "Drop-off Area"}
        </p>
      </div>

      <p className="mt-3 font-poppins font-medium text-base text-black capitalize">
        {area}
      </p>

      <div className="mt-1.5 flex items-start gap-x-[6px]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="17"
          fill="none"
          viewBox="0 0 16 17"
        >
          <path stroke="#DFDFDF" d="M1 0v7a4 4 0 004 4h11" />
        </svg>

        <p className="font-aileron font-normal text-xs leading-[14.5px] text-dark-gray">
          {type === 1 ? "Pick-Up Point: " : "Drop-Off Point: "}
          <span className="text-black font-semibold capitalize">{point}</span>
        </p>
      </div>
    </div>
  );
};

export default LocationPoint;
