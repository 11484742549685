import React from "react";
import dayjs from "dayjs";
import ClockIcon from "../../Common/Svgs/ClockIcon";
import OperationsIcon from "../../Common/Svgs/OperationsIcon";
import CheckCircleIcon from "../../Common/Svgs/CheckCircleIcon";
import getSessionStatus from "./getSessionStatus";
import CustomTooltip from "../../Core/Components/CustomTooltip";
import { SESSION_STATUS, SESSION_TYPE } from "./constants";
import ListDropdown from "./ListDropdown";
import getSessionType from "./getSessionType";
import getFormatedFloat from "../../Utils/getFormatedFloat";

export const sessionColumns = [
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray whitespace-nowrap">
        Session ID
      </div>
    ),
    dataIndex: "id",
    width: "100px",
    sorter: (a, b) => a.id - b.id,
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-black">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray whitespace-nowrap">
        Session Type
      </div>
    ),
    dataIndex: "activityType",
    render: (text) => <div>{getSessionType(+text)}</div>,
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray whitespace-nowrap">
        Agent
      </div>
    ),
    dataIndex: "agentFirstName",
    sorter: (a, b) => a.agentFirstName.localeCompare(b.agentFirstName),
    render: (text, record) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {text ? `${text} ${record.agentLastName}` : "--"}
      </div>
    ),
  },

  // {
  //   title: () => (
  //     <div className="font-normal font-aileron text-10 text-dark-gray whitespace-nowrap">
  //       Asset Type
  //     </div>
  //   ),
  //   dataIndex: "assetType",
  //   sorter: (a, b) => a.assetType - b.assetType,
  //   render: (text) => {
  //     const sessionTypeText = getAssetTypeName(+text);

  //     return (
  //       <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray whitespace-nowrap capitalize">
  //         {sessionTypeText}
  //       </div>
  //     );
  //   },
  // },

  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray whitespace-nowrap">
        Asset ID
      </div>
    ),
    sorter: (a, b) =>
      a?.assetRegistrationNo?.localeCompare(b?.assetRegistrationNo),
    dataIndex: "assetRegistrationNo",
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text || "--"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Farmer & Location
      </div>
    ),
    dataIndex: "farmerFirstName",
    sorter: (a, b) => a?.farmerFirstName?.localeCompare(b?.farmerFirstName),
    // width: "200px",
    render: (text, record) => {
      const farmerName = text ? `${text} ${record.farmerLastName}` : "--";

      return (
        <button type="button" onClick={(e) => e.stopPropagation()}>
          <CustomTooltip
            tooltipTitle={
              <div>
                <p className="text-xs font-medium font-aileron text-black">
                  {farmerName}
                </p>
                <p className="mt-1 text-10 leading-3 font-normal font-aileron text-dark-gray">
                  {record.farmLat}, {record.farmLong}
                </p>
              </div>
            }
            bgColor="#fff"
            textColor="text-dark-gray"
            placement="bottomLeft"
            padding="8px"
            trigger="click"
          >
            <div className="flex items-center cursor-pointer gap-x-1">
              <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray text-left capitalize whitespace-nowrap">
                {farmerName.length > 15
                  ? `${farmerName.substring(0, 15)}...`
                  : farmerName}
              </div>
              <img src="/assets/icons/chevron-down.svg" alt="" />
            </div>
          </CustomTooltip>
        </button>
      );
    },
  },

  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray whitespace-nowrap">
        Quantity
      </div>
    ),
    sorter: (a, b) => a.estimatedArea - b.estimatedArea,
    dataIndex: "estimatedArea",
    render: (text, record) => {
      if (record.status === SESSION_STATUS.COMPLETED) {
        return (
          <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
            {getFormatedFloat(record.actualArea)} {record.unit}
          </div>
        );
      }

      return (
        <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
          {record.estimatedArea
            ? `${getFormatedFloat(record.estimatedArea)} ${record.unit} (E)`
            : "--"}
        </div>
      );
    },
  },

  // {
  //   title: () => (
  //     <div className="font-normal font-aileron text-10 text-dark-gray">
  //       Area
  //     </div>
  //   ),
  //   dataIndex: "area",
  //   // width: "150px",
  //   sorter: (a, b) => a.area - b.area,
  //   render: (text, record) => (
  //     <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
  //       {record.status === SESSION_STATUS.COMPLETED
  //         ? `${record.actualArea} Sq.M`
  //         : `${record.estimatedArea} Sq.M (E)`}
  //     </div>
  //   ),
  // },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Total Session Cost
      </div>
    ),
    dataIndex: "totalCost",
    sorter: (a, b) => a.totalCost - b.totalCost,
    // width: "140px",
    render: (text, record) => {
      if (record.status === SESSION_STATUS.COMPLETED) {
        return (
          <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
            {getFormatedFloat(record.actualTotalCost)} Ksh
          </div>
        );
      }

      return (
        <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
          {record.estimatedTotalCost
            ? `${getFormatedFloat(record.estimatedTotalCost)} Ksh (E)`
            : "--"}
        </div>
      );
    },
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Date & Time Slot
      </div>
    ),
    dataIndex: "activityDate",
    sorter: (a, b) => a?.activityDate?.localeCompare(b?.activityDate),
    // width: "140px",
    render: (text, record) => (
      <div>
        <p className="text-xs font-semibold font-aileron leading-[14.5px] text-dark-gray whitespace-nowrap capitalize">
          {dayjs(text).format("ddd, MMM DD YYYY")}
        </p>

        <p className="text-10 font-normal font-aileron leading-3 text-dark-gray uppercase">
          {record.status === SESSION_STATUS.COMPLETED
            ? `${dayjs(record.startTime).format("hh:mm a")} - ${dayjs(
                record.endTime,
              ).format("hh:mm a")}`
            : record.timeSlot}
        </p>
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Status
      </div>
    ),
    dataIndex: "status",
    // width: "170px",
    render: (text) => <div>{getSessionStatus(text)}</div>,
  },
  {
    dataIndex: "id",
    width: "80px",
    render: (text, record) => (
      <button
        type="button"
        onClick={(event) => {
          event.stopPropagation();
        }}
        className="w-full flex items-center justify-center invisible group-hover:visible"
      >
        <ListDropdown detail={record} />
      </button>
    ),
  },
];

export const KPIData = (kpiData) => {
  return [
    {
      id: 1,
      title: "Total upcoming sessions",
      count: kpiData?.upcoming || 0,
      image: <OperationsIcon size={16} className="text-kpi-icon" />,
      onClick: () => {},
    },
    {
      id: 2,
      title: "Total ongoing sessions",
      count: kpiData?.ongoing || 0,
      image: <ClockIcon className="text-kpi-icon" />,
      onClick: () => {},
    },
    {
      id: 3,
      title: "Total completed sessions",
      count: kpiData?.completed || 0,
      total: kpiData?.total || 0,
      image: <CheckCircleIcon className="text-kpi-icon" />,
      onClick: () => {},
    },
  ];
};

export const statusFilters = [
  {
    id: SESSION_STATUS.ONGOING,
    value: getSessionStatus(SESSION_STATUS.ONGOING),
  },
  {
    id: SESSION_STATUS.UPCOMING,
    value: getSessionStatus(SESSION_STATUS.UPCOMING),
  },
  {
    id: SESSION_STATUS.COMPLETED,
    value: getSessionStatus(SESSION_STATUS.COMPLETED),
  },
];

export const sessionTypeFilters = [
  {
    id: SESSION_TYPE.TILLING,
    value: getSessionType(SESSION_TYPE.TILLING),
  },
  {
    id: SESSION_TYPE.MILLING,
    value: getSessionType(SESSION_TYPE.MILLING),
  },
  {
    id: SESSION_TYPE.THRESHING,
    value: getSessionType(SESSION_TYPE.THRESHING),
  },
  {
    id: SESSION_TYPE.WATER_PUMPING,
    value: getSessionType(SESSION_TYPE.WATER_PUMPING),
  },
  {
    id: SESSION_TYPE.CHAUF_CUTTING,
    value: getSessionType(SESSION_TYPE.CHAUF_CUTTING),
  },
];
