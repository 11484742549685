import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer } from "antd";
import dayjs from "dayjs";
import getRequestDetailColors from "../getRequestDetailColors";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import { REQUEST_STATUS } from "../constants";
import Detailistings from "../DetailListings";
import TrikeIcon from "../../../Common/Svgs/TrikeIcon";
import BatteryIcon from "../../../Common/Svgs/BatteryIcon";
import TickIcon from "../../../Common/Svgs/TickIcon";
import LocationPoint from "./LocationPoint";
import Footer from "./Footer";
import { updateRequest } from "../../../Redux/Slices/requests.slice";
import SelectTrike from "./SelectTrike";
import SelectBatteries from "./SelectBatteries";

const RenderBody = () => {
  const dispatch = useDispatch();

  const { currentRequest, transportDetailStep } = useSelector(
    (state) => state.requests,
  );

  const getBody = () => {
    if (+currentRequest.status === REQUEST_STATUS.REJECTED) {
      return (
        <div className="pt-6 pb-12">
          <div className="py-4 px-6 bg-alert-10 rounded-xl">
            <div className="max-w-max bg-background-white py-1 px-2 border border-alert-red rounded-md">
              <p className="font-poppins font-medium text-xs leading-[18px] text-alert-red">
                Rejected
              </p>
            </div>

            <div className="mt-4 flex">
              <Detailistings
                title="Rejected By"
                value={`${currentRequest?.updatedByFirstName} ${currentRequest?.updatedByLastName}`}
              />
              <Detailistings
                title="Date & Time"
                value={dayjs(currentRequest?.updatedAt).format(
                  "MMM DD, YYYY, hh:mm A",
                )}
              />
            </div>

            <p className="mt-4 font-poppins font-normal text-xs leading-[18px] text-dark-gray">
              Reason
            </p>

            <div className="mt-1 p-2 bg-background-white rounded-md font-poppins font-medium text-sm text-black first-letter:capitalize">
              {currentRequest?.metaData?.reasonText}
            </div>
          </div>

          <div className="w-full h-6" />
        </div>
      );
    }

    if (+currentRequest.status === REQUEST_STATUS.ASSIGN_TRIKE) {
      return (
        <div className="pt-6 pb-12">
          <div className="py-4 px-6 bg-green-10 rounded-xl">
            <div className="max-w-max bg-background-white py-1 px-2 border border-green rounded-md">
              <p className="font-poppins font-medium text-xs leading-[18px] text-green">
                Accepted
              </p>
            </div>

            <div className="mt-4 pb-3 flex border-b border-[#AABDBD]">
              <Detailistings
                title="Accepted By"
                value={`${currentRequest?.updatedByFirstName} ${currentRequest?.updatedByLastName}`}
              />

              <Detailistings
                title="Date & Time"
                value={dayjs(currentRequest?.updatedAt).format(
                  "MMM DD, YYYY, hh:mm A",
                )}
              />
            </div>

            <div className="mt-3 pb-1">
              <p className="font-poppins font-medium text-sm text-black">
                Assign a trike and batteries to accept this request
              </p>

              <button
                type="button"
                className="mt-3 px-4 py-[11px] bg-primary hover:bg-primary-50 rounded-md"
                onClick={() =>
                  dispatch(
                    updateRequest({
                      key: "transportDetailStep",
                      value: 2,
                    }),
                  )
                }
              >
                <div className="flex item-center gap-x-2">
                  <img src="/assets/icons/add.svg" alt="" />
                  <p className="font-poppins text-xs leading-4.5 font-medium text-background-white">
                    Assign Trike & Batteries
                  </p>
                </div>
              </button>
            </div>
          </div>

          <div className="w-full h-6" />
        </div>
      );
    }

    if (+currentRequest.status === REQUEST_STATUS.TRIKE_ASSIGNING_COMPLETED) {
      return (
        <div className="pt-6 pb-12">
          <div className="py-4 px-6 bg-green-10 rounded-xl">
            <div className="max-w-max bg-background-white py-1 px-2 border border-green rounded-md">
              <p className="font-poppins font-medium text-xs leading-[18px] text-green">
                Accepted
              </p>
            </div>

            <p className="mt-3 font-poppins font-medium text-sm text-black">
              Trike & Batteries are assigned for this request
            </p>

            <div className="mt-3 p-4 bg-white rounded-md flex items-center">
              <div className="w-10 h-10 flex items-center justify-center rounded-full bg-green-10">
                <TrikeIcon size={16} className="text-green" />
              </div>

              <p className="ml-2 flex-1 font-poppins font-medium text-base text-black">
                {currentRequest?.metaData?.trikeId}
              </p>

              <div className="bg-green-10 flex items-center py-1 px-2 rounded-md">
                <TickIcon size={16} className="text-green" />
                <p className="font-poppins font-medium text-xs leading-[18px] text-green">
                  Assigned
                </p>
              </div>
            </div>

            <div className="mt-3 p-4 bg-white rounded-md flex items-center">
              <div className="w-10 h-10 flex items-center justify-center rounded-full bg-yellow-10">
                <BatteryIcon
                  size={16}
                  className="stroke-yellow-dark fill-none"
                />
              </div>

              <p className="ml-2 flex-1 font-poppins font-medium text-base text-black">
                {currentRequest?.metaData?.batteries} Batteries
              </p>

              <div className="bg-green-10 flex items-center py-1 px-2 rounded-md">
                <TickIcon size={16} className="text-green" />
                <p className="font-poppins font-medium text-xs leading-[18px] text-green">
                  Assigned
                </p>
              </div>
            </div>
          </div>

          <div className="w-full h-6" />
        </div>
      );
    }

    return (
      <div className="mt-3 pt-3 border-t border-light-grey">
        <p className="font-poppins font-medium text-sm text-black">
          Accept this request and assign trike and batteries.
        </p>
      </div>
    );
  };

  const { borderColor, bgColor } = getRequestDetailColors(currentRequest.type);

  if (
    currentRequest.status === REQUEST_STATUS.ASSIGN_TRIKE &&
    transportDetailStep === 2
  ) {
    return <SelectTrike />;
  }

  if (
    currentRequest.status === REQUEST_STATUS.ASSIGN_TRIKE &&
    transportDetailStep === 3
  ) {
    return <SelectBatteries />;
  }

  return (
    <div className="w-full h-full bg-background-white overflow-y-scroll">
      <div className="px-6 pb-6">
        <div
          className="rounded-xl border py-4 px-6"
          style={{
            background: bgColor,
            borderColor,
          }}
        >
          <div
            className="py-1 px-2 h-[26px] max-w-max rounded-md"
            style={{
              background: borderColor,
            }}
          >
            <p className="font-poppins font-medium text-xs leading-[18px] text-background-white">
              Transport
            </p>
          </div>

          <div className="mt-4 flex items-start gap-x-4">
            <Detailistings
              title="Contact No."
              value={currentRequest?.metaData?.mobileNumber}
            />

            <Detailistings
              title="Pickup Time"
              value={currentRequest?.metaData?.timeSlot}
            />
          </div>

          <div className="mt-4 flex items-start gap-x-4">
            <Detailistings
              title="Type of Cargo"
              value={currentRequest?.metaData?.item}
            />
            <Detailistings title="Estimated Price" value="430 KES" />
          </div>
        </div>

        <LocationPoint
          type={1}
          area={currentRequest?.metaData?.pickupArea}
          point={currentRequest?.metaData?.pickupPoint}
        />

        <LocationPoint
          type={2}
          area={currentRequest?.metaData?.dropoffArea}
          point={currentRequest?.metaData?.dropoffPoint}
        />

        {getBody()}
      </div>
    </div>
  );
};

const TransportDetailsDrawer = ({
  visible,
  onCancel,
  currentPage,
  setCurrentPage,
}) => {
  return (
    <Drawer
      width={560}
      placement="right"
      closable={false}
      maskStyle={{ background: "transparent" }}
      open={visible}
      onClose={onCancel}
      className="rounded-tl-xl"
    >
      <div className="flex flex-col h-full">
        <div className="w-full p-6 bg-background-white flex items-center justify-between rounded-tl-xl">
          <p className="font-poppins font-medium text-base text-black">
            Request Details
          </p>

          <CloseIcon
            className="w-5 h-5 cursor-pointer stroke-dark-grey-text"
            onClick={onCancel}
          />
        </div>

        <RenderBody onCancel={onCancel} />

        <Footer
          onCancel={onCancel}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </Drawer>
  );
};

export default TransportDetailsDrawer;
