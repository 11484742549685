import { Popover } from "antd";
import React, { useState } from "react";
import TickIcon from "../../../Common/Svgs/TickIcon";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";

const options = [
  {
    id: 1,
    value: "All Time",
  },
  {
    id: 2,
    value: "Last Week",
  },
  {
    id: 3,
    value: "Last Month",
  },
  {
    id: 4,
    value: "Last 3 Months",
  },
  {
    id: 5,
    value: "Last 6 Months",
  },
  {
    id: 6,
    value: "Last Year",
  },
  {
    id: 7,
    value: "Custom",
  },
];

const KpiTimeFilter = ({ currentFilter, setCurrentFilter }) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      open={open}
      arrow={false}
      onOpenChange={(state) => setOpen(state)}
      content={
        <div className="w-[180px] bg-background-white rounded-md py-1">
          {options.map((option) => {
            const selected = currentFilter.id === option.id;

            return (
              <button
                type="button"
                key={option.id}
                className="w-full flex items-center justify-between py-2 px-3 capitalize group hover:bg-background max-h-20"
                onClick={() => {
                  setCurrentFilter(option);
                  setOpen(false);
                }}
              >
                <p
                  className={`font-aileron text-xs font-semibold text-left
                        ${
                          selected
                            ? "text-black"
                            : "text-dark-gray group-hover:text-black"
                        }
                       `}
                >
                  {option.value}
                </p>

                {selected ? (
                  <div className="flex items-center gap-x-2">
                    <TickIcon
                      size={16}
                      strokeWidth={2}
                      className="text-primary"
                    />
                  </div>
                ) : null}
              </button>
            );
          })}
        </div>
      }
    >
      <div
        role="button"
        className="px-3.5 py-2 w-[180px] flex items-center justify-end gap-x-2"
      >
        <p className="font-poppins font-medium text-xs capitalize text-green">
          {currentFilter?.value}
        </p>

        <ChevronIcon
          strokeWidth="1"
          className={`w-4 h-4 stroke-green transform transition-all duration-200 ${
            open ? "-rotate-90" : "rotate-90"
          }`}
        />
      </div>
    </Popover>
  );
};

export default KpiTimeFilter;
