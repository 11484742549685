/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import WeightCollectedIcon from "../../../Common/Svgs/WeightCollectedIcon";
import WeightDeliveredIcon from "../../../Common/Svgs/WeightDeliveredIcon";
import SpoiltMilkIcon from "../../../Common/Svgs/SpoiltMilkIcon";
import { updateTrips } from "../../../Redux/Slices/trips.slice";
import { client } from "../../../Utils/axiosClient";
import FarmerBadge from "./FarmerBadge";

const Listings = ({ icon, title, value }) => {
  return (
    <div className="flex items-center justify-between my-2">
      <div className="flex items-center gap-x-2">
        {icon}
        <p className="font-aileron text-sm font-normal text-grey-200">
          {title}
        </p>
      </div>
      <p className="font-aileron text-sm font-semibold">{value} Kg</p>
    </div>
  );
};

const FarmerCard = ({
  index,
  isOrigin = 1,
  isDestination = 1,
  farmerId,
  farmerName,
  farmerStop = 0,
  isNewFarmer = 0,
  farmerStopName,
  activities,
}) => {
  const { id, stopId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getBadge = () => {
    if (isNewFarmer) {
      return (
        <div className="mt-4">
          <FarmerBadge type={1} text="New Entry" />
        </div>
      );
    }
    if (!isNewFarmer && farmerStop) {
      return (
        <div className="mt-4">
          <FarmerBadge
            type={2}
            text={`Farmer originally from stop ${farmerStopName}`}
          />
        </div>
      );
    }
    return <></>;
  };

  return (
    <div
      className="p-4 rounded-2xl border border-light-grey"
      style={{ width: "49.3%" }}
      role={!isOrigin && !isDestination && "button"}
      onClick={() => {
        if (!isOrigin && !isDestination) {
          dispatch(
            updateTrips({
              key: "currentFarmer",
              value: {
                id: farmerId,
                name: farmerName,
                index,
                farmerStop,
                farmerStopName,
                isNewFarmer,
              },
            }),
          );

          navigate(`/trips/${id}/${stopId}/${farmerId}`);
        }
      }}
    >
      {!isOrigin && !isDestination && (
        <div className="pb-4 border-b border-[#E7E7E7]">
          <div className="flex items-center justify-between ">
            <div className="flex items-center gap-x-2">
              <div className="w-6 h-6 bg-[#E7E7E7] rounded-full flex items-center justify-center">
                <p className="font-poppins text-xs font-semibold">
                  {index + 1}
                </p>
              </div>

              <p className="flex-1 font-poppins text-base font-medium capitalize">
                {farmerName}
              </p>
            </div>

            <ChevronIcon className="w-5 h-5 stroke-grey-200" />
          </div>
          {getBadge()}
        </div>
      )}

      {activities.map((item, mapIndex) => (
        <div
          key={item.activityId}
          className={`mt-4 ${
            mapIndex !== 0 && "pt-4 border-t border-[#E7E7E7]"
          }`}
        >
          <p className="font-poppins text-sm font-medium capitalize">
            {item.produce}
          </p>
          {item.weightCollected && !item.isDestination ? (
            <Listings
              icon={<WeightCollectedIcon />}
              title="Total collected"
              value={item.weightCollected}
            />
          ) : null}
          {item.weightDelivered && !item.isOrigin ? (
            <Listings
              icon={<WeightDeliveredIcon />}
              title="Total delivered"
              value={item.weightDelivered}
            />
          ) : null}
          {item.spoiltMilk ? (
            <Listings
              icon={<SpoiltMilkIcon />}
              title="Spoilt milk"
              value={item.spoiltMilk}
            />
          ) : null}
        </div>
      ))}
    </div>
  );
};

const StopSummary = () => {
  const dispatch = useDispatch();
  const { currentStop } = useSelector((state) => state.trips);

  const [stopSummary, setStopSummary] = useState([]);

  const getStopSummary = useCallback(async () => {
    try {
      dispatch(
        updateTrips({
          key: "loading",
          value: true,
        }),
      );

      const response = await client.get(
        `${process.env.REACT_APP_API_URL}/trip/get-farmer-activity`,
        {
          params: {
            stopId: currentStop.stopId,
            tripId: currentStop.tripId,
          },
        },
      );

      setStopSummary([...response.data.farmerActivity]);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(
        updateTrips({
          key: "loading",
          value: false,
        }),
      );
    }
  }, [dispatch, currentStop]);

  const getFilteredActivities = (activities) => {
    if (currentStop.isOrigin) {
      return activities.filter((item) => item.isOrigin);
    }

    if (currentStop.isDestination) {
      return activities.filter((item) => item.isDestination);
    }

    return activities;
  };

  useEffect(() => {
    getStopSummary();
  }, [getStopSummary]);

  console.log(stopSummary, "check stop summary");

  return (
    <div className="p-6 bg-background-white h-[calc(100vh-128px)] overflow-y-scroll">
      {stopSummary.length ? (
        !stopSummary[0].isOrigin &&
        !stopSummary[0].isDestination && (
          <p className="font-poppins text-xs font-medium mb-4">
            {stopSummary.length === 1
              ? "1 Farmer"
              : `${stopSummary.length} Farmers`}
          </p>
        )
      ) : (
        <p className="font-poppins text-xs font-medium mb-4">
          No activity exists for this stop
        </p>
      )}
      <div className="flex flex-wrap gap-4 w-full">
        {stopSummary?.map((item, index) => (
          <FarmerCard
            key={index}
            index={index}
            isOrigin={item.isOrigin}
            isDestination={item.isDestination}
            farmerId={item.farmerId}
            farmerName={item.farmerName}
            farmerStop={item.farmerStop}
            farmerStopName={item.farmerStopName}
            isNewFarmer={item.isNewFarmer}
            activities={getFilteredActivities(item.activities)}
          />
        ))}
      </div>
    </div>
  );
};

export default StopSummary;
