import React from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "antd";
import getPowerAfricaAssetType from "./getPowerAfricaAssetType";
import AssetsIcon from "../../../Common/Svgs/AssetsIcon";
import HubStationIcon from "../../../Common/Svgs/HubStationIcon";
import AssetTagIcon from "../../../Common/Svgs/AssetTagIcon";
import AssetBatteries from "./AssociatedBatteries/AssetBatteries";
import ClockIcon from "../../../Common/Svgs/ClockIcon";

const KPIContainer = ({ icon, title, value }) => {
  return (
    <div className="flex items-center pt-2 pb-2.5 px-3 border-[0.5px] border-light-grey rounded-md">
      <div className="w-[38px] h-[38px] flex items-center justify-center bg-yellow-dark rounded-full">
        {icon}
      </div>

      <p className="ml-2.5 flex-1 font-aileron font-normal text-xs leading-[14.5px] text-dark-gray">
        {title}
      </p>

      <p className="font-poppins font-medium text-base text-black capitalize">
        {value || "--"}
      </p>
    </div>
  );
};

const KPIandBatteries = () => {
  const { currentAsset } = useSelector((state) => state.powerAfrica);

  const { assetName, assetIcon } = getPowerAfricaAssetType(currentAsset?.type);

  return (
    <div className="w-full p-6 border-b border-light-grey">
      <Row gutter={24}>
        <Col span={12} className="w-full">
          <div className="w-full flex flex-col gap-y-2">
            <KPIContainer
              title="Asset Type"
              value={assetName}
              icon={assetIcon}
            />

            <KPIContainer
              title="Model Number"
              value={currentAsset?.modelNo}
              icon={<AssetsIcon size={16} className="text-green" />}
            />

            <KPIContainer
              title="Asset Tag"
              value={currentAsset?.assetTag}
              icon={<AssetTagIcon size={16} className="text-green" />}
            />

            <KPIContainer
              title="Hub Station"
              value={currentAsset?.hubName}
              icon={
                <HubStationIcon width={16} height={16} className="text-green" />
              }
            />

            <KPIContainer
              title="Usage"
              value="--"
              icon={<ClockIcon size={16} className="text-green" />}
            />
          </div>
        </Col>

        <Col span={12} className="w-full">
          <AssetBatteries />
        </Col>
      </Row>
    </div>
  );
};

export default KPIandBatteries;
