import React from "react";

const AssetTagIcon = ({ size, className = "" }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="m2.78 10.2 3.02 3.02a3.187 3.187 0 0 0 4.5 0l2.927-2.926a3.187 3.187 0 0 0 0-4.5L10.2 2.78a3.17 3.17 0 0 0-2.4-.927l-3.333.16a2.576 2.576 0 0 0-2.46 2.447l-.16 3.333c-.04.9.3 1.773.933 2.407"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="1.5"
          d="M6.333 8a1.667 1.667 0 1 0 0-3.333 1.667 1.667 0 0 0 0 3.333Z"
        />
      </svg>
    </span>
  );
};

export default AssetTagIcon;
