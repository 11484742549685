import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import VerifyIcon from "../../../Common/Svgs/VerifyIcon";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import ZoomButton, {
  RelocateButton,
} from "../../../Core/Components/MapMarkerButtons";

const MapBottomCard = ({ mapRef, initialZoomLevel, center }) => {
  const { currentAsset } = useSelector((state) => state.powerAfrica);

  return (
    <div className="absolute left-0 right-0 z-20 w-full bottom-6">
      <Row gutter={16}>
        <Col span={21}>
          <div className="p-3 ml-6 bg-white rounded-md h-27">
            <div className="flex justify-between">
              <div className="flex items-center gap-x-2">
                <div className="text-base font-medium capitalize font-poppins text-new-black">
                  {currentAsset.agentFirstName
                    ? `${currentAsset.agentFirstName} ${currentAsset.agentLastName}`
                    : "--"}
                </div>
                <VerifyIcon />
              </div>

              {currentAsset?.currentAgent ? (
                <button
                  type="button"
                  className="flex items-center px-2 py-1 gap-x-2"
                  onClick={() => null}
                >
                  <p className="text-xs font-medium font-poppins text-primary">
                    Sessions
                  </p>
                  <ChevronIcon className="w-4 h-4 stroke-primary" />
                </button>
              ) : null}
            </div>

            <div className="text-10 font-light font-poppins leading-3.75 text-dark-grey-text">
              Driver
            </div>
          </div>
        </Col>
        <Col span={3}>
          <div className="flex flex-col gap-y-3">
            <RelocateButton
              mapRef={mapRef}
              initialZoomlevel={initialZoomLevel}
              center={center}
            />
            <ZoomButton mapRef={mapRef} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MapBottomCard;
