/* eslint-disable eqeqeq */

const getStep1EstimatedDetails = (watch) => {
  const localActivityId = watch("activityId");

  let type = "unitss";
  let unit = "unitss";
  let perUnit = "per unitss";

  if (!localActivityId) return { type, unit, perUnit };

  if (localActivityId == 1) {
    type = "Area";
    unit = "Sq.M";
    perUnit = "per Sq.M Area";
  } else if (localActivityId == 3) {
    type = "Litres";
    unit = "L";
    perUnit = "per litre";
  } else {
    type = "Weight";
    unit = "Kg";
    perUnit = "per Kg";
  }

  return { type, unit, perUnit };
};

export default getStep1EstimatedDetails;
