import React from "react";
import { POWER_AFRICA_ASSETS } from "../constants";
import TillerIcon from "../../../Common/Svgs/TillerIcon";
import {
  ChaufCutterIcon,
  MillerIcon,
  ThresherIcon,
  WaterPumpIcon,
} from "../../../Common/Svgs/PowerAfricaIcon";

const getPowerAfricaAssetType = (type) => {
  let assetName = "";
  let assetIcon = null;

  if (+type === POWER_AFRICA_ASSETS.TILLER) {
    assetName = "Tiller";
    assetIcon = <TillerIcon size={16} className="text-green" />;
  }

  if (+type === POWER_AFRICA_ASSETS.MILLER) {
    assetName = "Miller";
    assetIcon = <MillerIcon size={16} className="text-green" />;
  }

  if (+type === POWER_AFRICA_ASSETS.WATER_PUMP) {
    assetName = "Water Pump";
    assetIcon = <WaterPumpIcon size={16} className="text-green" />;
  }

  if (+type === POWER_AFRICA_ASSETS.THRESHER) {
    assetName = "Thresher";
    assetIcon = <ThresherIcon size={16} className="text-green" />;
  }

  if (+type === POWER_AFRICA_ASSETS.CHAUF_CUTTER) {
    assetName = "Chauf Cutter";
    assetIcon = <ChaufCutterIcon size={16} className="text-green" />;
  }

  return { assetName, assetIcon };
};

export default getPowerAfricaAssetType;
