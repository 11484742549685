import usePermission from "../../Core/hooks/usePermission";
import { PERMISSIONS } from "../Login/constants";

const useHeaderItems = () => {
  const data = [
    {
      id: 1,
      padding: "filter",
      width: "settingsDriversTab",
      text: "Trikes",
      hasPermission: usePermission([
        PERMISSIONS.TRIKE_VIEW,
        PERMISSIONS.TRIKE_VIEW_AND_EDIT,
      ]),
    },
    {
      id: 2,
      padding: "filter",
      width: "settingsDriversTab",
      text: "Agri-Tech",
      hasPermission: usePermission([
        PERMISSIONS.POWER_AFRICA_ASSET_VIEW,
        PERMISSIONS.POWER_AFRICA_ASSET_VIEW_AND_EDIT,
      ]),
    },
  ];

  return data;
};

export default useHeaderItems;
