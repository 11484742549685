/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef, useState } from "react";
import { cva } from "class-variance-authority";
import { Input } from "antd";
import { Controller } from "react-hook-form";
import AlertIcon from "../../Common/Svgs/AlertIcon";

const input = cva(
  [
    "font-aileron rounded-md placeholder:font-aileron placeholder:text-dark-gray",
  ],
  {
    variants: {
      intent: {
        primary:
          "px-3.5 py-2.25 w-full bg-white hover:bg-white focus:bg-white border border-light-grey",
        login: "px-3.5 py-2.25 w-[312px] bg-white h-9",
        filter:
          "px-3.5 py-2.25 w-[312px] h-10 bg-background-grey hover:bg-background-grey",
        dropdown: "px-3 py-2 w-[192px] h-8.5 bg-background-grey",
        search:
          "px-3 py-2 w-[288px] h-10 bg-background-white-hover hover:bg-background-white-hover",
        searchHome:
          "px-3 py-2 w-full h-10 bg-background-white-hover hover:bg-background-white-hover active:bg-background-white-hover",
        generalSettings:
          "px-3.5 py-2.25 w-full border border-light-grey bg-background-white",
        generalSettingsDisable:
          "px-3.5 py-2.25 w-full border border-light-grey bg-background hover:bg-background text-black disabled:bg-background disabled:hover:bg-background disabled:text-black",
        createUser:
          "px-3.5 py-2.25 w-[367px] bg-white border border-light-grey hover:bg-white",
        onboarding:
          "px-3.5 py-4 w-[512px] max-h-[46px] border border-[#528587] bg-green-20 hover:bg-green-20",
        searchFilterItem:
          "px-3 py-2 w-full h-10 bg-background hover:bg-background focus:bg-background",
        sessionReject:
          "px-3 py-2 w-full h-10 bg-white hover:bg-white focus:bg-white",
        editCommodity:
          "px-3 py-2 w-[108px] h-8 bg-white hover:bg-white focus:bg-white",
      },

      text: {
        primary: "text-xs font-medium leading-4.5 text-dark-gray",
        generalSettings: "text-xs font-semibold text-black",
      },

      border: {
        primary: "primary-border",
        success: "success-border",
        error: "error-border",
        none: "border-none",
      },
      placeholderText: {
        primary: "placeholder:font-light",
        filters: "placeholder:font-medium",
        filterSelected: "placeholder:text-new-black",
      },
    },
    defaultVariants: {
      intent: "primary",
      placeholderText: "primary",
      text: "primary",
    },
  },
);

const CustomInput = ({
  intent,
  border,
  text,
  placeholderText,
  placeholder,
  bordered = false,
  ...props
}) => {
  const { disabled } = props;

  return (
    <Input
      bordered={bordered}
      {...props}
      className={input({
        intent,
        border,
        text,
        placeholderText,
      })}
      style={{
        backgroundColor: disabled && "#f8f6f4",
      }}
      placeholder={placeholder}
    />
  );
};

export const CustomInputWithController = ({ ...InputProps }) => {
  const {
    name,
    placeholder,
    control,
    formState,
    rules,
    inputType,
    intent,
    suffix,
    isFieldDisabled,
    focus,
    setFocus,
    error,
  } = InputProps;

  const { errors } = formState;
  const hasError = error || errors?.[name]?.message || null;

  const renderBorderColor = () => {
    if (hasError) {
      return "error";
    } else {
      if (focus) {
        return "success";
      } else {
        return "primary";
      }
    }
  };

  return (
    <div>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <CustomInput
            {...field}
            intent={intent}
            // border={dirtyFields[name] ? renderBorderColor() : 'primary'}
            border={renderBorderColor()}
            placeholder={placeholder}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            suffix={
              hasError ? (
                <AlertIcon className="text-alert-red" />
              ) : (
                <div className="mt-1">{suffix}</div>
              )
            }
            type={inputType}
            disabled={isFieldDisabled}
          />
        )}
      />
      <p
        className={`font-poppins text-xs font-light capitalize leading-4.5 ${
          hasError ? "text-alert-red visible mt-2" : "invisible"
        }`}
      >
        {hasError}
      </p>
    </div>
  );
};

export const CustomTextArea = ({
  className = "w-full bg-white rounded-lg",
  placeholder,
  suffix,
  inputType,
  style = {},
  rows = 4,
  autoSize = { minRows: 3, maxRows: 6 },
  formState = {},
  error,
  name,
  disabled = false,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const { errors } = formState;

  const hasError = error || errors?.[name]?.message || null;

  const outline = hasError
    ? "outline outline-1 outline-alert-red"
    : isFocused
    ? "outline outline-1 outline-primary"
    : "outline outline-1 outline-light-grey";

  return (
    <>
      <div className={`${className} ${outline}`}>
        <Input.TextArea
          bordered={false}
          {...props}
          className="font-aileron text-xs font-semibold"
          placeholder={placeholder}
          style={style}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          rows={rows}
          autoSize={autoSize}
          disabled={disabled}
        />
      </div>
      <p
        className={`font-poppins text-xs font-light leading-4.5 mt-2 ${
          hasError ? "text-alert-red visible" : "invisible"
        }`}
      >
        {hasError}
      </p>
    </>
  );
};

export const CustomSecondaryInput = ({ value, onChange, className, style }) => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  return (
    <input
      ref={ref}
      className={`px-3.5 py-4 w-[512px] max-h-[46px] border border-[#528587] outline-none bg-green-20 hover:bg-green-20
         hover:outline-none active:outline-none font-aileron rounded-md placeholder:font-aileron placeholder:text-dark-gray 
         ${className}
         `}
      style={style}
      value={value}
      onChange={onChange}
    />
  );
};

export default CustomInput;
