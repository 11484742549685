import React from "react";
import dayjs from "dayjs";
import { REQUEST_STATUS } from "../constants";
import getRequestStatus from "../getRequestStatus";
import { getRequestTypeByName } from "../getRequestType";
import { RequestDetailCTA } from "../RequestDetailsDrawer";

export const requestColumns = [
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray whitespace-nowrap">
        Requested By
      </div>
    ),
    dataIndex: "requestedBy",
    sorter: (a, b) => a?.requestedBy?.localeCompare(b?.requestedBy),
    render: (text) => (
      // will show the farmer name or the mobile number
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {text || "--"}
      </div>
    ),
  },
  // {
  //   title: () => (
  //     <div className="font-normal font-aileron text-10 text-dark-gray whitespace-nowrap">
  //       Contact
  //     </div>
  //   ),
  //   dataIndex: "id",
  //   // sorter: (a, b) => a?.contactNo?.localeCompare(b?.contactNo),
  //   render: (text, record) => {
  //     const contactNo = record?.metaData?.mobileNumber;
  //     return (
  //       <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
  //         {contactNo || "--"}
  //       </div>
  //     );
  //   },
  // },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray whitespace-nowrap">
        Request Type
      </div>
    ),
    dataIndex: "type",
    sorter: (a, b) => a?.type?.localeCompare(b?.type),
    render: (text) => <div>{getRequestTypeByName(text)}</div>,
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray whitespace-nowrap">
        Hub Station
      </div>
    ),
    dataIndex: "hubName",
    sorter: (a, b) => a?.hubName?.localeCompare(b?.hubName),
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text || "--"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray whitespace-nowrap">
        Status
      </div>
    ),
    dataIndex: "status",
    sorter: (a, b) => a.status - b.status,
    render: (text) => <div>{getRequestStatus(+text)}</div>,
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray whitespace-nowrap">
        Requested on
      </div>
    ),
    dataIndex: "submittedAt",
    sorter: (a, b) => a?.submittedAt?.localeCompare(b?.submittedAt),
    render: (text) => (
      <div className="font-aileron text-dark-gray">
        <p className="font-semibold text-xs leading-[14.5px]">
          {dayjs(text).format("DD-MM-YYYY")}
        </p>
        <p className="font-normal text-10 leading-3 uppercase">
          {dayjs(text).format("hh:mm:ss a")}
        </p>
      </div>
    ),
  },
  {
    title: () => <div />,
    dataIndex: "id",
    render: (text, record) => (
      <div>
        <RequestDetailCTA request={record} />
      </div>
    ),
  },
];

export const requestData = [
  {
    id: 1,
    requestedBy: "Steven Oyugi",
    contactNo: "+25412345678",
    type: "taas",
    hubName: "Ogembo Agr-E-Hub",
    status: REQUEST_STATUS.PENDING,
    submittedAt: dayjs(),
  },
  {
    id: 2,
    requestedBy: "Moses Sambaza",
    contactNo: "+25412345678",
    type: "taas",
    hubName: "Ogembo Agr-E-Hub",
    status: REQUEST_STATUS.TRIKE_ASSIGNING_COMPLETED,
    submittedAt: dayjs(),
  },
  {
    id: 3,
    requestedBy: "Brian Oklamba",
    contactNo: "+25412345678",
    type: "taas",
    hubName: "Ogembo Agr-E-Hub",
    status: REQUEST_STATUS.REJECTED,
    submittedAt: dayjs(),
  },
  {
    id: 4,
    requestedBy: "Steven Oyugi",
    contactNo: "+25412345678",
    type: "taas",
    hubName: "Ogembo Agr-E-Hub",
    status: REQUEST_STATUS.ASSIGN_TRIKE,
    submittedAt: dayjs(),
  },
  {
    id: 5,
    requestedBy: "Steven Oyugi",
    contactNo: "+25412345678",
    type: "purchase-feed",
    hubName: "Ogembo Agr-E-Hub",
    status: REQUEST_STATUS.PENDING,
    submittedAt: dayjs(),
  },
  {
    id: 6,
    requestedBy: "Steven Oyugi",
    contactNo: "+25412345678",
    type: "purchase-feed",
    hubName: "Ogembo Agr-E-Hub",
    status: REQUEST_STATUS.ACCEPTED,
    submittedAt: dayjs(),
  },
  {
    id: 7,
    requestedBy: "Steven Oyugi",
    contactNo: "+25412345678",
    type: "purchase-feeed",
    hubName: "Ogembo Agr-E-Hub",
    status: REQUEST_STATUS.REJECTED,
    submittedAt: dayjs(),
  },
  {
    id: 8,
    requestedBy: "Steven Oyugi",
    contactNo: "+25412345678",
    type: "power-africa",
    hubName: "Ogembo Agr-E-Hub",
    status: REQUEST_STATUS.PENDING,
    submittedAt: dayjs(),
  },
  {
    id: 9,
    requestedBy: "Moses Oyugi",
    contactNo: "+25412345678",
    type: "power-africa",
    hubName: "Ogembo Agr-E-Hub",
    status: REQUEST_STATUS.ACCEPTED,
    submittedAt: dayjs(),
  },
  {
    id: 10,
    requestedBy: "Brian Oyugi",
    contactNo: "+25412345678",
    type: "power-africa",
    hubName: "Ogembo Agr-E-Hub",
    status: REQUEST_STATUS.REJECTED,
    submittedAt: dayjs(),
  },
  {
    id: 11,
    requestedBy: "Steven Oyugi",
    contactNo: "+25412345678",
    type: "taas",
    hubName: "Ogembo Agr-E-Hub",
    status: REQUEST_STATUS.PENDING,
    submittedAt: dayjs(),
  },
  {
    id: 12,
    requestedBy: "Steven Oyugi",
    contactNo: "+25412345678",
    type: "purchase-feed",
    hubName: "Ogembo Agr-E-Hub",
    status: REQUEST_STATUS.ACCEPTED,
    submittedAt: dayjs(),
  },
];

export const requestTypeFilters = [
  {
    id: "taas",
    value: getRequestTypeByName("taas"),
  },
  {
    id: "power-africa",
    value: getRequestTypeByName("power-africa"),
  },
  {
    id: "purchase-feed",
    value: getRequestTypeByName("purchase-feed"),
  },
];

export const statusFilters = [
  {
    id: REQUEST_STATUS.ACCEPTED,
    value: getRequestStatus(REQUEST_STATUS.ACCEPTED),
  },
  {
    id: REQUEST_STATUS.ASSIGN_TRIKE,
    value: getRequestStatus(REQUEST_STATUS.ASSIGN_TRIKE),
  },
  {
    id: REQUEST_STATUS.PENDING,
    value: getRequestStatus(REQUEST_STATUS.PENDING),
  },
  {
    id: REQUEST_STATUS.REJECTED,
    value: getRequestStatus(REQUEST_STATUS.REJECTED),
  },
  {
    id: REQUEST_STATUS.TRIKE_ASSIGNING_COMPLETED,
    value: getRequestStatus(REQUEST_STATUS.TRIKE_ASSIGNING_COMPLETED),
  },
];
