/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import { FinanceHoverIcon } from "../../../Common/Svgs/FinanceIcon";
import CustomTable from "../../../Core/Components/CustomTable";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import CollectionDetailsHeader from "./CollectionDetailsHeader";
import { collectionDetailColumns } from "./data";
import { getCollectionDetails } from "../../../Redux/APIs/revenueAPI";
import useDebounce from "../../../Core/hooks/useDebounce";
import getFormatedFloat from "../../../Utils/getFormatedFloat";
import { PAYMENT_STATUS } from "../data";

const LOCAL_DEFAULT_LIMIT = 10;

const CollectionDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { selectedCollection, collectionDetails } = useSelector(
    (state) => state.revenue,
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    paymentStatus: [],
    dateRangeStart: "",
    dateRangeEnd: "",
  });

  const debounceSearch = useDebounce(search, 500);

  const handlePageNameNavigation = () => {
    navigate("/revenue?tab=collections");
  };

  const getTotalAmountDue = () => {
    if (+selectedCollection.paymentStatus === PAYMENT_STATUS.COMPLETED) {
      return "--";
    }

    const total =
      selectedCollection.totalAmountDue - selectedCollection.totalAmountPaid;

    return total > 0 ? getFormatedFloat(total) : "--";
  };

  useEffect(() => {
    const filterObj = {
      farmerId: selectedCollection.entityId,
      offset: ((currentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
      limit: (currentPage || 1) * LOCAL_DEFAULT_LIMIT,
    };

    if (debounceSearch) filterObj.searchQuery = debounceSearch;

    if (filters.paymentStatus.length)
      filterObj.paymentStatus = filters.paymentStatus;

    if (filters.dateRangeStart !== "" && filters.dateRangeEnd !== "") {
      filterObj.startDate = filters.dateRangeStart;
      filterObj.endDate = filters.dateRangeEnd;
    }

    dispatch(getCollectionDetails(filterObj));
  }, [dispatch, selectedCollection, currentPage, debounceSearch, filters]);

  return (
    <CustomLayout
      pageName="Collections"
      nestedName={`${selectedCollection?.firstName} ${selectedCollection?.lastName}`}
      customPageNameNavigation={handlePageNameNavigation}
    >
      <div className="w-full bg-background-white">
        <div className="p-6">
          <h1 className="font-poppins text-base font-medium capitalize">
            Collection History
          </h1>

          <p className="mt-3 font-poppins text-base font-medium">
            {/* {selectedCollection.paymentStatus === PAYMENT_STATUS.PENDING
              ? `${getFormatedFloat(collectionDetails.totalDue)} KSH`
              : "--"} */}

            {getTotalAmountDue()}
          </p>

          <p className="mt-1 font-aileron text-xs font-normal text-dark-gray capitalize">
            Total amount due
          </p>
        </div>

        <CollectionDetailsHeader
          search={search}
          setSearch={setSearch}
          filters={filters}
          setFilters={setFilters}
          setCurrentPage={setCurrentPage}
        />

        <div
          className={`h-[calc(100vh-72px-128px-70px-40px)] ${
            collectionDetails.result.length && "overflow-y-scroll"
          }`}
        >
          <CustomTable
            // scrollX={0}
            // scrollY="388px"
            disableScroll
            columns={collectionDetailColumns}
            data={collectionDetails.result}
            emptyState={
              <FinanceHoverIcon size={16} className="text-dark-grey-text" />
            }
            emptyStateHeight="420px"
            rowClassName="group"
            pagination={{
              pageSize: LOCAL_DEFAULT_LIMIT,
              total: collectionDetails.result.length,
              showSizeChanger: false,
              onChange: (page) => {
                setCurrentPage(page);
              },
              current: currentPage,
              itemRender: (current, type, originalElement) => {
                const lastPage = collectionDetails.totalRecords / current;

                if (type === "prev" && current === 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{
                        pointerEvents: "none",
                      }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "prev" && current > 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next" && lastPage <= 10) {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{ pointerEvents: "none" }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next") {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                return originalElement;
              },
            }}
          />
        </div>
      </div>
    </CustomLayout>
  );
};

export default CollectionDetails;
