import { useSelector } from "react-redux";

export const useHubs = () => {
  const { userDetails } = useSelector((state) => state.loggedInUser);

  const { allHubs } = useSelector((state) => state.locations);

  if (!allHubs.length) return [];

  return allHubs
    ?.filter((hub) => userDetails.hubs.includes(hub.id))
    ?.map((item) => ({
      id: item?.id,
      value: item?.name,
    }));
};

export const useLocations = (watch) => {
  const { allLocations } = useSelector((state) => state.locations);

  if (!allLocations.length) return [];

  const locations = allLocations.map((item) => ({
    id: item.id,
    value: item.name,
    hubId: +item.hubId,
  }));

  const hubId = watch("hubId");

  if (hubId) return locations.filter((item) => item.hubId === +hubId);

  return locations;
};
