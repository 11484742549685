/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { assetColumns, AssetKPIData } from "./data";
import usePermission from "../../Core/hooks/usePermission";
import { PERMISSIONS } from "../Login/constants";
import CustomTable from "../../Core/Components/CustomTable";
import { getTableContainerHeight } from "../../Utils/getTableContainerHeight";
import ChevronIcon from "../../Common/Svgs/ChevronIcon";
import QRCodeModal from "../../Core/Components/Modal/QRCodeModal";
import { updateQrCode } from "../../Redux/Slices/qrcode.slice";
import useDebounce from "../../Core/hooks/useDebounce";
import {
  getAllAssets,
  getAssets,
  getPowerAfricaAssetChecklist,
  getPowerAfricaKPI,
} from "../../Redux/APIs/powerAfricaAPI";
import { updateAsset } from "../../Redux/Slices/powerAfrica.slice";
import ToastMessageModal from "./ToastMessageModal";
import TillerIcon from "../../Common/Svgs/TillerIcon";
import KPIContainer from "./KPIContainer";
import DeletePowerAfricaAssetModal from "./DeletePowerAfricaAssetModal";
import PowerAfricaAssetHeader from "./PowerAfricaAssetHeader";

const LOCAL_DEFAULT_LIMIT = 10;

const PowerAfricaAssetList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { assetList, kpiData, deletePowerAfricaAssetModal, showToastMessage } =
    useSelector((state) => state.powerAfrica);

  const { viewModal } = useSelector((state) => state.qrCode);

  const [showKpi, setShowKpi] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    hub: [],
    status: [],
    type: [],
  });

  const tableContainerRef = useRef();

  const debounceSearch = useDebounce(search, 500);

  // service permission
  const hasServicePermission = usePermission([
    PERMISSIONS.SERVICE_VIEW,
    PERMISSIONS.SERVICE_VIEW_AND_EDIT,
  ]);

  const handleRowClick = (record) => {
    dispatch(
      updateAsset({
        key: "currentAsset",
        value: record,
      }),
    );
    navigate(`/assets/agri-tech/${record.registrationNo}`);
  };

  const getTableContainerStyle = () => {
    if (assetList.result.length) {
      if (showKpi)
        return "h-[calc(100vh-72px-71px-61px-40px)] overflow-y-scroll";
      return "h-[calc(100vh-72px-71px-160px-61px-40px)] overflow-y-scroll";
    }
    return "h-[calc(100vh-72px-160px-61px-40px)]";
  };

  // fetch the kpi data
  useEffect(() => {
    dispatch(getPowerAfricaKPI());
    dispatch(getPowerAfricaAssetChecklist());
    dispatch(getAllAssets());
  }, [dispatch]);

  // close the toast message after 5 seconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        updateAsset({
          key: "showToastMessage",
          value: {
            visible: false,
            data: {},
          },
        }),
      );
    }, 5000);

    return () => clearTimeout(timeout);
  }, [dispatch, showToastMessage.visible]);

  // fetch the assetlist list on every page change
  useEffect(() => {
    const filterObj = {
      offset: ((currentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
      limit: LOCAL_DEFAULT_LIMIT,
    };

    if (debounceSearch) filterObj.searchQuery = debounceSearch;
    if (filters.hub.length) filterObj.hubId = filters.hub;
    if (filters.status.length) filterObj.status = filters.status;
    if (filters.type.length) filterObj.type = filters.type;

    dispatch(getAssets(filterObj));
  }, [dispatch, currentPage, filters, debounceSearch]);

  return (
    <div>
      <div className="relative bg-background">
        <div className="flex items-center justify-between w-full h-40 p-6 gap-x-5.5">
          {AssetKPIData(kpiData)?.map((i) => (
            <KPIContainer
              key={i?.id}
              id={i.id}
              image={i?.image}
              title={i?.title}
              count={i?.count}
              icon={hasServicePermission && i.count > 0 && i?.icon}
              redirect={hasServicePermission && i?.id === 3 && i.count > 0}
              onClick={() => {
                if (i.id === 1) {
                  dispatch(updateAsset({ key: "viewAllKPIs", value: true }));
                }

                if (hasServicePermission && i?.id === 3 && i.count > 0) {
                  navigate("/service");
                }
              }}
            />
          ))}
        </div>

        <div
          className={`absolute w-[calc(100vw-72px)] ${
            showKpi ? "-translate-y-[160px]" : ""
          }  transition-transform duration-500`}
        >
          <PowerAfricaAssetHeader
            showKpi={showKpi}
            setShowKpi={setShowKpi}
            search={search}
            setSearch={setSearch}
            filters={filters}
            setFilters={setFilters}
            setCurrentPage={setCurrentPage}
          />

          <div className={getTableContainerStyle()} ref={tableContainerRef}>
            <CustomTable
              // scrollX={0}
              disableScroll
              columns={assetColumns}
              data={assetList.result}
              onRowClick={handleRowClick}
              emptyState={<TillerIcon className="text-dark-grey-text" />}
              emptyStateHeight={getTableContainerHeight(tableContainerRef)}
              rowClassName="group cursor-pointer"
              pagination={{
                pageSize: LOCAL_DEFAULT_LIMIT,
                total: assetList.totalRecords,
                showSizeChanger: false,
                onChange: (page) => {
                  setCurrentPage(page);
                },
                current: currentPage,
                itemRender: (current, type, originalElement) => {
                  const lastPage = assetList.totalRecords / current;

                  if (type === "prev" && current === 0) {
                    return (
                      <button
                        type="button"
                        className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                        style={{
                          pointerEvents: "none",
                        }}
                      >
                        <ChevronIcon className="stroke-dark-gray" />
                      </button>
                    );
                  }

                  if (type === "prev" && current > 0) {
                    return (
                      <button
                        type="button"
                        className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      >
                        <ChevronIcon className="stroke-dark-gray" />
                      </button>
                    );
                  }

                  if (type === "next" && lastPage <= 10) {
                    return (
                      <button
                        type="button"
                        className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                        style={{ pointerEvents: "none" }}
                      >
                        <ChevronIcon className="stroke-dark-gray" />
                      </button>
                    );
                  }

                  if (type === "next") {
                    return (
                      <button
                        type="button"
                        className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      >
                        <ChevronIcon className="stroke-dark-gray" />
                      </button>
                    );
                  }

                  return originalElement;
                },
              }}
            />
          </div>
        </div>
      </div>

      <QRCodeModal
        visible={viewModal?.visible}
        type={viewModal?.data?.assetType}
        onCancel={() =>
          dispatch(
            updateQrCode({
              key: "viewModal",
              value: { visible: false, data: {} },
            }),
          )
        }
      />

      <DeletePowerAfricaAssetModal
        visible={deletePowerAfricaAssetModal}
        onCancel={() =>
          dispatch(
            updateAsset({
              key: "deletePowerAfricaAssetModal",
              value: false,
            }),
          )
        }
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />

      <ToastMessageModal
        visible={showToastMessage?.visible}
        onCancel={() =>
          dispatch(
            updateAsset({
              key: "showToastMessage",
              value: {
                visible: false,
                data: {},
              },
            }),
          )
        }
        title={showToastMessage?.data?.title}
        description={showToastMessage?.data?.description}
      />
    </div>
  );
};

export default PowerAfricaAssetList;
