import React from "react";

const FinanceIcon = ({ size, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
      >
        <g>
          <g>
            <path
              stroke="#AABDBD"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M8.324 14.983a6.658 6.658 0 100-13.316 6.658 6.658 0 000 13.316z"
            />
            <path
              stroke="#AABDBD"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M10.816 16.567a4.12 4.12 0 003.375 1.75 4.13 4.13 0 004.125-4.125 4.134 4.134 0 00-1.725-3.359"
            />
            <path
              fill="#AABDBD"
              d="M6.034 11.667h1.04a.2.2 0 00.2-.2V9.486a.2.2 0 01.07-.152l.288-.248a.2.2 0 01.297.041l1.633 2.45a.2.2 0 00.167.09h.644a.2.2 0 00.163-.316l-2.03-2.88a.2.2 0 01.023-.257l.164-.164A.2.2 0 019 8.079l2.38 3.5a.2.2 0 00.166.088h.58a.2.2 0 00.165-.312L9.638 7.41a.2.2 0 01.025-.253l1.814-1.815A.2.2 0 0011.336 5h-1.254a.2.2 0 00-.14.057l-2.33 2.265a.2.2 0 01-.339-.144V5.2a.2.2 0 00-.2-.2H6.034a.2.2 0 00-.2.2v6.267c0 .11.09.2.2.2z"
            />
          </g>
        </g>
      </svg>
    </span>
  );
};

export const FinanceHoverIcon = ({ size, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M8.324 14.983a6.658 6.658 0 100-13.316 6.658 6.658 0 000 13.316zM10.816 16.567a4.12 4.12 0 003.375 1.75 4.13 4.13 0 004.125-4.125 4.134 4.134 0 00-1.725-3.359"
        />
        <path
          fill="currentColor"
          d="M6.034 11.667h1.04a.2.2 0 00.2-.2V9.486a.2.2 0 01.07-.152l.288-.248a.2.2 0 01.297.041l1.633 2.45a.2.2 0 00.167.09h.644a.2.2 0 00.163-.316l-2.03-2.88a.2.2 0 01.023-.257l.164-.164A.2.2 0 019 8.079l2.38 3.5a.2.2 0 00.166.088h.58a.2.2 0 00.165-.312L9.638 7.41a.2.2 0 01.025-.253l1.814-1.815A.2.2 0 0011.336 5h-1.254a.2.2 0 00-.14.057l-2.33 2.265a.2.2 0 01-.339-.144V5.2a.2.2 0 00-.2-.2H6.034a.2.2 0 00-.2.2v6.267c0 .11.09.2.2.2z"
        />
      </svg>
    </span>
  );
};

export const FinanceActiveIcon = ({
  size,
  className,
  strokeColor,
  fillColor,
}) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          fill={strokeColor}
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M8.324 14.983a6.658 6.658 0 100-13.317 6.658 6.658 0 000 13.317z"
        />
        <path
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M10.816 16.567a4.12 4.12 0 003.375 1.75 4.13 4.13 0 004.125-4.125 4.134 4.134 0 00-1.725-3.358"
        />
        <path
          fill={fillColor}
          d="M6.034 11.667h1.04a.2.2 0 00.2-.2V9.486a.2.2 0 01.07-.152l.288-.248a.2.2 0 01.297.041l1.633 2.45a.2.2 0 00.167.09h.644a.2.2 0 00.163-.316l-2.03-2.88a.2.2 0 01.023-.257l.164-.164A.2.2 0 019 8.079l2.38 3.5a.2.2 0 00.166.088h.58a.2.2 0 00.165-.312L9.638 7.41a.2.2 0 01.025-.253l1.814-1.815A.2.2 0 0011.336 5h-1.254a.2.2 0 00-.14.057l-2.33 2.265a.2.2 0 01-.339-.144V5.2a.2.2 0 00-.2-.2H6.034a.2.2 0 00-.2.2v6.267c0 .11.09.2.2.2z"
        />
      </svg>
    </span>
  );
};

export const FinanceDueIcon = ({ size = 16, className = "" }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M6.659 12.987a5.327 5.327 0 1 0 0-10.654 5.327 5.327 0 0 0 0 10.654M8.652 14.253c.6.847 1.58 1.4 2.7 1.4 1.82 0 3.3-1.48 3.3-3.3a3.3 3.3 0 0 0-1.38-2.686"
        />
        <path
          fill="currentColor"
          d="M4.868 10.333h.751a.2.2 0 0 0 .2-.2V8.607a.2.2 0 0 1 .07-.152l.183-.157a.2.2 0 0 1 .297.041l1.27 1.905a.2.2 0 0 0 .166.09h.417a.2.2 0 0 0 .163-.316l-1.56-2.214a.2.2 0 0 1 .023-.256l.073-.074a.2.2 0 0 1 .307.029l1.865 2.743a.2.2 0 0 0 .166.087h.367a.2.2 0 0 0 .166-.311L7.73 6.955a.2.2 0 0 1 .024-.253l1.36-1.36A.2.2 0 0 0 8.973 5h-.89a.2.2 0 0 0-.14.057L6.16 6.79a.2.2 0 0 1-.34-.143V5.2a.2.2 0 0 0-.2-.2h-.751a.2.2 0 0 0-.2.2v4.933c0 .11.09.2.2.2"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M15.999 3.667V1h-2.667M12.668 4.333 16.001 1"
        />
      </svg>
    </span>
  );
};

export const FinancePaidIcon = ({ size = 16, className = "" }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M6.659 12.987a5.327 5.327 0 1 0 0-10.654 5.327 5.327 0 0 0 0 10.654M8.652 14.253c.6.847 1.58 1.4 2.7 1.4 1.82 0 3.3-1.48 3.3-3.3a3.3 3.3 0 0 0-1.38-2.686"
        />
        <path
          fill="currentColor"
          d="M4.868 10.333h.751a.2.2 0 0 0 .2-.2V8.607a.2.2 0 0 1 .07-.152l.183-.157a.2.2 0 0 1 .297.041l1.27 1.905a.2.2 0 0 0 .166.09h.417a.2.2 0 0 0 .163-.316l-1.56-2.214a.2.2 0 0 1 .023-.256l.073-.074a.2.2 0 0 1 .307.029l1.865 2.743a.2.2 0 0 0 .166.087h.367a.2.2 0 0 0 .166-.311L7.73 6.955a.2.2 0 0 1 .024-.253l1.36-1.36A.2.2 0 0 0 8.973 5h-.89a.2.2 0 0 0-.14.057L6.16 6.79a.2.2 0 0 1-.34-.143V5.2a.2.2 0 0 0-.2-.2h-.751a.2.2 0 0 0-.2.2v4.933c0 .11.09.2.2.2"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M12.665 1.667v2.666h2.667M16 1l-3.333 3.333"
        />
      </svg>
    </span>
  );
};

export default FinanceIcon;
