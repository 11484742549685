import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CustomModal from "../../../Core/Components/Modal/CustomModal";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import Button from "../../../Core/Components/CustomButton";
import AlertIcon from "../../../Common/Svgs/AlertIcon";
import { updateSession } from "../../../Redux/Slices/sessions.slice";

const EndProcessModal = ({ visible, onCancel }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onConfirm = () => {
    navigate("/sessions");

    dispatch(
      updateSession({
        key: "createSessionStep",
        value: 1,
      }),
    );

    dispatch(
      updateSession({
        key: "currentSession",
        value: {},
      }),
    );

    dispatch(
      updateSession({
        key: "sessionThroughUSSD",
        value: false,
      }),
    );

    onCancel();
  };

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="536px">
      <div
        className="p-4"
        style={{
          boxShadow:
            "12px 12px 16px -4px rgba(16, 24, 40, 0.08), -4px -4px 6px -2px rgba(16, 24, 40, 0.03)",
        }}
      >
        <div className="flex items-center justify-between">
          <div className="w-12 h-12 flex items-center justify-center rounded-full bg-warning-10">
            <AlertIcon size={24} className="text-warning" />
          </div>

          <button type="button" className="mr-2" onClick={onCancel}>
            <CloseIcon className="stroke-dark-gray w-6 h-6" />
          </button>
        </div>

        <div className="mt-5 px-2 pb-2">
          <h1 className="font-poppins text-base font-medium">End process</h1>

          <p className="mt-2 font-aileron text-xs font-normal">
            Are you sure you want to end this process?
          </p>

          <div className="mt-11 flex justify-end gap-x-3">
            <Button colors="grey" size="primary" onClick={onCancel}>
              Cancel
            </Button>

            <Button colors="primary" size="primary" onClick={onConfirm}>
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default EndProcessModal;
