/* eslint-disable no-console */
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import CustomLayout from "../../Core/Layout/CustomLayout";
import Button from "../../Core/Components/CustomButton";
import { CustomTextArea } from "../../Core/Components/CustomInput";
import PlusIcon from "../../Common/Svgs/PlusIcon";
import TrashIcon from "../../Common/Svgs/TrashIcon";
import { PopoverDropdown } from "../../Core/Components/CustomDropdown";
import { updateTrikes } from "../../Redux/Slices/trikes.slice";
import ToastMessageModal from "./ToastMessageModal";
import usePermission from "../../Core/hooks/usePermission";
import { PERMISSIONS } from "../Login/constants";
import TrikeKnownIssueDropdown from "../Trikes/phase3/TrikeKnownIssueDropdown";
import {
  addKnownIssues,
  resolveKnownIssue,
  updateKnownIssues,
} from "../../Redux/APIs/powerAfricaAPI";
import { updateAsset } from "../../Redux/Slices/powerAfrica.slice";
import usePowerAfricaChecklist from "./usePowerAfricaChecklist";

const PowerAfricaAssetKnownIssues = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const { currentAsset, showToastMessage } = useSelector(
    (state) => state.powerAfrica,
  );

  // check if power africa asset issue add permission exists
  const hasPowerAfricaIssueAddPermission = usePermission([
    PERMISSIONS.TRIKE_ISSUE_ADD,
  ]);

  // const checklistData = tillerChecklist.length
  //   ? tillerChecklist.map((item) => ({
  //       id: item.partId,
  //       value: item.partName,
  //     }))
  //   : [];

  const checklistData = usePowerAfricaChecklist();

  const { handleSubmit, control, formState, setValue, clearErrors } = useForm({
    defaultValues: {
      issues: currentAsset?.knownIssues?.length
        ? currentAsset?.knownIssues
        : [
            {
              partId: "",
              partName: "",
              partDescription: "",
            },
          ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "issues",
  });

  const rulesForPart = {
    required: "Required.",
  };

  const rulesForDescription = {
    required: "A clear description of the issue is required..",
  };

  const onCancel = () => navigate(-1);

  const onResolve = async (issue, index) => {
    await dispatch(
      resolveKnownIssue({
        powerAfricaAssetId: currentAsset?.id,
        partId: issue.partId,
      }),
    );

    dispatch(
      updateAsset({
        key: "showToastMessage",
        value: {
          visible: true,
          data: {
            title: "1 Issue Resolved",
            description: `${issue.partName} issue for asset ${currentAsset?.registrationNo} was resolved successfully.`,
          },
        },
      }),
    );

    remove(index);
  };

  const onSave = async (e) => {
    const payload = {
      powerAfricaAssetId: currentAsset.id,
      registrationNo: currentAsset.registrationNo,
      issues: e.issues,
    };

    if (currentAsset?.knownIssues?.length) {
      await dispatch(updateKnownIssues(payload));

      dispatch(
        updateAsset({
          key: "showToastMessage",
          value: {
            visible: true,
            data: {
              title: "Saved Changes",
              description: `Saved changes to issues for asset ${currentAsset?.registrationNo} successfully.`,
            },
          },
        }),
      );
    } else {
      await dispatch(addKnownIssues(payload));

      dispatch(
        updateAsset({
          key: "showToastMessage",
          value: {
            visible: true,
            data: {
              title: `${e.issues?.length} Issue Added`,
              description: `${e.issues?.length} issues were added for asset ${currentAsset?.registrationNo} successfully.`,
            },
          },
        }),
      );
    }

    navigate(-1);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        updateAsset({
          key: "showToastMessage",
          value: {
            visible: false,
            data: {},
          },
        }),
      );
    }, 5000);

    return () => clearTimeout(timeout);
  }, [dispatch, showToastMessage]);

  return (
    <CustomLayout pageName="Assets" nestedName={`${id}`} hideFooter>
      <div className="px-6 pt-6 bg-background-white">
        <div className="flex items-center justify-between pb-6">
          <p className="font-poppins text-base font-medium capitalize">
            Add issues for this asset
          </p>

          <div className="flex items-center gap-x-3">
            <Button
              size="filter"
              width="generateUser"
              colors="grey"
              onClick={onCancel}
            >
              Cancel
            </Button>

            {hasPowerAfricaIssueAddPermission && (
              <Button
                size="filter"
                width="generateUser"
                type="submit"
                onClick={handleSubmit(onSave)}
              >
                Save
              </Button>
            )}
          </div>
        </div>

        <div className="w-full bg-light-grey h-[1px]" />
      </div>

      <div className="bg-background-white h-[calc(100vh-88px-72px)] p-6 overflow-y-scroll">
        <p className="font-poppins text-xs font-medium">
          Choose an asset part to report issues
        </p>

        {fields.map((issue, index) => (
          <div key={issue.id} className="mt-6 max-w-[367px]">
            <div className="flex items-center justify-between">
              <p className="font-poppins text-xs font-medium">
                Issue {index + 1}
              </p>

              {hasPowerAfricaIssueAddPermission &&
              currentAsset?.knownIssues?.length ? (
                <TrikeKnownIssueDropdown
                  onResolve={() => onResolve(issue, index)}
                  onDelete={() => remove(index)}
                />
              ) : (
                hasPowerAfricaIssueAddPermission &&
                fields.length > 1 && (
                  <button
                    type="button"
                    className="bg-alert-10 py-1 px-2 rounded-md"
                    onClick={() => remove(index)}
                  >
                    <TrashIcon className="text-alert-red" />
                  </button>
                )
              )}
            </div>

            <p className="font-aileron text-xs font-normal text-dark-gray mt-3 mb-1.5">
              Type
            </p>
            <Controller
              name={`issues.${index}.partName`}
              control={control}
              rules={rulesForPart}
              render={({ field }) => (
                <PopoverDropdown
                  {...field}
                  formState={formState}
                  enableSearch
                  searchPlaceholder="Search Asset Parts"
                  placeholder="Select An Asset Part"
                  options={checklistData}
                  onSelect={(option) => {
                    setValue(`issues.${index}.partName`, option.value);
                    setValue(`issues.${index}.partId`, option.id);
                    clearErrors(`issues.${index}.partName`);
                  }}
                  error={formState?.errors?.issues?.[index]?.partName?.message}
                  valueStyle={{
                    pointerEvents: hasPowerAfricaIssueAddPermission
                      ? ""
                      : "none",
                  }}
                />
              )}
            />

            <p
              className={`font-poppins text-xs font-light capitalize leading-4.5 mt-2 ${
                formState?.errors?.issues?.[index]?.partName?.message
                  ? "text-alert-red visible"
                  : "invisible"
              }`}
            >
              {formState?.errors?.issues?.[index]?.partName?.message}
            </p>

            <p className="font-aileron text-xs font-normal text-dark-gray capitalize mt-6 mb-1.5">
              Description
            </p>
            <Controller
              name={`issues.${index}.partDescription`}
              control={control}
              rules={rulesForDescription}
              render={({ field }) => (
                <CustomTextArea
                  {...field}
                  formState={formState}
                  className="w-[367px] bg-white rounded-md"
                  placeholder="Describe the issue..."
                  error={
                    formState?.errors?.issues?.[index]?.partDescription?.message
                  }
                  disabled={!hasPowerAfricaIssueAddPermission}
                />
              )}
            />
          </div>
        ))}

        {hasPowerAfricaIssueAddPermission && (
          <button
            type="button"
            className="py-1 px-2 mt-6"
            onClick={() => {
              append({
                item: "",
              });
            }}
          >
            <div className="flex items-center gap-x-2 ">
              <p className="font-poppins text-xs font-medium text-primary">
                Add More Issues
              </p>
              <PlusIcon className="w-4 h-4 stroke-primary" />
            </div>
          </button>
        )}
      </div>

      <ToastMessageModal
        visible={showToastMessage.visible}
        onCancel={() =>
          dispatch(
            updateTrikes({
              key: "showToastMessage",
              value: {
                visible: false,
                data: {},
              },
            }),
          )
        }
        title={showToastMessage?.data?.title}
        description={showToastMessage?.data?.description}
      />
    </CustomLayout>
  );
};

export default PowerAfricaAssetKnownIssues;
