/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "antd";
import ChevronIcon from "../../Common/Svgs/ChevronIcon";
import { updateAsset } from "../../Redux/Slices/powerAfrica.slice";
import { client } from "../../Utils/axiosClient";

const Container = ({ bgColor, image, count, type = "tillers" }) => {
  return (
    <div className="bg-white p-4 flex-1 h-[148px] border border-light-grey rounded-md">
      <div
        className="w-[38px] h-[38px] flex items-center justify-center rounded-full"
        style={{ background: bgColor }}
      >
        <img src={image} className="w-4 h-4" alt="" />
      </div>

      <div className="mt-4">
        <p className="font-poppins font-semibold text-2xl leading-9 text-black">
          {count || 0}
        </p>

        <p className="mt-1 font-aileron font-normal text-xs leading-[14.5px] text-dark-gray capitalize">
          {type}
        </p>
      </div>
    </div>
  );
};

const ViewAllKPIs = () => {
  const dispatch = useDispatch();

  const [count, setCount] = useState({
    tillers: 0,
    millers: 0,
    waterPumps: 0,
    chaufCutters: 0,
    threshers: 0,
  });

  const getCount = useCallback(async () => {
    try {
      dispatch(
        updateAsset({
          key: "loading",
          value: true,
        }),
      );

      const response = await client.get(
        `${process.env.REACT_APP_API_URL}/power-africa//get-total-count`,
      );

      setCount(response?.data?.result);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(
        updateAsset({
          key: "loading",
          value: false,
        }),
      );
    }
  }, [dispatch]);

  useEffect(() => {
    getCount();
  }, [getCount]);

  return (
    <div className="bg-background p-6">
      <button
        type="button"
        className="flex items-center py-[5px] px-2 gap-x-2"
        onClick={() =>
          dispatch(updateAsset({ key: "viewAllKPIs", value: false }))
        }
      >
        <ChevronIcon className="w-4 h-4 stroke-green -rotate-180" />

        <p className="font-poppins font-medium text-xs leading-[18px] text-primary">
          Back
        </p>
      </button>

      {/* first line of assets */}
      <Row className="mt-[26px]" gutter={24}>
        <Col span={8}>
          <Container
            bgColor="#FBF1CF"
            image="/assets/icons/power-africa/tiller.svg"
            count={count?.tillers}
          />
        </Col>

        <Col span={8}>
          <Container
            bgColor="#E6EDEE"
            image="/assets/icons/power-africa/miller.svg"
            count={count?.millers}
            type="millers"
          />
        </Col>

        <Col span={8}>
          <Container
            bgColor="#F9ECEA"
            image="/assets/icons/power-africa/thresher.svg"
            count={count?.threshers}
            type="threshers"
          />
        </Col>
      </Row>

      {/* second line of assets */}
      <Row className="mt-5" gutter={24}>
        <Col span={8}>
          <Container
            bgColor="#FEF3E9"
            image="/assets/icons/power-africa/water-pump.svg"
            count={count?.waterPumps}
            type="water pumps"
          />
        </Col>

        <Col span={8}>
          <Container
            bgColor="#FCEBF2"
            image="/assets/icons/power-africa/chauf-cutter.svg"
            count={count?.chaufCutters}
            type="chauf cutters"
          />
        </Col>
      </Row>
    </div>
  );
};

export default ViewAllKPIs;
