import { createSlice } from "@reduxjs/toolkit";
import {
  getCollectionDetails,
  getCollectionKPI,
  getCollectionList,
  getDriverPaymentList,
  getFarmerPaymentList,
  getPaymentDetails,
  getPaymentDetailsKPI,
  getPaymentKPI,
  updatePaymentStatus,
  updateTempPaymentStatus,
} from "../APIs/revenueAPI";

const initialState = {
  loading: false,
  error: {},
  status: "idle",

  showToastMessage: {
    visible: false,
    data: {},
  },

  paymentKPI: {
    total: 0,
    drivers: 0,
    farmers: 0,
  },
  farmerPaymentList: {
    result: [],
    totalRecords: 0,
  },
  driverPaymentList: {
    result: [],
    totalRecords: 0,
  },
  selectedPayment: {},
  paymentDetails: {
    result: [],
    totalDue: 0,
    totalRecords: 0,
  },
  collectionKPI: {
    amountDue: 0,
    amountCollected: 0,
  },
  collectionList: {
    result: [],
    totalRecords: 0,
  },
  selectedCollection: {},
  collectionDetails: {
    result: [],
    totalDue: 0,
    totalRecords: 0,
  },
  threeDotsPopoverId: 0,
  paymentDetailsPopoverId: 0,
  collectionDetailsPopoverId: 0,
  selectedPaymentDetailStatusId: 0,
  selectedCollectionDetailStatusId: 0,
  transactionHistoryVisible: false,
  editCommodityModal: {
    visible: false,
    current: {},
  },

  collectionListCurrentPage: 1,
  paymentListCurrentPage: 1,
  paymentDetailsKPI: {
    paid: 0,
    due: 0,
  },
};

export const revenueSlice = createSlice({
  name: "revenue",
  initialState,
  reducers: {
    updateRevenue: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers(builder) {
    // get payment kpi
    builder.addCase(getPaymentKPI.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPaymentKPI.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.paymentKPI = action.payload;
    });
    builder.addCase(getPaymentKPI.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get farmer payment list
    builder.addCase(getFarmerPaymentList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFarmerPaymentList.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.farmerPaymentList = action.payload;
    });
    builder.addCase(getFarmerPaymentList.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get driver payment list
    builder.addCase(getDriverPaymentList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDriverPaymentList.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.driverPaymentList = action.payload;
    });
    builder.addCase(getDriverPaymentList.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get payment details
    builder.addCase(getPaymentDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPaymentDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.paymentDetails = action.payload.response;
    });
    builder.addCase(getPaymentDetails.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get collection kpi
    builder.addCase(getCollectionKPI.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCollectionKPI.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.collectionKPI = action.payload;
    });
    builder.addCase(getCollectionKPI.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get collection list
    builder.addCase(getCollectionList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCollectionList.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.collectionList = action.payload;
    });
    builder.addCase(getCollectionList.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get payment details
    builder.addCase(getCollectionDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCollectionDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.collectionDetails = action.payload.response;
    });
    builder.addCase(getCollectionDetails.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // update temp payment status
    builder.addCase(updateTempPaymentStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateTempPaymentStatus.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(updateTempPaymentStatus.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // update payment status
    builder.addCase(updatePaymentStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePaymentStatus.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(updatePaymentStatus.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get payment details kpi
    builder.addCase(getPaymentDetailsKPI.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPaymentDetailsKPI.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.paymentDetailsKPI = action.payload.result;
    });
    builder.addCase(getPaymentDetailsKPI.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });
  },
});

export const { updateRevenue } = revenueSlice.actions;

export default revenueSlice.reducer;
