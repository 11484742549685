import React from "react";
import EditIcon from "../../../Common/Svgs/EditIcon";
import QRCodeIcon from "../../../Common/Svgs/QRCodeIcon";
import TrashIcon from "../../../Common/Svgs/TrashIcon";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";
import NewFarmerAddIcon from "../../../Common/Svgs/NewFarmerAddIcon";

const useThreeDotsForFarmers = () => {
  const threeDotsForFarmers = [
    {
      id: 1,
      value: "Edit Details",
      icon: <EditIcon className="text-dark-gray group-hover:text-black" />,
      hasPermission: usePermission([PERMISSIONS.PARTNER_EDIT]),
    },
    {
      id: 2,
      value: "View QR Code",
      icon: <QRCodeIcon className="text-dark-gray group-hover:text-black" />,
      hasPermission: usePermission([PERMISSIONS.PARTNER_VIEW_QR]),
    },
    {
      id: 4,
      value: "Approve",
      icon: (
        <NewFarmerAddIcon
          size={16}
          className="text-dark-gray group-hover:text-black"
        />
      ),
      hasPermission: usePermission([
        PERMISSIONS.PARTNER_EDIT,
        PERMISSIONS.PARTNER_ADD,
      ]),
    },
    {
      id: 3,
      value: "Delete Partner",
      icon: <TrashIcon className="text-alert-red" />,
      hasPermission: usePermission([PERMISSIONS.PARTNER_DELETE]),
    },
  ];

  return threeDotsForFarmers;
};

export default useThreeDotsForFarmers;
