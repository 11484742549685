export const REQUEST_TYPE = {
  TRANSPORT: 1,
  PURCHASE: 2,
  SESSION: 3,
};

export const REQUEST_STATUS = {
  PENDING: 1,
  ACCEPTED: 2,
  REJECTED: 3,
  ASSIGN_TRIKE: 4,
  TRIKE_ASSIGNING_COMPLETED: 5,
};

export const transportRejectionList = [
  {
    id: 1,
    text: "Trikes are unavailable",
  },
  {
    id: 2,
    text: "Time slot is unavailable",
  },
  {
    id: 3,
    text: "Cargo is unavailable",
  },
  {
    id: 4,
    text: "Other",
  },
];

export const purchaseRejectionList = [
  {
    id: 1,
    text: "Feed is unavailable",
  },
  {
    id: 2,
    text: "Time slot is unavailable",
  },
  {
    id: 3,
    text: "Other",
  },
];

export const sessionRejectionList = [
  {
    id: 1,
    text: "Assets are unavailable",
  },
  {
    id: 2,
    text: "Time slot is unavailable",
  },
  {
    id: 3,
    text: "Agent is unavailable",
  },
  {
    id: 4,
    text: "Other",
  },
];
