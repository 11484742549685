import { SESSION_TYPE } from "../Sessions/constants";

const getSessionTypeDetails = (type) => {
  let activityTypeId = 1;

  if (type === "tilling") activityTypeId = SESSION_TYPE.TILLING;
  if (type === "milling") activityTypeId = SESSION_TYPE.MILLING;
  if (type === "threshing") activityTypeId = SESSION_TYPE.THRESHING;
  if (type === "water pumping") activityTypeId = SESSION_TYPE.WATER_PUMPING;
  if (type === "chaff cutting") activityTypeId = SESSION_TYPE.CHAUF_CUTTING;

  return activityTypeId;
};

export default getSessionTypeDetails;
