import React from "react";
import { useSelector } from "react-redux";
import CustomRoundedImage from "../../../../Core/Components/CustomRoundedImage";
import { BatteryPackIcon } from "../../../../Common/Svgs/BatteryIcon";
import EmptyState from "./EmptyState";
import MappedBatteries from "./MappedBatteries";

const AssetBatteries = () => {
  const { currentAsset } = useSelector((state) => state.powerAfrica);

  const getBody = () => {
    if (!currentAsset.batteryPack.length) return <EmptyState />;
    return <MappedBatteries />;
  };

  return (
    <div className="w-full h-full border-[0.5px] border-light-grey rounded-md p-4 flex flex-col justify-between">
      <div className="flex items-center justify-between">
        <div className="flex justify-start items-center gap-x-2.5">
          <CustomRoundedImage
            background="bg-primary"
            image={<BatteryPackIcon className="text-white" />}
          />
          <div className="font-aileron font-normal text-dark-gray text-xs leading-4.5">
            Battery Pack
          </div>
        </div>

        <div className="flex justify-start items-start gap-x-2.5">
          <img src="/assets/icons/details/discharging-grey.svg" alt="" />
          <div className="font-aileron font-normal text-dark-gray text-xs leading-4.5">
            Discharging
          </div>
        </div>
      </div>

      {getBody()}
    </div>
  );
};

export default AssetBatteries;
