/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import { Checkbox, Popover } from "antd";
import ChevronIcon from "../../Common/Svgs/ChevronIcon";
import CustomInput from "./CustomInput";

const FilterItem = ({
  label,
  identifier,
  customValue = false,
  contentData = [],
  onSelect = () => null,
  selectedValues = [],
  className = "",
  enableSearch = false,
}) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");

  const filteredData = () => {
    if (enableSearch) {
      return contentData.filter((item) =>
        item?.value?.toLowerCase().includes(search.toLowerCase()),
      );
    }

    return contentData;
  };

  const hasValues = selectedValues.length > 0;

  const getHeaderStyle = () => {
    if (hasValues) return "bg-primary text-white";
    if (open) return "bg-background text-dark-gray";
    return "bg-background-white text-dark-gray hover:bg-background";
  };

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      open={open}
      arrow={false}
      onOpenChange={(state) => setOpen(state)}
      content={
        <div>
          {contentData.length ? (
            <div
              className="bg-background-white py-1 pl-1.5 rounded-md"
              style={{ width: "225px" }}
            >
              {enableSearch && (
                <div className="px-1 mb-2">
                  <CustomInput
                    value={search}
                    intent="searchFilterItem"
                    placeholder="Search Hub Station"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              )}

              <div
                className={`${className || "max-h-[100px] overflow-y-scroll"}`}
              >
                {filteredData().map((item) => {
                  const selected = selectedValues.includes(item.id);

                  return (
                    <button
                      key={item.id}
                      type="button"
                      className="py-2 px-2 flex items-center gap-x-3 w-full hover:bg-background rounded-md group"
                      onClick={() => onSelect(identifier, item.id)}
                    >
                      <Checkbox checked={selected} />
                      {customValue ? (
                        <div className={item?.className}>{item.value}</div>
                      ) : (
                        <p
                          className={`font-aileron font-semibold text-xs capitalize group-hover:text-black ${
                            selected ? "text-black" : "text-dark-gray"
                          }`}
                        >
                          {item.value}
                        </p>
                      )}
                    </button>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
      }
    >
      <button
        type="button"
        className={`px-3 py-[11px] h-10 flex items-center gap-x-3 rounded-md ${getHeaderStyle()}`}
      >
        <p className="font-poppins font-medium text-xs">{label}</p>

        {hasValues && (
          <div className="bg-white w-4.5 h-4.5 rounded-full">
            <p className="font-aileron font-semibold text-10 pt-[1.6px] text-black">
              {selectedValues.length}
            </p>
          </div>
        )}
        <ChevronIcon
          className={`w-4 h-4 transform transition-all duration-[400] ${
            hasValues ? "stroke-white" : "stroke-dark-gray"
          } ${open ? "-rotate-90" : "rotate-90"}`}
        />
      </button>
    </Popover>
  );
};

export default FilterItem;
