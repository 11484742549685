import React from "react";
import dayjs from "dayjs";
import { Drawer } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "../../Common/Svgs/CloseIcon";
import getRequestDetailColors from "./getRequestDetailColors";
import Detailistings from "./DetailListings";
import { REQUEST_STATUS } from "./constants";
import { updateRequest } from "../../Redux/Slices/requests.slice";
import getSessionTypeDetails from "./getSessionTypeDetails";
import { updateSession } from "../../Redux/Slices/sessions.slice";

const SessionDetailsDrawer = ({ visible, onCancel }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currentRequest } = useSelector((state) => state.requests);

  const { borderColor, bgColor } = getRequestDetailColors(currentRequest.type);

  const handleAcceptAndCreateSession = () => {
    const sessionPayload = {
      farmerId: null,
      farmerFirstName: "",
      farmerLastName: "",
      hubId: currentRequest?.metaData?.hubId,
      hubName: currentRequest?.hubName,
      activityDate: dayjs(currentRequest?.activityDate),
      activityType: getSessionTypeDetails(
        currentRequest?.metaData?.activity.toLowerCase(),
      ),
      timeSlot: currentRequest?.metaData?.timeSlot,
    };

    dispatch(
      updateSession({
        key: "currentSession",
        value: sessionPayload,
      }),
    );

    dispatch(
      updateSession({
        key: "sessionThroughUSSD",
        value: true,
      }),
    );

    dispatch(
      updateRequest({
        key: "requestDetailsVisible",
        value: false,
      }),
    );

    navigate("/sessions/create-session");
  };

  const getFooter = () => {
    return (
      <div className="absolute bottom-6 w-[91.5%] h-4 flex items-center gap-x-3">
        <button
          type="button"
          className={`flex-1 flex items-center justify-center py-3 h-[42px] bg-light-grey hover:bg-medium-grey rounded-md ${
            currentRequest.status === REQUEST_STATUS.PENDING
              ? "max-w-[153px]"
              : ""
          }`}
          onClick={onCancel}
        >
          <p className="font-poppins font-medium text-xs leading-[18px] text-black">
            Close
          </p>
        </button>

        {currentRequest.status === REQUEST_STATUS.PENDING && (
          <button
            type="button"
            className="flex-1 max-w-[153px] flex items-center justify-center py-3 h-[42px] bg-alert-red hover:bg-alert-red-hover rounded-md"
            onClick={() =>
              dispatch(
                updateRequest({
                  key: "rejectModalVisible",
                  value: true,
                }),
              )
            }
          >
            <p className="font-poppins font-medium text-xs leading-[18px] text-white">
              Reject
            </p>
          </button>
        )}

        {currentRequest.status === REQUEST_STATUS.PENDING && (
          <button
            type="button"
            className="flex-1 flex items-center justify-center py-3 h-[42px] bg-primary hover:bg-primary-50 rounded-md"
            onClick={handleAcceptAndCreateSession}
          >
            <p className="font-poppins font-medium text-xs leading-[18px] text-white">
              Accept & Create Session
            </p>
          </button>
        )}
      </div>
    );
  };

  const getBody = () => {
    if (+currentRequest.status === REQUEST_STATUS.ACCEPTED) {
      return (
        <div className="mt-6 py-4 px-6 bg-green-10 rounded-xl">
          <div className="max-w-max bg-background-white py-1 px-2 border border-green rounded-md">
            <p className="font-poppins font-medium text-xs leading-[18px] text-green">
              Accepted
            </p>
          </div>

          <div className="mt-4 flex">
            <Detailistings
              title="Accepted By"
              value={`${currentRequest?.updatedByFirstName} ${currentRequest?.updatedByLastName}`}
            />

            <Detailistings
              title="Date & Time"
              value={dayjs(currentRequest?.updatedAt).format(
                "MMM DD, YYYY, hh:mm A",
              )}
            />
          </div>
        </div>
      );
    }

    if (+currentRequest.status === REQUEST_STATUS.REJECTED) {
      return (
        <div className="mt-6 py-4 px-6 bg-alert-10 rounded-xl">
          <div className="max-w-max bg-background-white py-1 px-2 border border-alert-red rounded-md">
            <p className="font-poppins font-medium text-xs leading-[18px] text-alert-red">
              Rejected
            </p>
          </div>

          <div className="mt-4 flex">
            <Detailistings
              title="Rejected By"
              value={`${currentRequest?.updatedByFirstName} ${currentRequest?.updatedByLastName}`}
            />
            <Detailistings
              title="Date & Time"
              value={dayjs(currentRequest?.updatedAt).format(
                "MMM DD, YYYY, hh:mm A",
              )}
            />
          </div>

          <p className="mt-4 font-poppins font-normal text-xs leading-[18px] text-dark-gray">
            Reason
          </p>

          <div className="mt-1 p-2 bg-background-white rounded-md font-poppins font-medium text-sm text-black first-letter:capitalize">
            {currentRequest?.metaData?.reasonText}
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <Drawer
      width={560}
      placement="right"
      closable={false}
      maskStyle={{ background: "transparent" }}
      open={visible}
      onClose={onCancel}
      className="rounded-tl-xl"
    >
      <div className="p-6 w-full h-full bg-background-white rounded-tl-xl relative">
        <div className="flex items-center justify-between">
          <p className="font-poppins font-medium text-base text-black">
            Request Details
          </p>

          <CloseIcon
            className="w-5 h-5 cursor-pointer stroke-dark-grey-text"
            onClick={onCancel}
          />
        </div>

        <div
          className="mt-6 rounded-xl border py-4 px-6"
          style={{
            background: bgColor,
            borderColor,
          }}
        >
          <div
            className="py-1 px-2 h-[26px] max-w-max rounded-md"
            style={{
              background: borderColor,
            }}
          >
            <p className="font-poppins font-medium text-xs leading-[18px] text-background-white">
              Session
            </p>
          </div>

          <div className="mt-4 flex items-start gap-x-4">
            <Detailistings
              title="Requested By"
              value={currentRequest?.requestedBy}
            />
            {/* <Detailistings
              title="Contact No."
              value={currentRequest?.metaData?.mobileNumber}
            /> */}
            <Detailistings
              title="Hub Station"
              value={currentRequest?.hubName}
            />
          </div>

          <div className="mt-4 flex items-start gap-x-4">
            {/* <Detailistings
              title="Hub Station"
              value={currentRequest?.hubName}
            /> */}
            <Detailistings
              title="Session Type"
              value={currentRequest?.metaData?.activity}
            />
            <Detailistings
              title="Session Date"
              value={dayjs(currentRequest?.submittedAt).format("MMM DD, YYYY")}
            />
          </div>

          <div className="mt-4 flex items-start gap-x-4">
            {/* <Detailistings
              title="Session Date"
              value={dayjs(currentRequest?.submittedAt).format("MMM DD, YYYY")}
            /> */}
            <Detailistings
              title="Session Start Time Slot"
              value={currentRequest?.metaData?.timeSlot}
            />
          </div>
        </div>

        {getBody()}
        {getFooter()}
      </div>
    </Drawer>
  );
};

export default SessionDetailsDrawer;
