/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Col, Row } from "antd";
import Button from "../../Core/Components/CustomButton";
import CustomTags from "../../Core/Components/CustomTags";
import CloseIcon from "../../Common/Svgs/CloseIcon";
import { client } from "../../Utils/axiosClient";
import { homeKpis, mapMarkerHeaders } from "./Data/HomeData";
import { PERMISSIONS } from "../Login/constants";
import TrikeListings from "./TrikeListings";
import ChevronIcon from "../../Common/Svgs/ChevronIcon";

const ListingsAndFilterButtons = ({
  mapRef,
  setFilteredLocations,
  setLocationsLoading,
  showListingsAndKPI,
  setShowListingsAndKPI,
}) => {
  const navigate = useNavigate();

  const { userDetails } = useSelector((state) => state.loggedInUser);
  const { kpiData } = useSelector((state) => state.home);

  const [filterMarkers, setFilterMarkers] = useState([]);

  const handleFilters = (id) => {
    if (filterMarkers.includes(id)) {
      setFilterMarkers(filterMarkers?.filter((item) => item !== id));
    } else {
      setFilterMarkers([...filterMarkers, id]);
    }
  };

  const getPermissions = (module) => {
    let hasPermission = false;

    switch (module) {
      case "trikes":
        hasPermission =
          userDetails?.permissions?.includes(PERMISSIONS.TRIKE_VIEW) ||
          userDetails?.permissions?.includes(PERMISSIONS.TRIKE_VIEW_AND_EDIT);

        break;

      case "batteries":
        hasPermission =
          userDetails?.permissions?.includes(PERMISSIONS.BATTERY_VIEW) ||
          userDetails?.permissions?.includes(PERMISSIONS.BATTERY_VIEW_AND_EDIT);

        break;

      case "drivers":
        hasPermission =
          userDetails?.permissions?.includes(PERMISSIONS.DRIVER_VIEW) ||
          userDetails?.permissions?.includes(PERMISSIONS.DRIVER_VIEW_AND_EDIT);

        break;

      case "partners":
        hasPermission =
          userDetails?.permissions?.includes(PERMISSIONS.PARTNER_VIEW) ||
          userDetails?.permissions?.includes(PERMISSIONS.PARTNER_VIEW_AND_EDIT);

        break;

      default:
        break;
    }

    return hasPermission;
  };

  useEffect(() => {
    const fetchHomeFilters = async () => {
      try {
        setLocationsLoading(true);

        const response = await client.get(
          `${process.env.REACT_APP_API_URL}/home/home-filters`,
          {
            params: {
              filters: filterMarkers,
            },
          },
        );

        setFilteredLocations(response.data?.filteredLocations);
      } catch (err) {
        console.log(err);
      } finally {
        setLocationsLoading(false);
      }
    };

    fetchHomeFilters();
  }, [filterMarkers]);

  // return (
  //   <div className="">
  //     <div className="flex gap-x-3">
  //       {homeKpis(
  //         kpiData.trikes,
  //         kpiData.batteries,
  //         kpiData.drivers,
  //         kpiData.inventory,
  //       ).map((item) => {
  //         const hasPermission = getPermissions(item.text);

  //         return (
  //           <div
  //             role={hasPermission && "button"}
  //             key={item.id}
  //             className="p-3 w-full h-12 bg-white text-dark-grey-text shadow-button font-poppins text-xs font-medium capitalize rounded-md"
  //             onClick={() => {
  //               // user should be able to visit the page only if permission exists
  //               if (hasPermission) {
  //                 if (item.text === "drivers") {
  //                   navigate("/users");
  //                 } else {
  //                   navigate(`/${item.text}`);
  //                 }
  //               }
  //             }}
  //           >
  //             <div className="flex items-center justify-around w-full gap-x-4">
  //               <CustomTags colors={item.color} width="full" size={homeKpis}>
  //                 <div className="flex items-center justify-start gap-x-2">
  //                   {item.icon}
  //                   <p className="text-xs font-semibold capitalize font-aileron">
  //                     {item.text}
  //                   </p>
  //                 </div>
  //               </CustomTags>
  //               <div className="text-base text-black font-poppins">
  //                 {item.total}
  //               </div>
  //             </div>
  //           </div>
  //         );
  //       })}
  //     </div>

  //     <div className="flex mt-3 gap-x-3">
  //       {mapMarkerHeaders.map((item) => (
  //         <Button
  //           key={item.id}
  //           width="dynamic"
  //           size="maps"
  //           padding="mapMarkers"
  //           colors={filterMarkers.includes(item.id) ? "primary" : "white"}
  //           modifier="shadow"
  //           onClick={() => handleFilters(item.id)}
  //         >
  //           <div className="flex gap-x-2">
  //             <div>{item.title}</div>
  //             {filterMarkers.includes(item.id) ? (
  //               <CloseIcon className="w-4 h-4 stroke-white" />
  //             ) : null}
  //           </div>
  //         </Button>
  //       ))}
  //     </div>
  //   </div>
  // );

  return (
    <div className="">
      <div className="flex gap-x-3">
        {homeKpis(
          kpiData.trikes,
          kpiData.batteries,
          kpiData.drivers,
          kpiData.partners,
        ).map((item) => {
          const hasPermission = getPermissions(item.text);

          return (
            <div
              role={hasPermission && "button"}
              key={item.id}
              className="p-3 w-full h-12 bg-white text-dark-grey-text shadow-button font-poppins text-xs font-medium capitalize rounded-md"
              onClick={() => {
                // user should be able to visit the page only if permission exists
                if (hasPermission) {
                  navigate(item.path);
                }
              }}
            >
              <div className="flex items-center justify-around w-full gap-x-4">
                <CustomTags colors={item.color} width="full" size={homeKpis}>
                  <div className="flex items-center justify-start gap-x-2">
                    {item.icon}
                    <p className="text-xs font-semibold capitalize font-aileron">
                      {item.text}
                    </p>

                    <ChevronIcon className={`w-3 h-3 ${item.chevronColor}`} />
                  </div>
                </CustomTags>

                <div className="text-base text-black font-poppins">
                  {item.total}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <Row
        gutter={12}
        className="mt-3 w-full h-[calc(100vh-72px-24px-12px-92px-12px-12px-60px)]"
      >
        {showListingsAndKPI && (
          <Col span={6}>
            <TrikeListings mapRef={mapRef} />
          </Col>
        )}

        <Col span={showListingsAndKPI ? 18 : 24}>
          <div className="relative">
            <div className="flex gap-x-3">
              {mapMarkerHeaders.map((item) => (
                <Button
                  key={item.id}
                  width="dynamic"
                  size="maps"
                  padding="mapMarkers"
                  colors={filterMarkers.includes(item.id) ? "primary" : "white"}
                  modifier="shadow"
                  onClick={() => handleFilters(item.id)}
                >
                  <div className="flex gap-x-2">
                    <div>{item.title}</div>
                    {filterMarkers.includes(item.id) ? (
                      <CloseIcon className="w-4 h-4 stroke-white" />
                    ) : null}
                  </div>
                </Button>
              ))}
            </div>

            <div
              role="button"
              className="absolute top-0 right-[-12px] h-8 p-2 bg-white text-dark-grey-text shadow-button rounded-md"
              onClick={() => setShowListingsAndKPI((prev) => !prev)}
            >
              <img
                src={
                  showListingsAndKPI
                    ? "/assets/minimize.svg"
                    : "/assets/maximize.svg"
                }
                className="w-4 h-4"
                alt=""
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ListingsAndFilterButtons;
