/* eslint-disable no-console */
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { client } from "../../../Utils/axiosClient";
import { updateUsers } from "../../../Redux/Slices/users.slice";
import { updateHome } from "../../../Redux/Slices/home.slice";

const ItemBody13 = ({ notification }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleViewDetails = async () => {
    dispatch(updateHome({ key: "notificationPopupVisible", value: false }));

    try {
      dispatch(updateHome({ key: "loading", value: true }));

      const response = await client.get(
        `${process.env.REACT_APP_API_URL}/farmers/get-farmer-by-id`,
        {
          params: {
            farmerId: parseFloat(notification?.metaData?.farmerId),
          },
        },
      );

      dispatch(
        updateUsers({
          key: "approveFarmerDrawer",
          value: {
            visible: true,
            data: response.data.farmer,
          },
        }),
      );

      navigate("/users?tab=partners");
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(updateHome({ key: "loading", value: false }));
    }
  };

  return (
    <div>
      <p className="font-poppins text-xs font-light">
        New farmer was added by {notification?.metaData?.driverName} on the app.
        Do you want to add this farmer in the system?
      </p>

      <button
        type="button"
        className="mt-1 w-full bg-primary hover:bg-primary-50 py-2 px-4 rounded-md"
        onClick={handleViewDetails}
      >
        <p className="font-poppins text-xs font-medium text-background-white">
          View Details
        </p>
      </button>
    </div>
  );
};

export default ItemBody13;
