import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowIcon from "../../Common/Svgs/ArrowIcon";
import { updateRequest } from "../../Redux/Slices/requests.slice";
import PurchaseDetailsDrawer from "./PurchaseDetailsDrawer";
import SessionDetailsDrawer from "./SessionDetailsDrawer";
import TransportDetailsDrawer from "./TransportDetails/TransportDetailsDrawer";

export const RequestDetailCTA = ({ request }) => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(
      updateRequest({
        key: "currentRequest",
        value: request,
      }),
    );

    dispatch(
      updateRequest({
        key: "requestDetailsVisible",
        value: true,
      }),
    );
  };

  return (
    <button
      type="button"
      className="w-8 h-6 bg-green-10 rounded-md py-1 px-2"
      onClick={onClick}
    >
      <div className="rotate-90">
        <ArrowIcon className="rotate-90 text-green" />
      </div>
    </button>
  );
};

const RequestDetailsDrawer = ({
  visible,
  onCancel,
  currentPage,
  setCurrentPage,
}) => {
  const { currentRequest } = useSelector((state) => state.requests);

  if (!visible) return null;

  if (currentRequest.type === "taas") {
    return (
      <TransportDetailsDrawer
        key="taas"
        visible={visible}
        onCancel={onCancel}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    );
  }

  if (currentRequest.type === "purchase-feed") {
    return (
      <PurchaseDetailsDrawer
        key="purchase-feed"
        visible={visible}
        onCancel={onCancel}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    );
  }

  if (currentRequest.type === "power-africa") {
    return (
      <SessionDetailsDrawer
        key="power-africa"
        visible={visible}
        onCancel={onCancel}
      />
    );
  }

  return null;
};

export default RequestDetailsDrawer;
