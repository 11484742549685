/* eslint-disable no-console */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "antd";
import { updateRevenue } from "../../../Redux/Slices/revenue.slice";
import UpdateStatus from "../UpdateStatus";
import { PAYMENT_STATUS } from "../data";
import {
  getCollectionKPI,
  getCollectionList,
  updatePaymentStatus,
} from "../../../Redux/APIs/revenueAPI";
import useThreeDotsOptions from "./useThreeDotsOption";

const LOCAL_DEFAULT_LIMIT = 10;

const ListDropdown = ({ detail }) => {
  const dispatch = useDispatch();

  const {
    collectionDetailsPopoverId,
    selectedCollectionDetailStatusId,
    collectionListCurrentPage,
  } = useSelector((state) => state.revenue);

  const [open, setOpen] = useState(false);

  const options = useThreeDotsOptions().filter((item) => item.hasPermission);

  const onOptionClick = async (optionId) => {
    // setOpen(false);

    switch (optionId) {
      case 1:
        dispatch(
          updateRevenue({
            key: "selectedCollectionDetailStatusId",
            value: detail.id,
          }),
        );
        break;

      default:
        break;
    }
  };

  const handleOpenChange = (state) => {
    if (!state) {
      dispatch(
        updateRevenue({
          key: "selectedCollectionDetailStatusId",
          value: 0,
        }),
      );
    }

    setOpen(state);

    dispatch(
      updateRevenue({
        key: "collectionDetailsPopoverId",
        value: detail.id,
      }),
    );
  };

  const handlePaymentStatus = async (newStatusId) => {
    if (detail.paymentStatus !== newStatusId) {
      await dispatch(
        updatePaymentStatus({
          paymentId: detail.id,
        }),
      );

      dispatch(
        getCollectionList({
          offset: ((collectionListCurrentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
          limit: (collectionListCurrentPage || 1) * LOCAL_DEFAULT_LIMIT,
        }),
      );

      dispatch(getCollectionKPI());
    }

    setOpen(false);
    dispatch(
      updateRevenue({
        key: "selectedCollectionDetailStatusId",
        value: 0,
      }),
    );
  };

  if (detail.paymentStatus === PAYMENT_STATUS.COMPLETED) {
    return <div />;
  }

  if (!options.length) {
    return <div />;
  }

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      open={open && collectionDetailsPopoverId === detail.id}
      arrow={false}
      onOpenChange={(state) => handleOpenChange(state)}
      content={
        <div
          className="rounded-md bg-background-white py-1 relative"
          style={{
            width: "156px",
            boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          {options.map((option) => (
            <button
              type="button"
              className="w-full flex items-center gap-x-3 px-3 py-2 group hover:bg-background"
              key={option.id}
              onClick={() => onOptionClick(option.id)}
            >
              {option.icon}
              <p className="font-aileron text-xs font-semibold capitalize text-dark-gray group-hover:text-black">
                {option.value}
              </p>
            </button>
          ))}

          {detail.id === selectedCollectionDetailStatusId && (
            <div className="mt-2 absolute">
              <UpdateStatus
                title="Collection Status"
                current={detail.paymentStatus}
                onClick={handlePaymentStatus}
              />
            </div>
          )}
        </div>
      }
    >
      <div className="cursor-pointer">
        <img src="/assets/icons/trips/more.svg" alt="dropdown" />
      </div>
    </Popover>
  );
};

export default ListDropdown;
