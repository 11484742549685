const getRequestDetailColors = (type) => {
  let borderColor = "";
  let bgColor = "";

  if (type === "taas") {
    borderColor = "#775DFA";
    bgColor = "#F5F3FF";
  }

  if (type === "purchase-feed") {
    borderColor = "#169757";
    bgColor = "#E8F5EE";
  }

  if (type === "power-africa") {
    borderColor = "#0892BD";
    bgColor = "#E6F4F8";
  }

  return { borderColor, bgColor };
};

export default getRequestDetailColors;
