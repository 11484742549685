import React from "react";

const MilkDeliveredIcon = ({ size = 16, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12.25 10v2.667c0 1.1-.9 2-2 2h-4c-1.1 0-2-.9-2-2V9.32l2.593.013c.487 0 .967.114 1.407.334S9.17 10 9.663 10z"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12.25 7.22V10H9.663c-.493 0-.973-.113-1.413-.333a3.14 3.14 0 0 0-1.407-.334L4.25 9.32v-2.1c0-.307.173-.727.393-.947L6.057 4.86a.67.67 0 0 0 .193-.467v-1.06h4v1.06c0 .174.073.347.193.467l1.414 1.413c.22.22.393.64.393.947M12.25 10.667V10M4.25 9.987V9.32M9.917 3.333H6.584c-.553 0-1-.453-1-1s.447-1 1-1h3.333c.554 0 1 .454 1 1 0 .547-.446 1-1 1"
        />
      </svg>
    </span>
  );
};

export default MilkDeliveredIcon;
