import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Popover } from "antd";
import Button from "../../Core/Components/CustomButton";
import useHeaderItems from "./useHeaderItems";
import usePermission from "../../Core/hooks/usePermission";
import { PERMISSIONS } from "../Login/constants";
import CustomTooltip from "../../Core/Components/CustomTooltip";
import ChevronIcon from "../../Common/Svgs/ChevronIcon";
import {
  MillerIcon,
  ChaufCutterIcon,
  ThresherIcon,
  WaterPumpIcon,
} from "../../Common/Svgs/PowerAfricaIcon";
import TillerIcon from "../../Common/Svgs/TillerIcon";

const options = [
  {
    id: 1,
    type: "tiller",
    label: "Tiller",
    icon: <TillerIcon className="text-dark-gray" />,
  },
  {
    id: 2,
    type: "miller",
    label: "Miller",
    icon: <MillerIcon className="text-dark-gray" />,
  },
  {
    id: 3,
    type: "thresher",
    label: "Thresher",
    icon: <ThresherIcon className="text-dark-gray" />,
  },
  {
    id: 4,
    type: "water-pump",
    label: "Water Pump",
    icon: <WaterPumpIcon className="text-dark-gray" />,
  },
  {
    id: 5,
    type: "chauf-cutter",
    label: "Chauf Cutter",
    icon: <ChaufCutterIcon className="text-dark-gray" />,
  },
];

const Buttons = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const selectedTab = searchParams.get("tab");

  const hasTrikeAddPermission = usePermission([PERMISSIONS.TRIKE_ADD]);

  const hasPowerAfricaAssetAddPermission = usePermission([
    PERMISSIONS.POWER_AFRICA_ASSET_ADD,
  ]);

  // checking permissions for trike checklist (add, edit and delete)
  const hasTrikeChecklistPermission = usePermission([
    PERMISSIONS.TRIKE_CHECKLIST_ADD,
    PERMISSIONS.TRIKE_CHECKLIST_EDIT,
    PERMISSIONS.TRIKE_CHECKLIST_DELETE,
  ]);

  // checking permissions for power africa checklist (add, edit and delete)
  const hasPowerAfricaAssetChecklistPermission = usePermission([
    PERMISSIONS.POWER_AFRICA_ASSET_CHECKLIST_ADD,
    PERMISSIONS.POWER_AFRICA_ASSET_CHECKLIST_EDIT,
    PERMISSIONS.POWER_AFRICA_ASSET_CHECKLIST_DELETE,
  ]);

  if (selectedTab === "trikes") {
    return (
      <div className="flex items-center gap-x-3">
        {hasTrikeChecklistPermission && (
          <CustomTooltip tooltipTitle="Checklist">
            <button
              type="button"
              className="bg-yellow hover:bg-yellow-dark p-3 w-10 h-10 rounded-md"
              onClick={() => navigate("/create-checklist/trike")}
            >
              <img
                src="/assets/icons/checklist.svg"
                className="w-4 h-4"
                alt="Checklist"
              />
            </button>
          </CustomTooltip>
        )}

        {hasTrikeAddPermission && (
          <button
            type="button"
            className="px-4 py-[11px] bg-primary hover:bg-primary-50 rounded-md"
            onClick={() => navigate("/assets/add-trike")}
          >
            <div className="flex item-center gap-x-2">
              <img src="/assets/icons/add.svg" alt="" />
              <p className="font-poppins text-xs leading-4.5 font-medium text-background-white">
                Add Trike
              </p>
            </div>
          </button>
        )}
      </div>
    );
  }

  return (
    <div className="flex items-center gap-x-3">
      {hasPowerAfricaAssetChecklistPermission && (
        <Popover
          placement="bottom"
          trigger="click"
          arrow={false}
          content={
            <div className="w-[180px] bg-background-white p-1 rounded-md">
              {options.map((item) => (
                <div
                  role="button"
                  className="flex items-center gap-x-3 py-2 px-3 rounded-md hover:bg-background group"
                  onClick={() => navigate(`/create-checklist/${item.type}`)}
                >
                  {item?.icon}

                  <p className="font-aileron font-semibold text-xs leading-[14.5px] text-dark-gray group-hover:text-black">
                    {item.label}
                  </p>
                </div>
              ))}
            </div>
          }
        >
          <button
            type="button"
            className="bg-yellow hover:bg-yellow-dark py-[11px] px-4 h-10 flex items-center gap-x-2 rounded-md"
          >
            <p className="font-poppins font-medium text-xs leading-[18px] text-black">
              Checklist
            </p>
            <ChevronIcon className="w-4 h-4 rotate-90 stroke-black" />
          </button>
        </Popover>
      )}

      {hasPowerAfricaAssetAddPermission && (
        <button
          type="button"
          className="px-4 py-[11px] bg-primary hover:bg-primary-50 rounded-md"
          onClick={() => navigate("/assets/add-power-africa-asset")}
        >
          <div className="flex item-center gap-x-2">
            <img src="/assets/icons/add.svg" alt="" />
            <p className="font-poppins text-xs leading-4.5 font-medium text-background-white">
              Add Asset
            </p>
          </div>
        </button>
      )}
    </div>
  );
};

const Header = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const headerButtons = useHeaderItems().filter((item) => item.hasPermission);

  const selectedTab = searchParams.get("tab");

  const onTabClick = (item) => {
    searchParams.set("tab", item.text.toLowerCase());
    setSearchParams(searchParams);
  };

  return (
    <div className="px-6 py-3.5 flex items-center justify-between border-b bg-background-white">
      <div className="flex items-center gap-x-6">
        {headerButtons.map((item) => {
          const selected = selectedTab === item.text.toLowerCase();

          return (
            <Button
              key={item.id}
              colors={selected ? "primary" : "white"}
              width={item.width}
              padding={item.padding}
              modifier="shadow"
              onClick={() => onTabClick(item)}
            >
              <div>{item.text}</div>
            </Button>
          );
        })}
      </div>

      <Buttons />
    </div>
  );
};

export default Header;
