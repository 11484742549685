import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "antd";
import { updateBatteries } from "../../../Redux/Slices/batteries.slice";
import { BATTERY_STATUS } from "../Data";
import useBatteryThreeDotsOptions from "./useBatteryThreeDotsOptions";
import { updateQrCode } from "../../../Redux/Slices/qrcode.slice";
import { QR_CODE_TYPE } from "../../QRCode/data";
// import { ITEM_STATUS } from "../../Login/constants";
import {
  retireBattery,
  unRetireBattery,
} from "../../../Redux/APIs/batteriesAPI";

const ListDropdown = ({ detail }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const { threeDotsPopoverId } = useSelector((state) => state.batteries);

  const [open, setOpen] = useState(false);

  const threeDotsOptions = useBatteryThreeDotsOptions().filter(
    (item) => item.hasPermission,
  );

  // const getItems = () => {
  //   let options = threeDotsOptions;

  //   // if retired, do not show the retire option
  //   if (detail.isActive === ITEM_STATUS.RETIRED) {
  //     options = options.filter((item) => item.id !== 5);
  //   }

  //   // if active, do not show the activate option
  //   if (detail.isActive === ITEM_STATUS.ACTIVE) {
  //     options = options.filter((item) => item.id !== 6);
  //   }

  //   return options;
  // };

  const getCustomOptionValue = (id) => {
    if (id === 3 && +detail.status === BATTERY_STATUS.SERVICE)
      return " View Service";
    return "Add to Service";
  };

  const onOptionClick = async (optionId) => {
    dispatch(
      updateBatteries({
        key: "currentBattery",
        value: detail,
      }),
    );

    setOpen(false);

    switch (optionId) {
      case 1:
        dispatch(
          updateBatteries({
            key: "currentBattery",
            value: detail,
          }),
        );
        navigate(`/batteries/${detail.registrationNo}`);

        break;
      case 2:
        navigate(`/edit-battery/${detail.registrationNo}`);
        break;
      case 3:
        if (detail.status === BATTERY_STATUS.SERVICE) {
          navigate("/service");
        } else {
          navigate(`/add-to-service/battery/${detail.registrationNo}`);
        }
        break;
      case 4:
        dispatch(
          updateQrCode({
            key: "viewModal",
            value: {
              visible: true,
              data: {
                assetId: detail.registrationNo,
                assetType: QR_CODE_TYPE.BATTERY,
                metaData: {
                  batteryId: detail.id,
                },
              },
            },
          }),
        );
        break;
      case 5:
        await dispatch(retireBattery({ batteryId: detail.id }));
        break;
      case 6:
        await dispatch(unRetireBattery({ batteryId: detail.id }));
        break;

      case 7:
        dispatch(
          updateBatteries({
            key: "deleteBatteryModal",
            value: true,
          }),
        );
        break;

      default:
        break;
    }
  };

  const handleOpenChange = (state) => {
    setOpen(state);

    dispatch(
      updateBatteries({
        key: "threeDotsPopoverId",
        value: detail.id,
      }),
    );
  };

  if (!userDetails.hubs.includes(detail.permanentHubId)) {
    return <div />;
  }

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      open={open && threeDotsPopoverId === detail.id}
      arrow={false}
      onOpenChange={(state) => handleOpenChange(state)}
      content={
        <div
          className="rounded-md bg-background-white py-1"
          style={{
            width: "156px",
            boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          {threeDotsOptions.map((option) => (
            <button
              type="button"
              className={`w-full flex items-center gap-x-3 px-3 py-2 group 
              ${
                option.id === 7
                  ? "hover:bg-light-alert-red-hover"
                  : "hover:bg-background"
              }
              `}
              key={option.id}
              onClick={() => onOptionClick(option.id)}
            >
              {option.icon}
              <p
                className={`font-aileron text-xs font-semibold capitalize ${
                  option.id === 7
                    ? "text-alert-red group-hover:text-alert-red-hover"
                    : "text-dark-gray group-hover:text-black"
                }`}
              >
                {option.id === 3
                  ? getCustomOptionValue(option.id)
                  : option.value}
              </p>
            </button>
          ))}
        </div>
      }
    >
      <div className="cursor-pointer">
        <img src="/assets/icons/trips/more.svg" alt="dropdown" />
      </div>
    </Popover>
  );
};

export default ListDropdown;
