/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomKPI from "../../../Core/Components/CustomKpi";
import { FinanceHoverIcon } from "../../../Common/Svgs/FinanceIcon";
import { collectionColumns, CollectionKPIData } from "./data";
import CollectionHeader from "./CollectionHeader";
import CustomTable from "../../../Core/Components/CustomTable";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import { updateRevenue } from "../../../Redux/Slices/revenue.slice";
import {
  getCollectionKPI,
  getCollectionList,
} from "../../../Redux/APIs/revenueAPI";
import useDebounce from "../../../Core/hooks/useDebounce";

const LOCAL_DEFAULT_LIMIT = 10;

const CollectionList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { collectionKPI, collectionList, collectionListCurrentPage } =
    useSelector((state) => state.revenue);

  const [search, setSearch] = useState("");
  // const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    hub: [],
    paymentStatus: [],
  });

  const debounceSearch = useDebounce(search, 500);

  const handleRowClick = (record) => {
    dispatch(
      updateRevenue({
        key: "selectedCollection",
        value: record,
      }),
    );

    navigate(`/revenue/collection-details/${record?.entityId}`);
  };

  // fetch the payment list on every page change
  useEffect(() => {
    const filterObj = {
      offset: ((collectionListCurrentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
      limit: (collectionListCurrentPage || 1) * LOCAL_DEFAULT_LIMIT,
    };

    if (debounceSearch) filterObj.searchQuery = debounceSearch;
    if (filters.hub.length) filterObj.hubIds = filters.hub;
    if (filters.paymentStatus.length)
      filterObj.paymentStatus = filters.paymentStatus;

    dispatch(getCollectionList(filterObj));
  }, [dispatch, collectionListCurrentPage, filters, debounceSearch]);

  useEffect(() => {
    dispatch(getCollectionKPI());
  }, []);

  return (
    <div>
      <div className="w-full flex items-center gap-x-6 p-6">
        {CollectionKPIData(collectionKPI).map((item) => (
          <CustomKPI
            key={item.title}
            title={item.title}
            count={item.count}
            countColor={item.countColor}
            background={item.background}
            image={item.icon}
            unit="KSH"
          />
        ))}
      </div>

      <div>
        <CollectionHeader
          search={search}
          setSearch={setSearch}
          filters={filters}
          setFilters={setFilters}
          // setCurrentPage={setCurrentPage}
        />

        <div
          className={`h-[calc(100vh-72px-71px-162px-69px-40px)] ${
            collectionList.result.length && "overflow-y-scroll"
          }`}
        >
          <CustomTable
            // scrollX={0}
            // scrollY="518px"
            disableScroll
            columns={collectionColumns}
            data={collectionList.result}
            emptyState={
              <FinanceHoverIcon size={16} className="text-dark-grey-text" />
            }
            onRowClick={handleRowClick}
            rowClassName="group cursor-pointer"
            pagination={{
              pageSize: LOCAL_DEFAULT_LIMIT,
              total: collectionList.totalRecords,
              showSizeChanger: false,
              onChange: (page) => {
                //
                dispatch(
                  updateRevenue({
                    key: "collectionListCurrentPage",
                    value: page,
                  }),
                );
              },
              current: collectionListCurrentPage,
              itemRender: (current, type, originalElement) => {
                const lastPage = collectionList.totalRecords / current;

                if (type === "prev" && current === 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{
                        pointerEvents: "none",
                      }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "prev" && current > 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next" && lastPage <= 10) {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{ pointerEvents: "none" }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next") {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                return originalElement;
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CollectionList;
