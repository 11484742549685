import React from "react";

export const getRequestTypeByName = (type) => {
  switch (type) {
    case "taas":
      return (
        <div className="h-[26px] w-[75px] py-1 px-2 bg-purple-10 rounded-md">
          <p className="font-poppins font-medium text-xs leading-[18px] text-purple">
            Transport
          </p>
        </div>
      );

    case "purchase-feed":
      return (
        <div className="h-[26px] w-[73px] py-1 px-2 bg-[#E8F5EE] rounded-md">
          <p className="font-poppins font-medium text-xs leading-[18px] text-[#169757]">
            Purchase
          </p>
        </div>
      );

    case "power-africa":
      return (
        <div className="h-[26px] w-[63px] py-1 px-2 bg-[#E6F4F8] rounded-md">
          <p className="font-poppins font-medium text-xs leading-[18px] text-light-blue">
            Session
          </p>
        </div>
      );

    default:
      return (
        <div className="h-[26px] w-[75px] py-1 px-2 bg-purple-10 rounded-md">
          <p className="font-poppins font-medium text-xs leading-[18px] text-purple">
            Transport
          </p>
        </div>
      );
  }
};
