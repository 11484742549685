export const POWER_AFRICA_ASSETS = {
  TILLER: 1,
  MILLER: 2,
  WATER_PUMP: 3,
  THRESHER: 4,
  CHAUF_CUTTER: 5,
};

export const POWER_AFRICA_ASSET_STATUS = {
  IN_SESSION: 1,
  HUB_STATION: 2,
  SERVICE: 3,
};
