/* eslint-disable no-console */
import React, { useState } from "react";
import { Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import { CustomInputWithController } from "../../../Core/Components/CustomInput";
import { PopoverDropdown } from "../../../Core/Components/CustomDropdown";
import { useLocations } from "./useApproval";
import { updateUsers } from "../../../Redux/Slices/users.slice";
import { editFarmerV3, getFarmersV3 } from "../../../Redux/APIs/usersAPI";
import { client } from "../../../Utils/axiosClient";

const LOCAL_DEFAULT_LIMIT = 10;

const LabelAndInput = ({
  label,
  control,
  formState,
  name,
  placeholder,
  rules,
  focus,
  setFocus,
}) => {
  return (
    <div className="">
      <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
        {label}
      </p>
      <CustomInputWithController
        intent="sessionReject"
        control={control}
        formState={formState}
        focus={focus}
        setFocus={(e) => setFocus(e)}
        name={name}
        placeholder={placeholder}
        rules={rules}
      />
    </div>
  );
};

const FarmerApprovalDrawer = ({
  visible,
  onCancel,
  currentPage,
  setCurrentPage,
}) => {
  const dispatch = useDispatch();

  const { approveFarmerDrawer } = useSelector((state) => state.users);

  const {
    control,
    formState,
    setValue,
    clearErrors,
    watch,
    handleSubmit,
    setError,
  } = useForm({
    defaultValues: {
      firstName: approveFarmerDrawer?.data?.firstName || "",
      lastName: approveFarmerDrawer?.data?.lastName || "",
      contactNo: approveFarmerDrawer?.data?.contactNo || "",
      lat: approveFarmerDrawer?.data?.farms?.[0]?.lat || "",
      long: approveFarmerDrawer?.data?.farms?.[0]?.long || "",
      hubId: approveFarmerDrawer?.data?.hubId || "",
      hubName: approveFarmerDrawer?.data?.hubName || "",
      collectionPointId: approveFarmerDrawer?.data?.locationId || "",
      collectionPointName: approveFarmerDrawer?.data?.locationName || "",
    },
  });

  const [focus, setFocus] = useState({
    firstName: false,
    lastName: false,
    contactNo: false,
    lat: false,
    long: false,
  });

  // hub and location list
  // const hubList = useHubs();
  const locationList = useLocations(watch);

  // error states
  // const hubNameError = formState?.errors?.hubName?.message;
  const collectionPointNameError =
    formState?.errors?.collectionPointName?.message;

  // refetching all the farmers
  const refetchRecords = async () => {
    const response = await dispatch(
      getFarmersV3({
        offset: ((currentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
        limit: LOCAL_DEFAULT_LIMIT,
      }),
    );

    // If no records exist for the current page, move to the previous page
    if (!response.payload.result.length && currentPage > 1) {
      const previousPage = currentPage - 1;
      setCurrentPage(previousPage);

      await dispatch(
        getFarmersV3({
          offset: (previousPage - 1) * LOCAL_DEFAULT_LIMIT,
          limit: LOCAL_DEFAULT_LIMIT,
        }),
      );
    }
  };

  const onReject = async () => {
    try {
      dispatch(
        updateUsers({
          key: "loading",
          value: true,
        }),
      );

      await client.post(
        `${process.env.REACT_APP_API_URL}/farmers/reject-farmer`,
        {
          farmerId: approveFarmerDrawer?.data?.id,
        },
      );

      await refetchRecords();

      dispatch(
        updateUsers({
          key: "showToastMessage",
          value: {
            visible: true,
            data: {
              title: "Farmer Rejected",
              description: `${
                approveFarmerDrawer?.data?.firstName
                  ? `${approveFarmerDrawer?.data?.firstName} ${approveFarmerDrawer?.data?.lastName}`
                  : "1 farmer"
              } was rejected sucessfully.`,
            },
          },
        }),
      );

      onCancel();
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(
        updateUsers({
          key: "loading",
          value: false,
        }),
      );
    }
  };

  const onApprove = async (e) => {
    const payload = {
      farmerId: approveFarmerDrawer?.data?.id,
      approvingFarmer: true,
      firstName: e.firstName,
      lastName: e.lastName,
      contactNumber: e.contactNo,
      collectionPointId: +e.collectionPointId,

      latLongArray: [
        {
          lat: e.lat,
          long: e.long,
        },
      ],
    };

    const editResponse = await dispatch(editFarmerV3(payload));

    if (
      editResponse?.payload?.response &&
      editResponse?.payload?.response?.status !== 200
    ) {
      setError("contactNo", {
        type: "server",
        shouldFocus: true,
        message: editResponse?.payload?.response?.data?.message,
      });
    } else {
      onCancel();

      await refetchRecords();

      dispatch(
        updateUsers({
          key: "showToastMessage",
          value: {
            visible: true,
            data: {
              title: "Farmer Approved",
              description: `${
                payload.firstName
                  ? `${payload.firstName} ${payload.lastName}`
                  : "1 farmer"
              } was added sucessfully.`,
            },
          },
        }),
      );
    }
  };

  return (
    <Drawer
      width={415}
      placement="right"
      closable={false}
      maskStyle={{ background: "transparent" }}
      open={visible}
      onClose={onCancel}
    >
      <div
        className="p-6 w-full h-full bg-background-white rounded-l-xl relative"
        style={{
          boxShadow: "-4px -4px 6px -2px rgba(16, 24, 40, 0.03)",
        }}
      >
        <div className="flex items-center justify-between">
          <p className="font-poppins font-medium text-base text-black">
            New Partner Details
          </p>

          <CloseIcon
            className="w-5 h-5 cursor-pointer stroke-dark-grey-text"
            onClick={onCancel}
          />
        </div>

        <div className="space-y-6 mt-6">
          <LabelAndInput
            label="First Name"
            control={control}
            formState={formState}
            name="firstName"
            placeholder="Enter first name"
            rules={{ required: "Required." }}
            focus={focus.firstName}
            setFocus={(state) => setFocus({ ...focus, firstName: state })}
          />

          <LabelAndInput
            label="Last Name"
            control={control}
            formState={formState}
            name="lastName"
            placeholder="Enter last name"
            rules={{ required: "Required." }}
            focus={focus.lastName}
            setFocus={(state) => setFocus({ ...focus, lastName: state })}
          />

          <LabelAndInput
            label="Contact Number"
            control={control}
            formState={formState}
            name="contactNo"
            placeholder="Enter contact no"
            rules={{ required: "Required." }}
            focus={focus.contactNo}
            setFocus={(state) => setFocus({ ...focus, contactNo: state })}
          />

          <div className="w-full">
            <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
              Latitude & Longitude of farm
            </p>

            <div className="flex items-center gap-x-2 w-full">
              <div className="flex-1">
                <CustomInputWithController
                  intent="sessionReject"
                  control={control}
                  formState={formState}
                  rules={{ required: "Required." }}
                  focus={focus.lat}
                  name="lat"
                  placeholder="Enter latitude"
                  setFocus={(state) => setFocus({ ...focus, lat: state })}
                />
              </div>

              <div className="flex-1">
                <CustomInputWithController
                  intent="sessionReject"
                  control={control}
                  formState={formState}
                  focus={focus.long}
                  setFocus={(state) => setFocus({ ...focus, long: state })}
                  name="long"
                  placeholder="Enter longitude"
                  rules={{ required: "Required." }}
                />
              </div>
            </div>
          </div>

          {/* <div className="w-full">
            <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
              Hub Station
            </p>
            <Controller
              name="hubName"
              rules={{ required: "Required." }}
              control={control}
              render={({ field }) => (
                <PopoverDropdown
                  {...field}
                  placeholder="Select hub station"
                  formState={formState}
                  options={hubList}
                  onSelect={(data) => {
                    setValue("hubId", data.id);
                    setValue("hubName", data.value.toLowerCase());
                    clearErrors("hubName");
                  }}
                />
              )}
            />

            <p
              className={`font-poppins text-xs font-light capitalize leading-4.5 ${
                hubNameError ? "text-alert-red visible mt-2" : "invisible"
              }`}
            >
              {hubNameError}
            </p>
          </div> */}

          <div className="w-full">
            <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
              Collection Point
            </p>
            <Controller
              name="collectionPointName"
              rules={{ required: "Required." }}
              control={control}
              render={({ field }) => (
                <PopoverDropdown
                  {...field}
                  placeholder="Select collection point"
                  className="max-h-[200px] overflow-y-scroll"
                  formState={formState}
                  options={locationList}
                  onSelect={(data) => {
                    setValue("collectionPointId", data.id);
                    setValue("colectionPointName", data.value.toLowerCase());
                    clearErrors("collectionPointName");
                  }}
                />
              )}
            />

            <p
              className={`font-poppins text-xs font-light capitalize leading-4.5 ${
                collectionPointNameError
                  ? "text-alert-red visible mt-2"
                  : "invisible"
              }`}
            >
              {collectionPointNameError}
            </p>
          </div>
        </div>

        <div className="absolute bottom-9 w-[88%] h-4 flex items-center self-end gap-x-3">
          <button
            type="button"
            className="w-[66px] flex items-center justify-center py-3 h-[42px] bg-light-grey hover:bg-medium-grey rounded-md"
            onClick={onCancel}
          >
            <p className="font-poppins font-medium text-xs leading-[18px] text-black">
              Close
            </p>
          </button>

          <button
            type="button"
            className="flex-1 flex items-center justify-center py-3 h-[42px] bg-alert-red hover:bg-alert-red-hover rounded-md"
            onClick={onReject}
          >
            <p className="font-poppins font-medium text-xs leading-[18px] text-white">
              Reject
            </p>
          </button>

          <button
            type="button"
            className="flex-1 flex items-center justify-center py-3 h-[42px] bg-primary hover:bg-primary-50 rounded-md"
            onClick={handleSubmit(onApprove)}
          >
            <p className="font-poppins font-medium text-xs leading-[18px] text-white">
              Approve & Add
            </p>
          </button>
        </div>
      </div>
    </Drawer>
  );
};

export default FarmerApprovalDrawer;
