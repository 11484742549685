/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { REQUEST_STATUS } from "../constants";
import { updateRequest } from "../../../Redux/Slices/requests.slice";
import { client } from "../../../Utils/axiosClient";
import { getRequestList } from "../../../Redux/APIs/requestsAPI";

const LOCAL_DEFAULT_LIMIT = 10;

const Footer = ({ onCancel, currentPage, setCurrentPage }) => {
  const dispatch = useDispatch();

  const { currentRequest, transportDetailStep, transportRequestAssigning } =
    useSelector((state) => state.requests);

  const refetchRecords = async () => {
    // close the drawer
    onCancel();

    // fetch the request list again
    const response = await dispatch(
      getRequestList({
        offset: ((currentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
      }),
    );

    // If no records exist for the current page, move to the previous page
    if (!response.payload.result.length && currentPage > 1) {
      const previousPage = currentPage - 1;
      setCurrentPage(previousPage);

      await dispatch(
        getRequestList({
          offset: (previousPage - 1) * LOCAL_DEFAULT_LIMIT,
        }),
      );
    }
  };

  const handleAcceptRequest = async () => {
    try {
      dispatch(
        updateRequest({
          key: "loading",
          value: true,
        }),
      );

      await client.post(
        `${process.env.REACT_APP_API_URL}/ussd/accept-request`,
        {
          requestId: +currentRequest.id,
          requestType: currentRequest?.type,
        },
      );

      refetchRecords();
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(
        updateRequest({
          key: "loading",
          value: false,
        }),
      );
    }
  };

  const handleAssigningTrikeAndBattery = async () => {
    const payload = {
      requestId: currentRequest.id,
      trikeId: transportRequestAssigning.trikeId,
      batteries: transportRequestAssigning.batteries.map((item) => +item.id),
    };

    console.log(payload, "check details");

    try {
      dispatch(
        updateRequest({
          key: "loading",
          value: true,
        }),
      );

      await client.post(
        `${process.env.REACT_APP_API_URL}/ussd/assign-trike-and-battery`,
        payload,
      );

      dispatch(updateRequest({ key: "transportDetailStep", value: 1 }));

      refetchRecords();
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(
        updateRequest({
          key: "loading",
          value: false,
        }),
      );
    }
  };

  return (
    <div className="absolute z-10 bottom-0 w-full p-6 bg-background-white flex items-center gap-x-3">
      <button
        type="button"
        className="flex-1 flex items-center justify-center py-3 h-[42px] bg-light-grey hover:bg-medium-grey rounded-md"
        onClick={onCancel}
      >
        <p className="font-poppins font-medium text-xs leading-[18px] text-black">
          Close
        </p>
      </button>

      {currentRequest.status === REQUEST_STATUS.PENDING && (
        <button
          type="button"
          className="flex-1 flex items-center justify-center py-3 h-[42px] bg-alert-red hover:bg-alert-red-hover rounded-md"
          onClick={() =>
            dispatch(
              updateRequest({
                key: "rejectModalVisible",
                value: true,
              }),
            )
          }
        >
          <p className="font-poppins font-medium text-xs leading-[18px] text-white">
            Reject
          </p>
        </button>
      )}

      {currentRequest.status === REQUEST_STATUS.PENDING && (
        <button
          type="button"
          className="flex-1 flex items-center justify-center py-3 h-[42px] bg-primary hover:bg-primary-50 rounded-md"
          onClick={handleAcceptRequest}
        >
          <p className="font-poppins font-medium text-xs leading-[18px] text-white">
            Accept
          </p>
        </button>
      )}

      {currentRequest.status === REQUEST_STATUS.ASSIGN_TRIKE &&
        transportDetailStep === 2 && (
          <button
            type="button"
            className="flex-1 flex items-center justify-center py-3 h-[42px] bg-primary hover:bg-primary-50 rounded-md"
            onClick={() =>
              dispatch(updateRequest({ key: "transportDetailStep", value: 3 }))
            }
          >
            <p className="font-poppins font-medium text-xs leading-[18px] text-white">
              Next
            </p>
          </button>
        )}

      {currentRequest.status === REQUEST_STATUS.ASSIGN_TRIKE &&
        transportDetailStep === 3 && (
          <button
            type="button"
            className="flex-1 flex items-center justify-center py-3 h-[42px] bg-primary hover:bg-primary-50 rounded-md"
            onClick={handleAssigningTrikeAndBattery}
          >
            <p className="font-poppins font-medium text-xs leading-[18px] text-white">
              Assign and Accept
            </p>
          </button>
        )}
    </div>
  );
};

export default Footer;
