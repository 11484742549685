/* eslint-disable operator-assignment */
import React from "react";
import dayjs from "dayjs";
import { getPaymentDetailStatus } from "../getPaymentStatus";
import ListDropdown from "./ListDropdown";
import { PAYMENT_STATUS } from "../data";
import {
  AmountCommodityColumn,
  CommodityColumn,
  RateColumn,
  sortByTotalAmount,
  TotalAmountColumn,
  WeightCollectedColumn,
} from "./CustomColumnRender";

export const statusOptions = [
  {
    id: PAYMENT_STATUS.PENDING,
    value: getPaymentDetailStatus(PAYMENT_STATUS.PENDING),
  },
  {
    id: PAYMENT_STATUS.COMPLETED,
    value: getPaymentDetailStatus(PAYMENT_STATUS.COMPLETED),
  },
];

export const paymentDetailColumns = [
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Date
      </div>
    ),
    dataIndex: "submittedAt",
    sorter: (a, b) => a.submittedAt.localeCompare(b.submittedAt),
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {text ? dayjs(text).format("DD-MM-YYYY") : "--"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Trip ID
      </div>
    ),
    dataIndex: "tripId",
    sorter: (a, b) => a.tripId - b.tripId,
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Commodity
      </div>
    ),
    dataIndex: "tripId",
    render: (text, record) => <CommodityColumn payment={record} />,
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Total Weight
      </div>
    ),
    dataIndex: "tripId",
    render: (text, record) => <WeightCollectedColumn payment={record} />,
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Rate
      </div>
    ),
    dataIndex: "tripId",
    render: (text, record) => <RateColumn payment={record} />,
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Amount/Commodity
      </div>
    ),
    dataIndex: "tripId",
    render: (text, record) => <AmountCommodityColumn payment={record} />,
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Total Amount
      </div>
    ),
    dataIndex: "tripId",
    sorter: (a, b) => sortByTotalAmount(a, b),
    render: (text, record) => <TotalAmountColumn payment={record} />,
  },
  // {
  //   title: () => (
  //     <div className="font-normal font-aileron text-10 text-dark-gray">
  //       Status
  //     </div>
  //   ),
  //   dataIndex: "paymentStatus",
  //   sorter: (a, b) => a.paymentStatus - b.paymentStatus,
  //   render: (text, record) => (
  //     <div>
  //       <div>{getPaymentDetailStatus(+text)}</div>
  //       <p className="mt-1 text-xs font-aileron font-semibold text-dark-gray">
  //         {record.paymentTimestamp
  //           ? dayjs(record.paymentTimestamp).format("DD-MM-YYYY")
  //           : ""}
  //       </p>
  //     </div>
  //   ),
  // },
  {
    dataIndex: "id",
    width: "80px",
    render: (text, record) => (
      <button
        type="button"
        onClick={(event) => {
          event.stopPropagation();
        }}
        className="w-full flex items-center justify-center invisible group-hover:visible"
      >
        <ListDropdown detail={record} />
      </button>
    ),
  },
];

export const paymentTransactionColumns = [
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Date & Time
      </div>
    ),
    dataIndex: "submittedAt",
    sorter: (a, b) => a.submittedAt.localCompare(b.submittedAt),
    render: (text) => (
      <div>
        <p className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
          {dayjs(text).format("DD-MM-YYYY")}
        </p>
        <p className="text-10 font-normal font-aileron leading-3 text-dark-gray">
          {dayjs(text).format("hh:mm:ss")}
        </p>
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Amount
      </div>
    ),
    dataIndex: "amount",
    sorter: (a, b) => a.amount - b.amount,
    render: (text) => (
      <p className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {text} KSH
      </p>
    ),
  },
];

export const paymentTransactionData = [
  {
    id: 1,
    amount: 300,
    submittedAt: dayjs().subtract(5, "day"),
  },
  {
    id: 2,
    amount: 300,
    submittedAt: dayjs().subtract(3, "day"),
  },
  {
    id: 3,
    amount: 300,
    submittedAt: dayjs().subtract(2, "day"),
  },
  {
    id: 4,
    amount: 300,
    submittedAt: dayjs().subtract(1, "day"),
  },
  {
    id: 5,
    amount: 300,
    submittedAt: dayjs(),
  },
];
