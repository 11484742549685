/* eslint-disable no-console */
/* eslint-disable no-plusplus */
import React, { useCallback, useEffect, useRef, useState } from "react";
import * as ReactDOMServer from "react-dom/server";
import L from "leaflet";
import {
  MapContainer,
  Marker,
  Polyline,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import { MAX_BOUNDS } from "../../Home/Data/HomeData";
import checkSameCoordinates from "./checkSameCoordinates";
import convertLatLongToMeter from "./convertLatLongToMeter";

const lineOptions = {
  color: "#085054",
  fillColor: "#085054",
  fillOpacity: 0.5,
};

const renderCustomIcon = () => {
  // eslint-disable-next-line new-cap
  return new L.divIcon({
    html: ReactDOMServer.renderToString(
      <div className="absolute w-5 h-5 rounded-full bg-primary top-[-8px] left-[-4px]" />,
    ),
  });
};

const MeasureLandScreen = () => {
  const [allowMapping, setAllowMapping] = useState(true);
  const [center, setCenter] = useState([-0.6803741, 34.7569482]);
  const [boundary, setBoundary] = useState([]);
  const [polygonArea, setPolygonArea] = useState(0);

  const mapRef = useRef(null);

  const getPolygonArea = useCallback(() => {
    const referenceLat = boundary[0][0];
    let area = 0;

    const convertedBoundary = boundary.map((point) =>
      convertLatLongToMeter(point, referenceLat),
    );

    const n = convertedBoundary.length;

    for (let i = 0; i < n; i++) {
      const [x1, y1] = convertedBoundary[i];
      const [x2, y2] = convertedBoundary[(i + 1) % n];

      const crossProduct = x1 * y2 - x2 * y1;
      area += crossProduct;
    }

    area = Math.abs(area) / 2;

    setPolygonArea(area);
  }, [boundary]);

  const handleReset = () => {
    setBoundary([]);
    setPolygonArea(0);
    setAllowMapping(true);
  };

  const handleClick = (event) => {
    const { latlng } = event;
    const newBoundary = [...boundary, [latlng.lat, latlng.lng]];
    setBoundary(newBoundary);

    // if same co-ordinate, stop mapping and add the first point as the last point
    if (newBoundary.length > 3 && checkSameCoordinates(newBoundary)) {
      const first = boundary[0];
      setBoundary((prev) => [...prev, first]);
      setAllowMapping(false);
    } else {
      setBoundary(newBoundary);
    }
  };

  const MapClickHandler = () => {
    useMapEvents({
      click: allowMapping ? handleClick : null,
    });

    return null;
  };

  // to load the map correctly
  useEffect(() => {
    const timeout = setTimeout(() => {
      mapRef?.current?.invalidateSize(true);
    }, 200);

    return () => clearTimeout(timeout);
  });

  // to fetch the current co-ordinates of the user
  useEffect(() => {
    const getCoordinates = () => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position?.coords?.latitude;
          const long = position?.coords?.longitude;

          setCenter([lat, long]);
        },
        (error) => {
          console.error("Error getting location:", error.message);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        },
      );
    };

    getCoordinates();
  }, []);

  // to find the total area
  useEffect(() => {
    if (!allowMapping) {
      getPolygonArea();
    }
  }, [allowMapping, getPolygonArea]);

  return (
    <div className="w-screen h-screen relative">
      <div className="absolute top-6 z-40 flex items-center justify-between px-[27px]">
        {polygonArea > 0 ? (
          <p className="flex-1 font-poppins text-sm text-black font-semibold">
            Total Area: {parseFloat(polygonArea).toFixed(2)} m<sup>2</sup>
          </p>
        ) : (
          <div className="flex-1" />
        )}

        <button
          type="button"
          className="px-4 py-[11px] bg-primary rounded-md"
          onClick={handleReset}
        >
          <p className="font-poppins text-xs leading-4.5 font-medium text-background-white">
            Reset
          </p>
        </button>
      </div>

      <MapContainer
        center={center}
        zoom={20}
        maxBounds={MAX_BOUNDS}
        className="z-10 w-full h-full"
        zoomControl={false}
        ref={mapRef}
      >
        <MapClickHandler />
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

        {boundary.length > 1 && (
          <Polyline
            pathOptions={lineOptions}
            positions={boundary}
            weight={1.5}
          />
        )}

        {boundary.length > 0 &&
          boundary.map((item) => (
            <Marker key={`${item}`} position={item} icon={renderCustomIcon()} />
          ))}
      </MapContainer>
    </div>
  );
};

export default MeasureLandScreen;
