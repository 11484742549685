import { QR_CODE_TYPE } from "../QRCode/data";
import { POWER_AFRICA_ASSETS } from "./constants";

const getQrCodeType = (type) => {
  switch (type) {
    case POWER_AFRICA_ASSETS.TILLER:
      return QR_CODE_TYPE.TILLER;
    case POWER_AFRICA_ASSETS.MILLER:
      return QR_CODE_TYPE.MILLER;
    case POWER_AFRICA_ASSETS.WATER_PUMP:
      return QR_CODE_TYPE.WATER_PUMP;
    case POWER_AFRICA_ASSETS.THRESHER:
      return QR_CODE_TYPE.THRESHER;
    case POWER_AFRICA_ASSETS.CHAUF_CUTTER:
      return QR_CODE_TYPE.CHAUF_CUTTER;
    default:
      return QR_CODE_TYPE.TILLER;
  }
};

export default getQrCodeType;
