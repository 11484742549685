import React from "react";
import CustomRoundedImage from "../../Core/Components/CustomRoundedImage";
import PlusIcon from "../../Common/Svgs/PlusIcon";

const KPIContainer = ({ id, image, title, redirect, count, icon, onClick }) => {
  const getCountAndSuffix = () => {
    if (id === 1) {
      return (
        <div className="ml-[45px] flex items-center justify-between gap-x-1 mt-1.5 cursor-pointer">
          <div className="flex items-center">
            <p className="text-2xl font-semibold leading-9 text-black font-poppins">
              {count}
            </p>
          </div>

          <button
            type="button"
            className="flex items-center py-[5px] px-2 gap-x-2 rounded-md hover:bg-background"
            onClick={() => onClick()}
          >
            <PlusIcon className="w-4 h-4 stroke-green" />

            <p className="font-poppins font-medium text-xs leading-[18px] text-primary">
              View All
            </p>
          </button>
        </div>
      );
    }

    return (
      <div
        className={`ml-[45px] flex items-center justify-start max-w-max gap-x-1 mt-1.5 ${
          redirect && "cursor-pointer"
        }`}
        onClick={() => {
          if (redirect) onClick();
        }}
      >
        <div className="flex items-center">
          <p className="text-2xl font-semibold leading-9 text-black font-poppins">
            {count}
          </p>
        </div>

        {icon || null}
      </div>
    );
  };

  return (
    <div className="w-full h-full p-4 bg-background-white border-[0.5px] rounded-md border-light-grey">
      <div className="flex items-center justify-start gap-x-2">
        <CustomRoundedImage image={image} />

        <p className="font-normal text-xs leading-4.5 font-aileron text-dark-gray capitalize">
          {title}
        </p>
      </div>

      {getCountAndSuffix()}
    </div>
  );
};

export default KPIContainer;
