import React from "react";
import { useSelector } from "react-redux";
import BatteryBar from "./BatteryBar";
import getFormatedFloat from "../../../../Utils/getFormatedFloat";

const MappedBatteries = () => {
  const { currentAsset } = useSelector((state) => state.powerAfrica);

  const totalBatteries = currentAsset.batteryPack.length;

  const totalPercentage = () => {
    const sum = currentAsset.batteryPack.reduce((acc, current) => {
      acc += parseFloat(current.percentage);
      return acc;
    }, 0);

    const percentage = (sum / currentAsset.batteryPack.length).toFixed(2);
    return percentage ? getFormatedFloat(percentage) : 0;
  };

  const getWidth = () => {
    if (totalBatteries === 5) return "46px";
    if (totalBatteries === 6) return "34px";
    return "64px";
  };

  const getColor = (percentage) => {
    if (percentage > 0 && percentage <= 24) return "bg-[#F4DAD6]";
    if (percentage >= 25 && percentage <= 50) return "bg-[#F1872533]";
    return "bg-[#CEDCDD]";
  };

  return (
    <div className="w-full h-full my-4">
      <div className="absolute right-9 top-[52px]">
        <p className="text-xl font-poppins font-semibold">
          {totalPercentage()}%
        </p>
      </div>

      <div className="flex items-center justify-between">
        <p className="text-xs font-aileron font-normal text-dark-gray capitalize">
          Batteries
        </p>
      </div>

      <div className="flex items-start justify-between w-full h-full">
        <div className="pt-4.5 flex flex-1 items-center gap-x-1 w-[80%] h-full">
          {currentAsset.batteryPack.map((item, index) => {
            return (
              <div
                className="flex items-center flex-1 w-full h-full"
                key={item.id}
              >
                {/* individual battery and percentage */}
                <div className="w-full h-full flex-1 mr-1 relative">
                  <div
                    className={`flex items-center gap-x-1 absolute top-[-16px] z-20 ${
                      totalBatteries < 5 ? "left-5" : "left-2"
                    }`}
                  >
                    <p className="font-aileron text-xs font-semibold text-dark-gray text-center">
                      {item.percentage}%
                    </p>
                  </div>

                  <BatteryBar
                    width={getWidth()}
                    height="100%"
                    text={
                      item.percentage ? `${item.percentage}%` : `B${index + 1}`
                    }
                    current={item.percentage}
                    color={getColor(item.percentage)}
                    textColor="text-dark-gray font-normal text-xs"
                  />
                </div>

                {/* plus icon */}
                {index < currentAsset.batteryPack.length - 1 && (
                  <div className="w-4 h-4 flex-1 mx-auto">
                    <img
                      src="/assets/icons/details/add.svg"
                      alt=""
                      className="w-4 h-4 mx-auto"
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>

        {/* empty div for layout */}
        <div className="flex-1" />

        {/* arrow icon */}
        <div className="flex flex-1 h-full items-center justify-center">
          <img
            src="/assets/icons/details/arrow-full.svg"
            alt=""
            className="w-4 h-4 rotate-180 mx-auto"
          />
        </div>

        {/* empty div for layout */}
        <div className="flex-1" />

        {/* total percentage */}
        <div className="flex-1 w-full h-full flex justify-end">
          <BatteryBar
            width="64px"
            height="100%"
            color="bg-primary"
            current={totalPercentage()}
            text={`${totalPercentage()}%`}
            textColor="text-white font-normal text-base"
          />
        </div>
      </div>
    </div>
  );
};

export default MappedBatteries;
