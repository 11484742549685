import React from "react";
import AssetsIcon from "../../../Common/Svgs/AssetsIcon";
import TripsIcon from "../../../Common/Svgs/TripsIcon";
import MilkDeliveredIcon from "../../../Common/Svgs/MilkDeliveredIcon";
import SessionIcon from "../../../Common/Svgs/SessionIcon";

const getProductKPIDetails = (type) => {
  let title = "";
  let icon = null;
  let bgColor = "";
  let color = "";

  if (type === "assets") {
    title = "All Assets";
    icon = <AssetsIcon size={16} className="text-[#775DFA]" />;
    bgColor = "#F5F3FF";
    color = "#775DFA";
  }

  if (type === "trips") {
    title = "All Trips";
    icon = <TripsIcon size={16} className="text-[#0892BD]" />;
    bgColor = "#0892BD1A";
    color = "#0892BD";
  }

  if (type === "sessions") {
    title = "All Sessions";
    icon = <SessionIcon size={16} className="text-[#169757]" />;
    bgColor = "#E8F5EE";
    color = "#169757";
  }

  if (type === "milk") {
    title = "Total Milk Delivered";
    icon = <MilkDeliveredIcon size={16} className="text-[#135BBA]" />;
    bgColor = "#135BBA1A";
    color = "#135BBA";
  }

  return { title, icon, bgColor, color };
};

export default getProductKPIDetails;
