import React from "react";
import { SESSION_TYPE } from "./constants";

const getSessionType = (type) => {
  switch (type) {
    case SESSION_TYPE.TILLING:
      return (
        <div className="flex items-center max-w-max h-[26px] py-[5px] px-2 gap-x-1 bg-yellow-10 rounded-md">
          <img
            src="/assets/icons/power-africa/tiller.svg"
            className="w-4 h-4"
            alt="tiller"
          />
          <p className="font-poppins font-medium text-xs leading-[18px] text-yellow-dark">
            Tilling
          </p>
        </div>
      );

    case SESSION_TYPE.MILLING:
      return (
        <div className="flex items-center max-w-max h-[26px] py-[5px] px-2 gap-x-1 bg-green-10 rounded-md">
          <img
            src="/assets/icons/power-africa/miller.svg"
            className="w-4 h-4"
            alt="miller"
          />
          <p className="font-poppins font-medium text-xs leading-[18px] text-green">
            Milling
          </p>
        </div>
      );

    case SESSION_TYPE.WATER_PUMPING:
      return (
        <div className="flex items-center w-[130px] h-[26px] py-[5px] px-2 gap-x-1 bg-warning-bg rounded-md">
          <img
            src="/assets/icons/power-africa/water-pump.svg"
            className="w-4 h-4"
            alt="thresher"
          />
          <p className="font-poppins font-medium text-xs leading-[18px] text-warning whitespace-nowrap">
            Water Pumping
          </p>
        </div>
      );

    case SESSION_TYPE.THRESHING:
      return (
        <div className="flex items-center max-w-max h-[26px] py-[5px] px-2 gap-x-1 bg-alert-10 rounded-md">
          <img
            src="/assets/icons/power-africa/thresher.svg"
            className="w-4 h-4"
            alt="thresher"
          />
          <p className="font-poppins font-medium text-xs leading-[18px] text-alert-red">
            Threshing
          </p>
        </div>
      );

    case SESSION_TYPE.CHAUF_CUTTING:
      return (
        <div className="flex items-center max-w-max h-[26px] py-[5px] px-2 gap-x-1 bg-user-pink-10 rounded-md">
          <img
            src="/assets/icons/power-africa/chauf-cutter.svg"
            className="w-4 h-4"
            alt="thresher"
          />
          <p className="font-poppins font-medium text-xs leading-[18px] text-user-pink whitespace-nowrap">
            Chaff Cutting
          </p>
        </div>
      );

    default:
      return (
        <div className="flex items-center max-w-max h-[26px] py-[5px] px-2 gap-x-1 bg-user-pink-10 rounded-md">
          <img
            src="/assets/icons/power-africa/chauf-cutter.svg"
            className="w-4 h-4"
            alt="thresher"
          />
          <p className="font-poppins font-medium text-xs leading-[18px] text-user-pink whitespace-nowrap">
            Chaff Cutting
          </p>
        </div>
      );
  }
};

export default getSessionType;
