import React from "react";
import { POWER_AFRICA_ASSET_STATUS } from "./constants";

const getStatus = (status) => {
  switch (+status) {
    case POWER_AFRICA_ASSET_STATUS.IN_SESSION:
      return (
        <div className="py-1 px-2 bg-primary-10 rounded-md w-[77px]">
          <p className="font-poppins font-medium text-xs leading-[18px] text-primary">
            In Session
          </p>
        </div>
      );
    case POWER_AFRICA_ASSET_STATUS.HUB_STATION:
      return (
        <div className="py-1 px-2 bg-yellow-10 rounded-md w-[87px]">
          <p className="font-poppins font-medium text-xs leading-[18px] text-yellow-dark">
            Hub Station
          </p>
        </div>
      );
    case POWER_AFRICA_ASSET_STATUS.SERVICE:
      return (
        <div className="py-1 px-2 bg-light-grey rounded-md w-[60px]">
          <p className="font-poppins font-medium text-xs leading-[18px] text-black">
            Service
          </p>
        </div>
      );
    default:
      return (
        <div className="py-1 px-2 bg-primary-10 rounded-md w-[77px]">
          <p className="font-poppins font-medium text-xs leading-[18px] text-primary">
            In Session
          </p>
        </div>
      );
  }
};

export default getStatus;
