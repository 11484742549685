import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../../Core/Components/CustomInput";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import FilterItem from "../../../Core/Components/FilterItem";
import RangePicker from "../RangePicker";
import { statusOptions } from "./data";
import { updateRevenue } from "../../../Redux/Slices/revenue.slice";
import { PAYMENT_RECEIVER_TYPE } from "../data";
import getPaymentStatus from "../getPaymentStatus";

const PaymentDetailsHeader = ({
  search,
  setSearch,
  filters,
  setFilters,
  setCurrentPage,
}) => {
  const dispatch = useDispatch();

  const { selectedPayment } = useSelector((state) => state.revenue);

  const [focus, setFocus] = useState(false);
  const [dateRangeOpen, setDateRangeOpen] = useState(false);

  const onDateRangeSelect = (e) => {
    // clear the date range and close the the date range input box
    if (filters.dateRangeStart !== "" && filters.dateRangeEnd !== "") {
      setFilters((prev) => ({
        ...prev,
        dateRangeStart: "",
        dateRangeEnd: "",
      }));

      setDateRangeOpen(false);
    }
    // set the start date and end date
    else {
      const [startMonth, endMonth] = e;

      setFilters((prev) => ({
        ...prev,
        dateRangeStart: startMonth,
        dateRangeEnd: endMonth,
      }));
    }
  };

  const onSelect = (key, value) => {
    // whenever applying the filter, the page should be defaulted back to first page
    setCurrentPage(1);

    if (filters[key].includes(value)) {
      const newFilters = filters[key].filter((item) => item !== value);
      setFilters({ ...filters, [key]: newFilters });
    } else {
      setFilters({ ...filters, [key]: [...filters[key], value] });
    }
  };

  return (
    <div className="bg-background-white">
      <div className="px-6 py-2.5 flex items-center justify-between border-y-[0.5px] border-light-grey">
        <div className="flex items-center gap-x-3">
          <CustomInput
            intent="search"
            prefix={
              <img src="/assets/icons/search.svg" alt="" className="mr-2" />
            }
            value={search}
            focus={focus}
            border={focus ? "success" : "none"}
            placeholder={focus ? null : "Search Trip ID"}
            onChange={(e) => setSearch(e.target.value)}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            suffix={
              search.length > 0 ? (
                <CloseIcon
                  className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
                  onClick={() => {
                    setFocus(false);
                    setSearch("");
                  }}
                />
              ) : null
            }
          />

          <div className="flex items-center">
            <FilterItem
              label="Status"
              identifier="paymentStatus"
              customValue
              className="max-h-max"
              contentData={statusOptions}
              onSelect={onSelect}
              selectedValues={filters.paymentStatus}
            />

            <RangePicker
              dateRangeOpen={dateRangeOpen}
              setDateRangeOpen={setDateRangeOpen}
              onChange={onDateRangeSelect}
              startDate={filters.dateRangeStart}
              endDate={filters.dateRangeEnd}
            />
          </div>
        </div>

        {+selectedPayment.entityType === PAYMENT_RECEIVER_TYPE.FARMER && (
          <div className="flex items-center gap-x-3">
            <div className="max-w-max">
              {getPaymentStatus(+selectedPayment.paymentStatus)}
            </div>

            <button
              type="button"
              className="px-4 py-[11px] bg-primary hover:bg-primary-50 rounded-md"
              onClick={() =>
                dispatch(
                  updateRevenue({
                    key: "transactionHistoryVisible",
                    value: true,
                  }),
                )
              }
            >
              <div className="flex item-center gap-x-2">
                <img
                  src="/assets/icons/receipt.svg"
                  className="w-4 h-4"
                  alt="transaction-history"
                />

                <p className="font-poppins text-xs leading-4.5 font-medium text-background-white">
                  Transaction History
                </p>
              </div>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentDetailsHeader;
