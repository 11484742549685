/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import { Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Core/Components/CustomButton";
import CustomModal from "../../Core/Components/Modal/CustomModal";
import CloseIcon from "../../Common/Svgs/CloseIcon";
import InfoCircleIcon from "../../Common/Svgs/InfoCircleIcon";
import {
  purchaseRejectionList,
  sessionRejectionList,
  transportRejectionList,
} from "./constants";
import CustomInput from "../../Core/Components/CustomInput";
import AlertIcon from "../../Common/Svgs/AlertIcon";
import { getRequestList, rejectRequest } from "../../Redux/APIs/requestsAPI";
import { updateRequest } from "../../Redux/Slices/requests.slice";

const rejectionConditions = {
  "power-africa": 4,
  "purchase-feed": 3,
  taas: 4,
};

const dataList = {
  "power-africa": sessionRejectionList,
  taas: transportRejectionList,
  "purchase-feed": purchaseRejectionList,
};

const LOCAL_DEFAULT_LIMIT = 10;

const RejectRequestModal = ({
  visible,
  onCancel,
  currentPage,
  setCurrentPage,
}) => {
  const dispatch = useDispatch();

  const { currentRequest } = useSelector((state) => state.requests);

  const [disabled, setDisabled] = useState("");
  const [selectedReason, setSelectedReason] = useState({});
  const [otherInput, setOtherInput] = useState({
    focus: false,
    value: "",
  });

  // rejection list
  const data = dataList[currentRequest.type];

  const handleOtherTextInput = (e) => {
    setOtherInput((prev) => ({
      ...prev,
      value: e.target.value,
    }));

    setSelectedReason((prev) => ({
      ...prev,
      text: e.target.value,
    }));

    setDisabled("");
  };

  const handleCancelAndClose = () => {
    setDisabled("");
    setSelectedReason({});
    setOtherInput({
      focus: false,
      value: "",
    });

    onCancel();
  };

  const handleSelection = (reason) => {
    setSelectedReason(reason);
  };

  const handleReject = async () => {
    if (selectedReason.text === "Other") {
      setDisabled("Please enter the reason");
      return;
    }

    const payload = {
      requestId: +currentRequest.id,
      reasonId: +selectedReason.id,
      reasonText: selectedReason.text,
    };

    await dispatch(rejectRequest(payload));

    const response = await dispatch(
      getRequestList({
        offset: ((currentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
      }),
    );

    // If no records exist for the current page, move to the previous page
    if (!response.payload.result.length && currentPage > 1) {
      const previousPage = currentPage - 1;
      setCurrentPage(previousPage);

      await dispatch(
        getRequestList({
          offset: (previousPage - 1) * LOCAL_DEFAULT_LIMIT,
        }),
      );
    }

    handleCancelAndClose();

    const requestType =
      currentRequest?.type === "taas"
        ? "Transport"
        : currentRequest?.type === "power-africa"
        ? "Session"
        : "Purchase";

    dispatch(
      updateRequest({
        key: "showToastMessage",
        value: {
          visible: true,
          data: {
            title: `1 ${requestType} Request Was Rejected.`,
            description: "SMS sent to the farmer successfully.",
          },
        },
      }),
    );
  };

  return (
    <CustomModal
      visible={visible}
      onCancel={handleCancelAndClose}
      width="536px"
    >
      <div
        className="p-4 bg-background-white rounded-xl"
        style={{
          boxShadow:
            "12px 12px 16px -4px rgba(16, 24, 40, 0.08), -4px -4px 6px -2px rgba(16, 24, 40, 0.03)",
        }}
      >
        <div className="flex items-center justify-between">
          <div className="w-12 h-12 flex items-center justify-center rounded-full bg-warning-10">
            <InfoCircleIcon size={24} className="text-warning rotate-180" />
          </div>

          <button type="button" className="mr-2" onClick={handleCancelAndClose}>
            <CloseIcon className="stroke-dark-gray w-6 h-6" />
          </button>
        </div>

        <div className="mt-5 px-2 pb-2">
          <h1 className="font-poppins text-base font-medium text-black">
            Reject Request
          </h1>

          <p className="mt-2 font-aileron text-xs leading-[14.5px] font-normal">
            Are you sure you want to reject this request? An SMS will be sent to
            the farmer.
          </p>

          <p className="mt-5 font-aileron text-xs leading-[14.5px] font-normal text-dark-gray">
            Reason (Optional)
          </p>

          <div className="mt-2 bg-white border border-light-grey rounded-xl">
            {data?.map((item, index) => {
              const showOtherTextInput =
                rejectionConditions[currentRequest.type] === selectedReason.id;

              return (
                <div
                  key={item.id}
                  role="button"
                  className={`flex items-center gap-x-3 px-3 py-5 group hover:bg-background ${
                    index + 1 === data.length
                      ? ""
                      : "border-b border-light-grey"
                  }`}
                  style={{
                    borderTopLeftRadius: index === 0 ? "12px" : null,
                    borderTopRightRadius: index === 0 ? "12px" : null,
                    borderBottomLeftRadius:
                      index + 1 === data.length ? "12px" : null,
                    borderBottomRightRadius:
                      index + 1 === data.length ? "12px" : null,
                  }}
                  onClick={() => handleSelection(item)}
                >
                  <Radio checked={selectedReason?.id === item.id} />

                  {index + 1 === data.length && showOtherTextInput ? (
                    <CustomInput
                      intent="sessionReject"
                      text="generalSettings"
                      value={otherInput.value}
                      focus={otherInput.focus}
                      border={otherInput.focus ? "success" : "none"}
                      placeholder={
                        otherInput.focus
                          ? null
                          : "Tell them why their request is rejected.."
                      }
                      onFocus={() =>
                        setOtherInput((prev) => ({
                          ...prev,
                          focus: true,
                        }))
                      }
                      onBlur={() =>
                        setOtherInput((prev) => ({
                          ...prev,
                          focus: false,
                        }))
                      }
                      onChange={handleOtherTextInput}
                    />
                  ) : (
                    <p
                      className={`font-aileron font-semibold text-xs leading-[14.5px] capitalize group-hover:text-black ${
                        selectedReason?.id === item.id
                          ? "text-black"
                          : "text-grey-200"
                      }`}
                    >
                      {item.text}
                    </p>
                  )}
                </div>
              );
            })}
          </div>

          <div className="mt-11 flex items-center justify-between gap-x-3">
            {disabled ? (
              <div className="flex items-start gap-x-2">
                <AlertIcon size={16} className="text-alert-red" />

                <p className="font-aileron text-xs font-medium text-alert-red">
                  {disabled}
                </p>
              </div>
            ) : (
              <div />
            )}

            <div className="flex items-center gap-x-3">
              <Button
                colors="grey"
                size="primary"
                onClick={handleCancelAndClose}
              >
                Cancel
              </Button>

              <Button colors="alert" size="primary" onClick={handleReject}>
                Reject Request
              </Button>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default RejectRequestModal;
