/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import CustomRoundedImage from "./CustomRoundedImage";

export const CustomDetailsKPI = ({ image, background, title, value }) => {
  return (
    <div className="border-[0.5px] border-light-grey rounded-md px-3 flex justify-between items-center h-14">
      <div className="flex justify-start items-center gap-x-2.5">
        <CustomRoundedImage image={image} background={background} />
        <div className="font-aileron font-normal text-dark-gray text-xs leading-4.5">
          {title}
        </div>
      </div>
      <div className="text-base font-medium text-black font-poppins">
        {value}
      </div>
    </div>
  );
};

const CustomKPI = ({
  title,
  count,
  countColor = "#000000",
  total,
  icon,
  image,
  background,
  marginLeft = "ml-[45px]",
  handleOnClick = () => {},
  redirect = false,
  unit = "",
  renderFilter = () => null,
}) => {
  return (
    <div
      data-testid="customKPI"
      className="w-full h-full p-4 bg-background-white border-[0.5px] rounded-md border-light-grey"
      onClick={() => handleOnClick()}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-start gap-x-2">
          <CustomRoundedImage background={background} image={image} />

          <p className="font-normal text-xs leading-4.5 font-aileron text-dark-gray capitalize">
            {title}
          </p>
        </div>

        {renderFilter}
      </div>
      <div
        className={`flex items-center justify-start max-w-max ${marginLeft} gap-x-1 mt-1.5 ${
          redirect && "cursor-pointer"
        }`}
      >
        <div className="flex items-center">
          <p
            className="text-2xl font-semibold leading-9 font-poppins"
            style={{
              color: countColor,
            }}
          >
            {count}
          </p>

          {total > 0 && (
            <div className="text-2xl font-[250] leading-9 font-poppins text-dark-gray">
              /{total}
            </div>
          )}

          {unit && (
            <p className="ml-2 font-poppins text-xs font-light text-dark-gray">
              {unit}
            </p>
          )}
        </div>

        {icon || null}
      </div>
    </div>
  );
};
export default CustomKPI;
