import React from "react";

const SessionIcon = ({ size = 16, className = "" }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          fill="currentColor"
          d="M5.686 14.1v-1.857h.24a.5.5 0 1 0 0-1H4.62V8.674H6.57a.5.5 0 0 0 .5-.5V6.023c0-1.728-.628-3.353-1.68-4.345L4.463.803a.5.5 0 0 0-.686 0l-.928.875c-1.052.992-1.68 2.617-1.68 4.345v2.151a.5.5 0 0 0 .5.5h1.951v2.569H2.312a.5.5 0 1 0 0 1h.24V14.1c0 .863.703 1.566 1.567 1.566s1.567-.703 1.567-1.566M2.168 7.674V6.023c0-1.458.51-2.81 1.366-3.618l.585-.551.586.551c.855.807 1.365 2.16 1.365 3.618v1.651H4.62v-.97a.5.5 0 1 0-1 0v.97zM3.552 14.1v-1.857h1.134V14.1a.568.568 0 0 1-1.134 0M15.018.5a.5.5 0 0 0-.5.5v4.634h-1.769V1a.5.5 0 1 0-1 0v4.634H9.98V1a.5.5 0 1 0-1 0v6.02c0 .82.668 1.488 1.488 1.488h1.281v2.568h-1.308a.5.5 0 1 0 0 1h.241v1.857c0 .864.703 1.567 1.567 1.567s1.567-.703 1.567-1.567v-1.857h.24a.5.5 0 1 0 0-1H12.75V8.508h1.28a1.49 1.49 0 0 0 1.489-1.488V1a.5.5 0 0 0-.5-.5m-4.55 7.008a.49.49 0 0 1-.488-.488v-.386h1.769v.874zm2.348 4.568v1.857a.568.568 0 0 1-1.134 0v-1.857zm1.702-5.056a.49.49 0 0 1-.488.488h-1.281v-.874h1.769z"
        />
      </svg>
    </span>
  );
};

export default SessionIcon;
