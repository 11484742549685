import React from "react";
import getPaymentStatus from "../getPaymentStatus";
import getUserType from "../getUserType";
import PaymentStatus from "./PaymentStatus";
import TotalAmountDue, { sortByTotalAmountDue } from "./TotalAmountDue";
import {
  FinanceDueIcon,
  FinanceHoverIcon,
  FinancePaidIcon,
} from "../../../Common/Svgs/FinanceIcon";
import getFormatedFloat from "../../../Utils/getFormatedFloat";
import ListDropdown from "./ListDropdown";
import { PAYMENT_STATUS } from "../data";

export const tempPaymentColumns = [
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Name
      </div>
    ),
    dataIndex: "firstName",
    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    render: (text, record) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-black capitalize">
        {text} {record?.lastName}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Main Hub
      </div>
    ),
    dataIndex: "hubName",
    sorter: (a, b) => a.hubName.localeCompare(b.hubName),
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        User Type
      </div>
    ),
    dataIndex: "userType",
    sorter: (a, b) => a.userType - b.userType,
    render: (text) => <div className="max-w-max">{getUserType(text)}</div>,
  },
  // {
  //   title: () => (
  //     <div className="font-normal font-aileron text-10 text-dark-gray">
  //       No. of Trips Due
  //     </div>
  //   ),
  //   dataIndex: "firstName",
  //   sorter: (a, b) => sortByTripsDue(a, b),
  //   render: (text, record) => <TripsDue payment={record} />,
  // },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Total Amount Due
      </div>
    ),
    dataIndex: "firstName",
    sorter: (a, b) => sortByTotalAmountDue(a, b),
    render: (text, record) => <TotalAmountDue payment={record} />,
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Payment Status
      </div>
    ),
    dataIndex: "paymentStatus",
    render: (text, record) => <PaymentStatus payment={record} />,
  },
];

export const paymentColumns = [
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Name
      </div>
    ),
    dataIndex: "firstName",
    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    render: (text, record) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-black capitalize">
        {text} {record?.lastName}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Main Hub
      </div>
    ),
    dataIndex: "hubName",
    sorter: (a, b) => a.hubName.localeCompare(b.hubName),
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        User Type
      </div>
    ),
    dataIndex: "entityType",
    sorter: (a, b) => a.entityType - b.entityType,
    render: (text) => <div className="max-w-max">{getUserType(text)}</div>,
  },
  // {
  //   title: () => (
  //     <div className="font-normal font-aileron text-10 text-dark-gray">
  //       No. of Trips Due
  //     </div>
  //   ),
  //   dataIndex: "firstName",
  //   sorter: (a, b) => sortByTripsDue(a, b),
  //   render: (text, record) => <TripsDue payment={record} />,
  // },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Total Amount Due
      </div>
    ),
    dataIndex: "totalAmountDue",
    sorter: (a, b) => a.totalAmountDue - b.totalAmountDue,
    render: (text, record) => {
      const value =
        +record.paymentStatus === PAYMENT_STATUS.COMPLETED
          ? 0
          : record.totalAmountDue;

      return (
        <div className="text-xs font-medium font-poppins leading-4.5">
          {value > 0 ? `${value} KSH` : "--"}
        </div>
      );
    },
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Payment Status
      </div>
    ),
    dataIndex: "paymentStatus",
    render: (text) => <div>{getPaymentStatus(text)}</div>,
  },
  {
    dataIndex: "id",
    render: (text, record) => (
      <div
        className="invisible group-hover:visible"
        onClick={(e) => e.stopPropagation()}
      >
        {record?.totalAmountDue > 0 ? <ListDropdown detail={record} /> : null}
      </div>
    ),
  },
];

export const PaymentKPIData = (kpidata) => {
  return [
    {
      id: 1,
      title: "Amount Due To Drivers",
      background: "bg-alert-red",
      countColor: "#C84630",
      icon: <FinanceDueIcon size={16} className="text-background-white" />,
      // count: kpidata?.drivers ? parseFloat(kpidata.drivers).toFixed(2) : 0,
      count: kpidata?.drivers ? getFormatedFloat(kpidata.drivers) : 0,
      redirect: false,
    },
    {
      id: 2,
      title: "Amount Due to Farmers",
      background: "bg-alert-red",
      countColor: "#C84630",
      icon: <FinanceDueIcon size={16} className="text-background-white" />,
      // count: kpidata?.farmers ? parseFloat(kpidata.farmers).toFixed(2) : 0,
      count: kpidata?.farmers ? getFormatedFloat(kpidata.farmers) : 0,
      redirect: false,
    },
    {
      id: 3,
      title: "Total Amount Paid",
      background: "bg-yellow",
      countColor: "#000000",
      icon: <FinanceHoverIcon size={16} className="text-green" />,
      // count: kpidata?.total ? parseFloat(kpidata.total).toFixed(2) : 0,
      count: kpidata?.total ? getFormatedFloat(kpidata.total) : 0,
      redirect: false,
    },
  ];
};

export const PaymentDetailsKPIData = (kpidata) => {
  return [
    {
      title: "Total Amount Due",
      background: "bg-alert-red",
      countColor: "#C84630",
      icon: <FinanceDueIcon size={16} className="text-background-white" />,
      count: kpidata?.due ? getFormatedFloat(kpidata.due) : 0,
      redirect: false,
    },
    {
      title: "Total Amount Paid",
      background: "bg-green",
      countColor: "#085054",
      icon: <FinancePaidIcon size={16} className="text-background-white" />,
      count: kpidata?.paid ? getFormatedFloat(kpidata.paid) : 0,
      redirect: false,
    },
    {
      title: "Total Amount",
      background: "bg-yellow",
      countColor: "#000000",
      icon: <FinanceHoverIcon size={16} className="text-green" />,
      count: kpidata?.paid || kpidata?.due ? kpidata.paid + kpidata.due : 0,
      redirect: false,
    },
  ];
};

export const userTypeFilterOptions = [
  {
    id: 1,
    value: "Driver",
    className:
      "py-1 px-2 bg-[#F5F3FF] text-[#775DFA] font-poppins text-xs font-medium rounded-md",
  },
  {
    id: 2,
    value: "Farmer",
    className:
      "py-1 px-2 bg-[#FEFAED] text-[#EAB726] font-poppins text-xs font-medium rounded-md",
  },
];

export const paymentStatusFilterOptions = [
  {
    id: 1,
    value: getPaymentStatus(1),
  },
  // {
  //   id: 2,
  //   value: getPaymentStatus(2),
  // },
  {
    id: 3,
    value: getPaymentStatus(3),
  },
];
