import usePermission from "../../Core/hooks/usePermission";
import { PERMISSIONS } from "../Login/constants";
import getQRCodeStatus from "./getQRCodeStatus";

const useTypeFilters = () => {
  const typeFilters = [
    {
      id: 1,
      value: getQRCodeStatus(1),
      hasPermission: usePermission([PERMISSIONS.TRIKE_VIEW_QR]),
    },
    {
      id: 2,
      value: getQRCodeStatus(2),
      hasPermission: usePermission([PERMISSIONS.BATTERY_VIEW_QR]),
    },
    {
      id: 3,
      value: getQRCodeStatus(3),
      hasPermission: usePermission([PERMISSIONS.PARTNER_VIEW_QR]),
    },
    {
      id: 4,
      value: getQRCodeStatus(4),
      hasPermission: true,
    },
    {
      id: 5,
      value: getQRCodeStatus(5),
      hasPermission: usePermission([PERMISSIONS.POWER_AFRICA_ASSET_VIEW_QR]),
    },
    {
      id: 6,
      value: getQRCodeStatus(6),
      hasPermission: usePermission([PERMISSIONS.POWER_AFRICA_ASSET_VIEW_QR]),
    },
    {
      id: 7,
      value: getQRCodeStatus(7),
      hasPermission: usePermission([PERMISSIONS.POWER_AFRICA_ASSET_VIEW_QR]),
    },
    {
      id: 8,
      value: getQRCodeStatus(8),
      hasPermission: usePermission([PERMISSIONS.POWER_AFRICA_ASSET_VIEW_QR]),
    },
    {
      id: 9,
      value: getQRCodeStatus(9),
      hasPermission: usePermission([PERMISSIONS.POWER_AFRICA_ASSET_VIEW_QR]),
    },
  ];

  return typeFilters;
};

export default useTypeFilters;
