/* eslint-disable no-nested-ternary */
import React from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import usePermission from "../../Core/hooks/usePermission";
import { PERMISSIONS } from "../Login/constants";
import { POWER_AFRICA_ASSET_STATUS, POWER_AFRICA_ASSETS } from "./constants";
import getStatus from "./getStatus";
import CustomTooltip from "../../Core/Components/CustomTooltip";
import ListDropdown from "./ListDropdown";
import TillerIcon from "../../Common/Svgs/TillerIcon";
import UserIcon from "../../Common/Svgs/UserIcon";
import AddToServiceIcon from "../../Common/Svgs/AddToServiceIcon";
import BatteryPackColumn from "./BatteryPackColumn";
import getAssetType from "./getAssetType";
import { SESSION_STATUS } from "../Sessions/constants";
import getSessionStatus from "../Sessions/getSessionStatus";

export const assetColumns = [
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Registration No.
      </div>
    ),
    dataIndex: "registrationNo",
    // width: "140px",
    sorter: (a, b) => a.registrationNo.localeCompare(b.registrationNo),
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-black">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Asset Type
      </div>
    ),
    dataIndex: "type",
    // width: "140px",
    sorter: (a, b) => a.type.localeCompare(b.type),
    render: (text) => <div>{getAssetType(+text)}</div>,
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray whitespace-nowrap">
        Model No.
      </div>
    ),
    dataIndex: "modelNo",
    sorter: (a, b) => a.modelNo.localeCompare(b.modelNo),
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {text || "--"}
      </div>
    ),
  },

  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray whitespace-nowrap">
        Asset Tag
      </div>
    ),
    sorter: (a, b) => a?.assetTag?.localeCompare(b?.assetTag),
    dataIndex: "assetTag",
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text || "--"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Hub Station
      </div>
    ),
    dataIndex: "hubName",
    sorter: (a, b) => a?.hubName?.localeCompare(b?.hubName),
    // width: "200px",
    render: (text) => (
      <div>
        <CustomTooltip tooltipTitle={text} textColor="capitalize">
          <div className="text-xs font-semibold font-aileron text-dark-gray capitalize whitespace-nowrap">
            {/* {text.length > 15 ? `${text.substring(0, 15)}...` : text} */}
            {text}
          </div>
        </CustomTooltip>
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Battery Pack
      </div>
    ),
    dataIndex: "batteryPack",
    // width: "150px",
    render: (text, record) => <BatteryPackColumn record={record} />,
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Agent
      </div>
    ),
    dataIndex: "agentFirstName",
    sorter: (a, b) => a?.agentFirstName?.localeCompare(b?.agentLastName),
    // width: "140px",
    render: (text, record) => {
      const agentName = text
        ? `${record.agentFirstName} ${record.agentLastName}`
        : "--";

      return (
        <CustomTooltip tooltipTitle={agentName} textColor="capitalize">
          <div className="text-xs font-semibold font-aileron text-dark-gray capitalize whitespace-nowrap">
            {agentName.length > 15
              ? `${agentName.substring(0, 15)}...`
              : agentName}
          </div>
        </CustomTooltip>
      );
    },
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Usage
      </div>
    ),
    dataIndex: "usage",
    // sorter: (a, b) => a?.usage?.localeCompare(b?.usage),
    // width: "140px",
    render: () => (
      <div>
        <div className="text-xs font-semibold font-aileron text-dark-gray capitalize whitespace-nowrap">
          --
        </div>
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Status
      </div>
    ),
    dataIndex: "status",
    // width: "170px",
    render: (text) => getStatus(text),
  },
  {
    dataIndex: "id",
    width: "80px",
    render: (text, record) => (
      <button
        type="button"
        onClick={(event) => {
          event.stopPropagation();
        }}
        className="w-full flex items-center justify-center invisible group-hover:visible"
      >
        <ListDropdown detail={record} />
      </button>
    ),
  },
];

export const assetDetailSessionColumns = [
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Session ID
      </div>
    ),
    dataIndex: "id",
    // width: "140px",
    sorter: (a, b) => a.id - b.id,
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-black">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Agent
      </div>
    ),
    dataIndex: "agentFirstName",
    // width: "140px",
    sorter: (a, b) => a.agentFirstName.localeCompare(b.agentFirstName),
    render: (text, record) => {
      const agentName = text
        ? `${record.agentFirstName} ${record.agentLastName}`
        : "--";

      return (
        <CustomTooltip tooltipTitle={agentName} textColor="capitalize">
          <div className="text-xs font-semibold font-aileron text-dark-gray capitalize whitespace-nowrap">
            {agentName.length > 15
              ? `${agentName.substring(0, 15)}...`
              : agentName}
          </div>
        </CustomTooltip>
      );
    },
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Farmer & Location
      </div>
    ),
    dataIndex: "farmerFirstName",
    sorter: (a, b) => a?.farmerFirstName?.localeCompare(b?.farmerFirstName),
    // width: "200px",
    render: (text, record) => {
      const farmerName = text ? `${text} ${record.farmerLastName}` : "--";

      return (
        <button type="button" onClick={(e) => e.stopPropagation()}>
          <CustomTooltip
            tooltipTitle={
              <div>
                <p className="text-xs font-medium font-aileron text-black">
                  {farmerName}
                </p>
                <p className="mt-1 text-10 leading-3 font-normal font-aileron text-dark-gray">
                  {record.farmLat}, {record.farmLong}
                </p>
              </div>
            }
            bgColor="#fff"
            textColor="text-dark-gray"
            placement="bottomLeft"
            padding="8px"
            trigger="click"
          >
            <div className="flex items-center cursor-pointer gap-x-1">
              <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray text-left capitalize whitespace-nowrap">
                {farmerName.length > 15
                  ? `${farmerName.substring(0, 15)}...`
                  : farmerName}
              </div>
              <img src="/assets/icons/chevron-down.svg" alt="" />
            </div>
          </CustomTooltip>
        </button>
      );
    },
  },

  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray whitespace-nowrap">
        Quantity
      </div>
    ),
    // sorter: (a, b) => a?.assetTag?.localeCompare(b?.assetTag),
    dataIndex: "id",
    render: (text, record) => {
      const value =
        +record.status === SESSION_STATUS.COMPLETED
          ? record.actualArea
          : record?.estimatedArea
          ? record.estimatedArea
          : null;

      return (
        <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
          {value ? `${value} ${record.unit}` : "--"}
        </div>
      );
    },
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Date & Time Slot
      </div>
    ),
    dataIndex: "activityDate",
    sorter: (a, b) => a?.activityDate?.localeCompare(b?.activityDate),
    // width: "140px",
    render: (text, record) => (
      <div>
        <p className="text-xs font-semibold font-aileron leading-[14.5px] text-dark-gray whitespace-nowrap capitalize">
          {dayjs(text).format("ddd, MMM DD YYYY")}
        </p>

        <p className="text-10 font-normal font-aileron leading-3 text-dark-gray uppercase">
          {record.status === SESSION_STATUS.COMPLETED
            ? `${dayjs(record.startTime).format("hh:mm a")} - ${dayjs(
                record.endTime,
              ).format("hh:mm a")}`
            : record.timeSlot}
        </p>
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Status
      </div>
    ),
    dataIndex: "status",
    sorter: (a, b) => a.status - b.status,
    // width: "170px",
    render: (text) => <div>{getSessionStatus(text)}</div>,
  },
];

export const AssetKPIData = (kpidata) => {
  const navigate = useNavigate();

  // service permission
  const hasServicePermission = usePermission([
    PERMISSIONS.SERVICE_VIEW,
    PERMISSIONS.SERVICE_VIEW_AND_EDIT,
  ]);

  return [
    {
      id: 1,
      title: "Total No. Of Assets",
      count: kpidata?.total,
      redirect: false,
      image: <TillerIcon className="text-primary" />,
    },
    {
      id: 2,
      title: "No. Of Assets In Sessions",
      count: kpidata?.inSession,
      redirect: false,
      image: <UserIcon size={16} className="stroke-primary fill-none" />,
    },
    {
      id: 3,
      title: "No. Of Assets In Service",
      count: kpidata?.service,
      redirect: true,
      icon: (
        <img
          src="/assets/icons/chevron-down.svg"
          className="-rotate-90 "
          alt="maintenance"
        />
      ),
      onClick: () => {
        if (hasServicePermission && kpidata.service > 0) {
          navigate("/service");
        }
      },

      image: <AddToServiceIcon size={16} className="text-primary" />,
    },
  ];
};

export const statusFilters = [
  {
    id: POWER_AFRICA_ASSET_STATUS.IN_SESSION,
    value: getStatus(POWER_AFRICA_ASSET_STATUS.IN_SESSION),
  },
  {
    id: POWER_AFRICA_ASSET_STATUS.HUB_STATION,
    value: getStatus(POWER_AFRICA_ASSET_STATUS.HUB_STATION),
  },
  {
    id: POWER_AFRICA_ASSET_STATUS.SERVICE,
    value: getStatus(POWER_AFRICA_ASSET_STATUS.SERVICE),
  },
];

export const assetTypeFilters = [
  {
    id: POWER_AFRICA_ASSETS.TILLER,
    value: getAssetType(POWER_AFRICA_ASSETS.TILLER),
  },
  {
    id: POWER_AFRICA_ASSETS.MILLER,
    value: getAssetType(POWER_AFRICA_ASSETS.MILLER),
  },
  {
    id: POWER_AFRICA_ASSETS.THRESHER,
    value: getAssetType(POWER_AFRICA_ASSETS.THRESHER),
  },
  {
    id: POWER_AFRICA_ASSETS.WATER_PUMP,
    value: getAssetType(POWER_AFRICA_ASSETS.WATER_PUMP),
  },
  {
    id: POWER_AFRICA_ASSETS.CHAUF_CUTTER,
    value: getAssetType(POWER_AFRICA_ASSETS.CHAUF_CUTTER),
  },
];

export const assetTypeOptions = [
  {
    id: POWER_AFRICA_ASSETS.TILLER,
    value: "Tiller",
  },
  {
    id: POWER_AFRICA_ASSETS.MILLER,
    value: "Miller",
  },
  {
    id: POWER_AFRICA_ASSETS.THRESHER,
    value: "Thresher",
  },
  {
    id: POWER_AFRICA_ASSETS.WATER_PUMP,
    value: "Water Pump",
  },
  {
    id: POWER_AFRICA_ASSETS.CHAUF_CUTTER,
    value: "Chauf Cutter",
  },
];
