import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import getProductKPIDetails from "./Data/getProductKPIDetails";
import ChevronIcon from "../../Common/Svgs/ChevronIcon";

const KPI = ({ type = "assets", value = 1123, path = "" }) => {
  const navigate = useNavigate();

  const { title, icon, bgColor, color } = getProductKPIDetails(type);

  const handleClick = () => {
    if (!path) return;
    navigate(`${path}`);
  };

  return (
    <div
      className="h-[92px] flex-1 bg-background-white rounded-md p-3"
      style={{
        boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div
        className="flex items-center gap-x-2 rounded-md py-1 px-2"
        style={{ background: bgColor }}
      >
        {icon}

        <p
          className="font-aileron font-semibold text-xs leading-[14.4px]"
          style={{ color }}
        >
          {title}
        </p>
      </div>

      <div
        className="mt-2 flex items-center max-w-max"
        role={path ? "button" : "none"}
        onClick={handleClick}
      >
        <p className="font-poppins font-semibold text-2xl leading-[36px]">
          {value || "--"}
        </p>

        {path ? (
          <div className="ml-2">
            <ChevronIcon className="w-4 h-4 stroke-black" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const ProductKPIs = ({ showListingsAndKPI }) => {
  const { allKpiCount } = useSelector((state) => state.home);

  if (!showListingsAndKPI) return <div />;

  return (
    <div className="flex items-center gap-x-3 mb-3">
      <KPI
        type="assets"
        value={allKpiCount?.assets}
        path="/assets?tab=trikes"
      />

      <KPI type="trips" value={allKpiCount?.trips} path="/trips" />

      <KPI type="sessions" value={allKpiCount?.sessions} path="/sessions" />

      <KPI
        type="milk"
        value={allKpiCount?.milk ? `${allKpiCount?.milk} L` : 0}
        path={false}
      />
    </div>
  );
};

export default ProductKPIs;
